import React from 'react';
import './ResetPassword.css';

const ResetPasswordForm = (props) => {
    return (<>
        <div className="activate_account_page_container">
            <h1>{props.getTranslate('front_reset_password_form','reset_password_form_title')}</h1>
            <p>
                {props.getTranslate('front_reset_password_form','reset_password_form_new_pass_title')}:
            </p>
            <form onSubmit={(event) => {
                event.preventDefault();
                props.send(props.form.password, props.token);
            }}>
                <div className={'row'}>
                    <div className="col-md-3">
                        <div className="form-group">
                            <input type="text"
                                   className="form-control"
                                   value={props.form && props.form.password}
                                   onChange={(event) => props.changeValue('password', event.target.value)}
                                   required={'required'}
                            />
                        </div>
                        <div className="form-group">
                            <input type="text"
                                   className="form-control"
                                   value={props.form && props.form.check_password}
                                   onChange={(event) => props.changeValue('check_password', event.target.value)}
                                   required={'required'}
                            />
                        </div>
                        <button type="submit" className="btn custom_button btn-block">{props.getTranslate('front_reset_password_form','reset_password_form_button_title')}</button>
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="col-md-12">
                    {props.form && props.form.success && <>
                        <p className={'text-success'}>{props.form.message}</p>
                    </>}
                    {props.form && props.form.fail && <>
                        <p className={'text-danger'}>{props.form.message}</p>
                    </>}
                </div>
            </div>
        </div>
    </>)
};

export default ResetPasswordForm;