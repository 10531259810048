import React from 'react';
import {connect} from "react-redux";
import ProductsDetail from "./ProductsDetail";
import './ProductsDetail.css';
import {
    addCartProductThunk, getMenuItemsThunk,
    setProductDetailDataThunk,
    setProductDetailTabActive
} from "../../../store/reducers/MainReducer";
import {compose} from "redux";
import {withLang} from "../../HOC/withLang/withLang";
import onClickOutside from "react-onclickoutside";


class ProductsDetailContainer extends React.Component {

    constructor(props){
        super(props);
        this.product = this.props.uri.match.params.product;
        this.addInLocalStorage = this.addInLocalStorage.bind(this);
        this.getCategoryData = this.getCategoryData.bind(this);
    }

    addInLocalStorage(model) {
        const actual = localStorage.getItem('medbuy_searched_products_store') ? JSON.parse(localStorage.getItem('medbuy_searched_products_store')) : [];
        !actual.includes(model) && actual.push(model);
        const string_actual = JSON.stringify(actual);
        localStorage.setItem('medbuy_searched_products_store', string_actual);
    }

    getCategoryData() {
        let category = null;
        this.props.categoryTree.forEach(e => {
            if(e.alias === this.props.activeCategory) {
                category = e;
            } else {
                e.childs && e.childs.forEach(c => {
                    if(c.alias === this.props.activeCategory) {
                        category = c;
                        category.parent_data = e;
                    }
                })
            }
        });

        return category;
    }

    componentDidMount() {
        this.props.getMenuItems();
        this.props.getProduct(this.product);
        this.addInLocalStorage(this.product);
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        nextProps.product && this.addInLocalStorage(nextProps.product.id);
        if(nextProps.uri.match.params.product !== this.props.uri.match.params.product) {
            this.props.getProduct(nextProps.uri.match.params.product);
            this.product = nextProps.uri.match.params.product;
        }
    }

    render() {
            return <ProductsDetail product={this.props.product ? this.props.product : {}}
                                   activeTab={this.props.activeTab}
                                   setActiveTab={this.props.setActiveTab}
                                   category={this.getCategoryData()}
                                   addToCart={this.props.addToCart}
                                   getTranslate={this.props.getTranslate}
            />
    }
}

let mapStateToProps = (state) => {
    return {
        product: state.main.productDetail,
        activeTab: state.main.productDetailTabActive,
        activeCategory: state.main.productsActiveCategory,
        categoryTree: state.main.categoryTree,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        getProduct: (id) => dispatch(setProductDetailDataThunk(id)),
        setActiveTab: (tab) => dispatch(setProductDetailTabActive(tab)),
        addToCart: (product) => dispatch(addCartProductThunk(product)),
        getMenuItems: () => dispatch(getMenuItemsThunk()),
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang,
    onClickOutside
)(ProductsDetailContainer);