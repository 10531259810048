import React from 'react';
import LangLink from "../../Common/LangLink";

export const withPagination = (Component) => {

    class PaginationLocal extends React.Component {

        constructor(props) {
            super(props);
            this.state = {
                page: 1,
                pageSize: 10,
                pageStart: 1,
                pageFinish: 10
            }
            this.renderPagination = this.renderPagination.bind(this);
        }

        componentDidMount() {

        }

        renderPagination(count) {
            if(!count || !this.state) return '';
            const pages = [];
            let countPage = Math.floor( count / this.state.pageSize);
            if(count % this.state.pageSize !== 0) {
                countPage++;
            }
            for(let i = 1; i <= Number(countPage); i++) {
                pages.push(<LangLink isA={true} className={'page-link ' + (Number(this.state.page) === Number(i) ? 'active' : '')} onClick={() => this.setPage(i, count)}> {i} </LangLink>)
            }
            return pages;
        }

        getPages() {
            return {
                pageStart: this.state.pageStart,
                pageFinish: this.state.pageFinish,
                page: this.state.page
            }
        }

        setPage(page, count) {
            let finishPage = this.state.pageSize * page;
            let startPage = this.state.pageSize * (page -1);
            this.setState({
                page: page,
                pageStart: startPage,
                pageFinish: finishPage,
            });
        }

        renderPaginationBlock(count) {
            return <div className={'pagination_main_container'}>
                <ul className="pagination">
                    <li className="page-item">
                        {this.renderPagination(count)}
                    </li>
                </ul>
            </div>
        }

        render() {

            return <Component {...this.props}
                              paginationHTML={this.renderPaginationBlock(this.props.paginationDataCount)}
                              paginationPageStart={this.state.pageStart}
                              paginationPages={this.getPages()}
            />
        }
    }
    return PaginationLocal;
}



