import React from 'react';
import {connect} from "react-redux";
import About from "./About";
import './About.css';

class AboutContainer extends React.Component {

    constructor(props){
        super(props);
    }

    componentDidMount() {}

    render() {
        return <About/>
    }
}

let mapStateToProps = (state) => {
    return {}
}

let mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutContainer);