import React from 'react';
import {connect} from "react-redux";
import Tags from "./Tags";
import {compose} from "redux";
import {withLang} from "../../HOC/withLang/withLang";
import onClickOutside from "react-onclickoutside";


class TagsContainer extends React.Component {

    constructor(props){
        super(props);
    }

    render() {
        return <Tags
            tags={this.props.tags}
            getTranslate={this.props.getTranslate}
        />
    }
}

let mapStateToProps = (state) => {
    return {
    }
};

let mapDispatchToProps = (dispatch) => {
    return {

    }
};
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang,
    onClickOutside
)(TagsContainer);