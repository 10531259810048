import ApiService from "../../api/api";
import {changeLoaderMessageAC, changeLoaderStatusAC} from "./MainReducer";

const SET_USER_AUTH = 'SET_USER_AUTH';
const CHANGE_AUTH_FORM_VALUE = 'CHANGE_AUTH_FORM_VALUE';
const LOGOUT = 'LOGOUT';
const CHANGE_USER_DATA = 'CHANGE_USER_DATA';

const apiService = new ApiService();

let initialState = {
    user: null,
    authForm: {
        login: null,
        pass:  null,
        isFailure: false
    },
    isAuth: false
};

const AuthReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_USER_AUTH:
            return {
                ...state,
                user: action.user,
                isAuth: true
            };
        case CHANGE_AUTH_FORM_VALUE:
            let authForm = {...state.authForm};
            authForm[action.input] = action.value;
            if(action.input !== 'isFailure') {
                authForm.isFailure = false;
            }
            return {
                ...state,
                authForm: authForm
            };
        case CHANGE_USER_DATA:
            let userData = {...state.user};
            userData[action.input] = action.value;
            return {
                ...state,
                user: userData
            };
        case LOGOUT:
            localStorage.removeItem('medbuy_store_user_token');
            return {
                ...state,
                user: null,
                isAuth: false
            };
        default:
            return state
    }
};

export const setUserAuthAC = (user) => {
    return {
        type: SET_USER_AUTH,
        user: user
    }
};
export const changeAuthFormValueAC = (input, value) => {
    return {
        type: CHANGE_AUTH_FORM_VALUE,
        input: input,
        value: value
    }
};
export const changeUserDataValueAC = (input, value) => {
    return {
        type: CHANGE_USER_DATA,
        input: input,
        value: value
    }
};
export const actionLogoutAC = () => {
    return {
        type: LOGOUT
    }
};

export const getAuthDataThunk = (login, pass) => (dispatch) => {
    apiService.getAuth(login, pass)
        .then(token => {
            if(token) {
                localStorage.setItem('medbuy_store_user_token', token);
                apiService.getAuthData(token)
                    .then(user => {
                        user && dispatch(setUserAuthAC(user));
                    })
            } else {
                dispatch(changeAuthFormValueAC('isFailure', true))
            }
        })
}

export const sendCustomerRegistrationThunk = (form) => (dispatch) => {
    apiService.registrationCustomer(form)
        .then(token => {
            if(token) {
                dispatch(getAuthDataThunk(form.email, form.password));
                // localStorage.setItem('medbuy_store_user_token', token);
                // apiService.getAuthData(token)
                //     .then(user => {
                //         user && dispatch(setUserAuthAC(user));
                //     })
            } else {
                dispatch(changeAuthFormValueAC('isFailure', true))
            }
        })
}

export const checkAuthDataThunk = () => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    apiService.getAuthData()
        .then(user => {
            user && dispatch(setUserAuthAC(user));
            dispatch(changeLoaderStatusAC(false));
        })
}

export const getCustomerOrdersDataThunk = () => (dispatch, getState) => {


    const user = getState().auth.user;

    if(user.orders && user.orders.length > 0) return false;

    dispatch(changeLoaderStatusAC(true));

    apiService.getCustomersOrders(user.id)
        .then(res => {
            user && dispatch(changeUserDataValueAC('orders', res));
            dispatch(changeLoaderStatusAC(false));
        })
}


export const updateUserDataThunk = () => (dispatch, getState) => {
    const user = getState().auth.user;
    dispatch(changeLoaderStatusAC(true));
    apiService.updateUser(user)
        .then(res => {
            if(res) {
                dispatch(checkAuthDataThunk());
                dispatch(changeLoaderStatusAC(false));
            } else {
                window.location.href = '/account/profile'
            }

        })
}



export default AuthReducer;