import React from 'react';
import {Link} from "react-router-dom";
import './Breadcrumbs.css';
import {compose} from "redux";
import {connect} from "react-redux";
import {withLang} from "../../HOC/withLang/withLang";
import LangLink from "../LangLink";

const Breadcrumbs = (props) => {
    const getTree = (pages, name) => {
        let breadcrumbs = pages ? pages.map(e => {
            return e.name ? <li className="breadcrumb-item"><LangLink to={e.link}>{e.name}</LangLink></li> : ''
        }) : [];

        return [
            ...breadcrumbs,
            <li className="breadcrumb-item active">{name}</li>
        ]
    }
    return (
        <div className="row">
            <div className="col-sm-12">
                <div className={"breadcrumb_container_with_status"}>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><LangLink to={'/home'}>{props.getTranslate('front_breadcrumbs','breadcrumbs_home_title')}</LangLink></li>
                    {getTree(props.pages, props.name)}
                </ol>
                {props && props.statusName && props.statusName.length > 1 ?
                    <span className={"breadcrumb_status_partner"}>{props.getTranslate('front_breadcrumbs','breadcrumbs_status_title')}: {props && props.statusName ? props.statusName : ""}</span>
                    : ""}
                </div>
            </div>
        </div>
    )
}
export default compose(
    connect(),
    withLang
)(Breadcrumbs);