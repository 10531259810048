import React from "react";
import ContactUs from "./ContactUs";
import {connect} from "react-redux";
import './ContactUs.css';
import {toggleViewContactModalAC, changeAccountContactFormAC, sendAccountContactMessageThunk} from "../../../store/reducers/MainReducer";
import {compose} from "redux";
import {withLang} from "../../HOC/withLang/withLang";
import onClickOutside from "react-onclickoutside";

class ContactUsContainer extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return this.props.isOpen ? <ContactUs
            user={this.props.user}
            form={this.props.form}
            send={this.props.send}
            changeValue={this.props.changeValue}
            toggleViewContactModal={this.props.toggleViewContactModal}
            getTranslate={this.props.getTranslate}
        /> : '';
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        toggleViewContactModal: (status, user) => dispatch(toggleViewContactModalAC(status, user)),
        changeValue: (input, value) => dispatch(changeAccountContactFormAC(input, value)),
        send: (theme, message, user) => dispatch(sendAccountContactMessageThunk(theme, message, user)),
    }
}

let mapStateToProps = (state) => {
    return {
        isOpen: state.main.viewContactModal.isOpen,
        user: state.auth.user,
        form: state.main.contactAccountForm
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang,
    onClickOutside
)(ContactUsContainer);