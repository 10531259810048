import React from 'react';
import {connect} from "react-redux";
import ProductsGallery from "./ProductsGallery";
import './ProductsGallery.css';
import {
    addCartProductAC,
    addCartProductThunk,
    setProductDetailDataThunk,
    setRelatedProductsDataThunk, setSearchedroductsDataThunk
} from "../../../store/reducers/MainReducer";
import ApiService from "../../../api/api";
import {compose} from "redux";
import {withLang} from "../../HOC/withLang/withLang";
import onClickOutside from "react-onclickoutside";

const apiService = new ApiService();


class ProductsGalleryContainer extends React.Component {

    constructor(props){
        super(props);
        this.getProducts = this.getProducts.bind(this);
        this.checkCartAdded = this.checkCartAdded.bind(this);
    }

    getProducts(type) {
        switch (type) {
            case 'related':
                this.props.getRelatedProducts();
                break;
            case 'searched':
                this.props.getSearchedProducts();
                break;
        }
    }

    componentDidMount() {
        this.getProducts(this.props.type);
    }

    checkCartAdded(type, id) {
        let products = [];
        let inCart = false;
        switch (type) {
            case 'sale':
                products = [...this.props.cartProducts];
                break;
            case 'preorder':
                products = [...this.props.cartPreorder];
                break;
        }
        products.forEach((e) => {
            if(Number(e.id) === Number(id)) {
                inCart = true;
            }
        });
        return inCart;
    }

    render() {
        return <ProductsGallery view={this.props.view}
                                products={this.props.products ? this.props.products : (this.props.type === 'related' ? this.props.relatedProducts : this.props.searchedProducts)}
                                count={this.props.count}
                                title={this.props.title}
                                type={this.props.type}
                                addToCart={this.props.addToCart}
                                checkCartAdded={this.checkCartAdded}
                                getTranslate={this.props.getTranslate}
        />
    }
}

let mapStateToProps = (state) => {
    return {
        relatedProducts: state.main.relatedProducts,
        searchedProducts: state.main.searchedProducts,
        cartProducts: state.main.cart.products,
        cartPreorder: state.main.cart.productsPreOrder,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (product) => dispatch(addCartProductThunk(product)),
        getRelatedProducts: () => dispatch(setRelatedProductsDataThunk()),
        getSearchedProducts: () => dispatch(setSearchedroductsDataThunk()),
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang,
    onClickOutside
)(ProductsGalleryContainer);
// export default connect(mapStateToProps, mapDispatchToProps)(ProductsGalleryContainer);git