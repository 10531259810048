import React, {useState} from 'react';
import {isMobile} from "../../../store/reducers/MainReducer";
import Select from "react-select";

const Filters = (props) => {
    const [isOpen, toggleOpen] = useState(false);
    const [isInPackOpen, toggleInPackOpen] = useState(false);
    const [isManufacturer, toggleManufacturer] = useState(false);

    return(
        <div className={!isOpen && isMobile() ? "products_filter_main_container" : "products_filter_main_container mobile_style_filters"}>
            <div className="products_filter_row">
                <span className={'products_filter_title'}>{isMobile() && <span onClick={() => toggleOpen(!isOpen)}> <i className="fas fa-bars"></i> </span>} {props.getTranslate('front_filter','filter_title')}</span>

            </div>
            {!isOpen && isMobile() ?
                ''
                :
                <div className="products_filter_row">
                    <div className="products_filter_item_body price">
                        <div className="products_filter_item_title">
                            {props.getTranslate('front_filter','filter_price_title')}
                        </div>
                        <div className={'filter_price_inputs'}>
                            <div className={'products_filter_item_input_container'}>
                                <input type="text" value={props.inputRange.min}/>
                            </div>
                            <div className={'products_filter_item_separator'}> </div>
                            <div className={'products_filter_item_input_container'}>
                                <input type="text" value={props.inputRange.max}/>
                            </div>
                        </div>
                        <div className="filters_price_selector">
                            {props.inputRange.input}
                        </div>
                    </div>
                    <div className="products_filter_item_body checkbox">
                        <div className="products_filter_item_title curet" onClick={ e=> toggleManufacturer(!isManufacturer)}>
                            {props.getTranslate('front_filter','filter_manufacturer_title')} {isManufacturer ? "⮟" : "⮞"}
                        </div>
                        {isManufacturer && props.manufacturers && props.manufacturers.map(e => {
                            return <div className={'filter_price_checkbox custom-control custom-checkbox mr-sm-2'}>
                                <input type="checkbox"
                                       className="custom-control-input"
                                       checked={props.filters.manufacturer.includes(e.id) ? 'checked' : ''}
                                       onChange={(event) => props.changeFilter('manufacturer', e.id, event.target.checked)}
                                       id={`filter_id_manufacturer_${e.id}`}/>
                                <label className="custom-control-label"
                                       htmlFor={`filter_id_manufacturer_${e.id}`}>{e.name}</label>
                            </div>
                        })}
                    </div>
                    <div className="products_filter_item_body checkbox">
                        <div className="products_filter_item_title curet" onClick={ e=> toggleInPackOpen(!isInPackOpen)}>
                            {props.getTranslate('front_filter','filter_in_pack_title')} {isInPackOpen ? "⮟" : "⮞"}
                        </div>
                            {isInPackOpen && props.directoryData && props.directoryData.in_pack && props.directoryData.in_pack.map(e => {
                                return <div className={'filter_price_checkbox custom-control custom-checkbox mr-sm-2'}>
                                    <input type="checkbox"
                                           className="custom-control-input"
                                           checked={props.filters.in_pack.includes(e.in_pack) ? 'checked' : ''}
                                           onChange={(event) => props.changeFilter('in_pack', e.in_pack, event.target.checked)}
                                           id={`filter_id_in_pack_${e.in_pack}`}/>
                                    <label className="custom-control-label"
                                           htmlFor={`filter_id_in_pack_${e.in_pack}`}>{e.in_pack}</label>
                                </div>

                            })}
                    </div>
                    <div className="products_filter_item_body checkbox">
                        <div className="products_filter_item_title">
                            {props.getTranslate('front_filter','filter_stock_check_title')}
                        </div>
                        <div className={'filter_price_checkbox custom-control custom-checkbox mr-sm-2'}>
                            <input type="checkbox"
                                   className="custom-control-input"
                                   checked={props.filters.inStock.includes(1) ? 'checked' : ''}
                                   onChange={(event) => props.changeFilter('inStock', 1, event.target.checked)}
                                   id={`filter_id_stock`}/>
                            <label className="custom-control-label"
                                   htmlFor={`filter_id_stock`}>{props.getTranslate('front_common','in_stock')}</label>
                        </div>
                        <div className={'filter_price_checkbox custom-control custom-checkbox mr-sm-2'}>
                            <input type="checkbox"
                                   className="custom-control-input"
                                   checked={props.filters.inStock.includes(9) ? 'checked' : ''}
                                   onChange={(event) => props.changeFilter('inStock', 9, event.target.checked)}
                                   id={`filter_id_preorder`}/>
                            <label className="custom-control-label"
                                   htmlFor={`filter_id_preorder`}>{props.getTranslate('front_common','preorder')}</label>
                        </div>
                        <div className={'filter_price_checkbox custom-control custom-checkbox mr-sm-2'}>
                            <input type="checkbox"
                                   className="custom-control-input"
                                   checked={props.filters.getDiscount.includes(1) ? 'checked' : ''}
                                   onChange={(event) => props.changeFilter('getDiscount', 1, event.target.checked)}
                                   id={`filter_get_discount`}/>
                            <label className="custom-control-label"
                                   htmlFor={`filter_get_discount`}>{props.getTranslate('front_common','product_action')}</label>
                        </div>
                        <div className={'filter_price_checkbox custom-control custom-checkbox mr-sm-2'}>
                            <input type="checkbox"
                                   className="custom-control-input"
                                   checked={props.filters.isNewProduct.includes(1) ? 'checked' : ''}
                                   onChange={(event) => props.changeFilter('isNewProduct', 1, event.target.checked)}
                                   id={`filter_new_product`}/>
                            <label className="custom-control-label"
                                   htmlFor={`filter_new_product`}>{props.getTranslate('front_common','filter_product_new')}</label>
                        </div>

                    </div>
                    {/*attributes*/}

                    {props.attributes && props.attributes.map(e => {
                        return <div className="products_filter_item_body checkbox">
                            <div className="products_filter_item_title">
                                {e.name}
                            </div>
                            {e.values && e.values.map(c => {
                                return <div className={'filter_price_checkbox custom-control custom-checkbox mr-sm-2'}>
                                    <input type="checkbox"
                                           className="custom-control-input"
                                           checked={props.filters.attributes.filter( h => Number(h.id) === Number(e.id) && Number(h.value) === Number(c.id))[0] ? 'checked' : ''}
                                           onChange={(event) => props.changeFilter('attributes', {
                                               id: e.id,
                                               value: c.id
                                           }, event.target.checked)}
                                           id={`filter_id_atributes_${c.id}`}
                                    />
                                    <label className="custom-control-label" htmlFor={`filter_id_atributes_${c.id}`}>{c.value}</label>
                                </div>
                            })}
                        </div>
                    })}

                    <div className="product_gallery_button">
                        <button onClick={() => {
                            let filters = {...props.filters};
                            switch(props.type) {
                                case 'category':
                                    filters.category = props.activeCategory;
                                    props.filterProducts(filters, props.page);
                                    break;
                                case 'search':
                                    filters.key = props.searchInput;
                                    props.searchProducts(props.searchInput, filters, props.page)
                                    break;
                            }
                            toggleOpen(!isOpen);


                        }}>{props.getTranslate('front_filter','filter_button_filtered')}</button>
                    </div>
                    <div className="product_gallery_button product_gallery_inverse">
                        <button onClick={() => {
                            props.cleanFilters();
                            switch(props.type) {
                                case 'category':
                                    props.filterProducts({category: props.activeCategory}, props.page);
                                    break;
                                case 'search':
                                    props.searchProducts(props.searchInput, {key: props.searchInput}, props.page)
                                    break;
                            }
                        }}>{props.getTranslate('front_filter','filter_button_clear')}</button>
                    </div>
                </div>
            }

        </div>
    )
}

export default Filters;