import React from 'react';
import {connect} from "react-redux";
import Menu from "./Menu";
import './Menu.css';
import {
    changeSearchInputDataAC,
    getMenuItemsThunk, isMobile,
    toggleActiveMenuItemAC
} from "../../store/reducers/MainReducer";
import onClickOutside from "react-onclickoutside";
import {compose} from "redux";
import {withLang} from "../HOC/withLang/withLang";
import MenuMobile from "./MenuMobile";
import Header from "../Common/Header/Header";

class MenuContainer extends React.Component {

    constructor(props){
        super(props);
    }

    handleClickOutside = evt => {
        this.props.toggleMenu(null)
    };

    componentDidMount() {
        this.props.getMenuItems();
    }



    render() {
        return isMobile() ?
            <MenuMobile items={this.props.categoryTree}
                        searchInput={this.props.searchInput}
                        changeSearch={this.props.changeSearch}
                        getTranslate={this.props.getTranslate}
            />
            :
            <Menu items={this.props.categoryTree}
                     activeMenu={this.props.activeMenu}
                     toggleMenu={this.props.toggleMenu}
                  getTranslate={this.props.getTranslate}
            />
    }
}

let mapStateToProps = (state) => {
    return {
        menuItems: state.main.menuItems,
        activeMenu: state.main.menuActive,
        categoryTree: state.main.categoryTree,
        searchInput: state.main.searchInput,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        getMenuItems: () => dispatch(getMenuItemsThunk()),
        toggleMenu: (id) => dispatch(toggleActiveMenuItemAC(id)),
        changeSearch: (value) => dispatch(changeSearchInputDataAC(value)),
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang,
    onClickOutside
)(MenuContainer);
// export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);