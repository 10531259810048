import React, {useEffect} from 'react';
import './Footer.css';
import {useDispatch, useSelector} from "react-redux";
import {checkCookieThunk} from "../../../store/reducers/MainReducer";

const FooterCookie = ({sendForm, getTranslate}) => {

    const dispatch = useDispatch();
    const id_customer = useSelector((state) => state.auth.user ? state.auth.user.id : false);
    const show = useSelector((state) => state.main.showCookie);

    useEffect(() => {
        dispatch(checkCookieThunk(id_customer));
    }, [])


    return show && <div className={"cookie_container"}>
        <p className={"cookie_text_info"}>{getTranslate('front_footer', 'cookie_text')}</p>
        <form className={"cookie_form"} onSubmit={(event) => {
            event.preventDefault();
        }}>
            <button onClick={() => sendForm(true, id_customer)}>{getTranslate('front_footer', 'cookie_button_accept')}</button>
            <button onClick={() => sendForm(false, id_customer)}>{getTranslate('front_footer', 'cookie_button_decline')}</button>
        </form>
    </div>
}

export default FooterCookie;