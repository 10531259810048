import React from 'react';
import './Tags.css';
import {Link} from "react-router-dom";
import LangLink from "../LangLink";

const Tags = (props) => {
    return (
        <div className={'row'}>
            <div className="tags_main_container col-md-12">
                <div className="tags_main_title">
                    <h3>{props.getTranslate('front_tags','top_tags')}</h3>
                </div>
                <div className="tags_main_container">
                    {props.tags && props.tags.map(e => {
                        return <div className="tags_item">
                            <LangLink to={'/search/' + e.name}>{e.name}</LangLink>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default Tags;