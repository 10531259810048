import React from 'react';

const AccountAddresses = (props) => {
    return <>
        <div className="container-fluid">
            <div className="row">
                {props.user && props.user.address && props.user.address.map(e => {
                    return <div className="col-12">
                                <div className="info-box bg-light">
                                    <div className="info-box-content">
                                        <span className="info-box-number text-center text-muted mb-0">
                                            {props.directoryData && props.directoryData.regions && props.directoryData.regions.filter(c => {
                                                return Number(c.id) === Number(e.region)
                                            })[0].name } обл.,
                                            {props.directoryData && props.directoryData.districts && props.directoryData.districts.filter(b => {
                                                return Number(b.id) === Number(e.district)
                                            })[0].name} р.,
                                            м. {props.directoryData && props.directoryData.cities && props.directoryData.cities.filter(c => {
                                                return Number(c.id) === Number(e.city)
                                            })[0].name }, вул. {e.street}, № {e.number_build}
                                        </span>
                                    </div>
                                </div>
                            </div>
                    })}
            </div>
        </div>
    </>
}

export default AccountAddresses;