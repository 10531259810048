import React from 'react';
import '../../../plugins/flag-icon-css/css/flag-icon.min.css'
import './Header.css';
import ImageSearch from '../../../image/search.png';
import ImageLogo from '../../../image/logo.png';
import ImagePhone from '../../../image/phone.png';
import ImageBasket from '../../../image/basket.png';
import ImageUser from '../../../image/user.png';
import {Link, useParams} from "react-router-dom";
import {formatSum, isMobile} from "../../../store/reducers/MainReducer";
import {languages, useCurrentLangPrefix} from "../../../App";
import LangLink from "../LangLink";

const Header = (props) => {
    const langPrefix = useCurrentLangPrefix();
    const { lang } = useParams();
    const changeLangURL = () => {
        const url = window.location.pathname;
        if(!languages.includes(lang)) return url;
        return url.replace('/' + lang, '')
    }
    const prefix = (code) => code === 'UA' ? '' : '/' + String(code).toLowerCase();
    const withoutRussian = props.languages.filter((e) => e.code !== 'RU');
    return(
        <>
            <header className="container">
                <div className="wrapper row">
                    <div className="header_center_logo">
                        <div>
                            <LangLink to={'/'}><img className="header_logo" src={ImageLogo} /></LangLink>
                        </div>
                        <div className="header_language_button">
                            {props.languages && withoutRussian.map((e,i) => {
                                return <>
                                    <Link className={localStorage.getItem('medbuy_store_main_config_lang') === String(e.id) ? 'lang_active' : ''} to={prefix(e.code) + changeLangURL()}>
                                        {e.code}
                                    </Link>
                                    {i+1 === withoutRussian.length ? '' : '|'}
                                </>
                            })}
                            {/*<a className="lang_active" href="#">UA</a>|*/}
                            {/*<a href="#">RU</a>*/}
                        </div>
                    </div>
                    {isMobile() ? " "
                        :
                        <div className="header_center">
                            <form className="header_search" onSubmit={(event) => {
                                event.preventDefault();
                                window.location.href = langPrefix +'/search/' + props.searchInput.replace('/', ' ');
                            }}>
                                <input className="form-control" value={props.searchInput} onChange={(event) => props.changeSearch(event.target.value)}/>
                                <LangLink to={'/search/' + props.searchInput.replace('/', ' ')} className="header_form_button" type="submit"><img src={ImageSearch} /></LangLink>
                            </form>
                        </div>
                    }
                    <div className="header_center_contact">
                        <div className="header_contact">
                            <div className="header_contact_main"><img src={ImagePhone} /><span><a href="tel:+380983535560">+380 98 353 5560</a></span></div>
                            <div className="header_contact_info">{props.getTranslate('front_header','how_long_work')}</div>
                        </div>
                        <div className="header_contact">
                            <div className="header_contact_main header_cart_container" onClick={() => props.toggle(true)}>
                                <div className={'header_cart_image_container ' + (props.cartTotals && props.cartTotals.count > 0 ? 'with_products' : '')}>
                                    <img src={ImageBasket} />
                                </div>
                                <span>₴ {formatSum(props.cartTotals.amount)}</span>
                            </div>
                            {/*<div className="header_contact_info">{props.cartTotals && props.cartTotals.count > 0 ? <span>{props.cartTotals.count} {props.getTranslate('front_header','cart_products')} </span> : props.getTranslate('front_header','cart_products_empty') }</div>*/}
                            <div className="header_contact_info">{ props.getTranslate('front_header','cart_products_empty') }</div>
                        </div>
                        {props.user ?
                            <LangLink to={'/account/profile'} className="header_contact">
                                <div className="header_contact_main"><img src={ImageUser} /><span>{props.user.name}</span></div>
                                <div className="header_contact_info"></div>
                            </LangLink>
                            :
                            <LangLink to={'/login'} className="header_contact">
                                <div className="header_contact_main"><img src={ImageUser} /><span>{props.getTranslate('front_header','button_enter')}</span></div>
                                <div className="header_contact_info">{props.getTranslate('front_header','button_registration')}</div>
                            </LangLink>
                        }

                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;
