import React from 'react';
import AccountActivate from "./AccountActivate";
import {setCategoryProductsDataThunk} from "../../../store/reducers/MainReducer";
import {connect} from "react-redux";
import AccountActivateConfirm from "./AccountActivateConfirm";
import {compose} from "redux";
import {withLang} from "../../HOC/withLang/withLang";
import onClickOutside from "react-onclickoutside";

class AccountActivateContainer extends React.Component {
    render() {
        return this.props.isConfirm ? <AccountActivateConfirm getTranslate={this.props.getTranslate}/> : <AccountActivate token={this.props.uri.match.params.token} getTranslate={this.props.getTranslate}/>
    }
}

let mapStateToProps = (state) => {
    return {
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang,
    onClickOutside
)(AccountActivateContainer);
