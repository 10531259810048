import React, {useEffect, useState} from 'react';
import ApiService from "../../../api/api";
import {useDispatch, useSelector} from "react-redux";
import {changeLoaderStatusAC, setTranslatesAC} from "../../../store/reducers/MainReducer";

// export const withLang = (Component) => {
//     class LangData extends React.Component {
//
//         apiService = new ApiService();
//
//         constructor(props) {
//             super(props);
//             this.getTranslate = this.getTranslate.bind(this);
//             this.state = {
//                 translates: null
//             }
//         }
//
//         getTranslate(category, key) {
//             if(!this.state.translates) return 'Не вказано';
//
//             if(!this.state.translates[key]) {
//                 this.apiService.createTranslate({
//                     key: key,
//                     category: category,
//                     lang: localStorage.getItem('medbuy_store_main_config_lang') ? localStorage.getItem('medbuy_store_main_config_lang') : 1,
//                     value: key
//
//                 })
//                     .then(res => {
//                         return res;
//                     })
//                 // return 'Не вказано';
//             } else {
//                 return this.state.translates[key];
//             }
//         }
//
//         componentDidMount() {
//             this.apiService.getTranslates()
//                 .then(res => {
//                     if(res) {
//                         this.setState({
//                             translates: res
//                         })
//                     }
//                 })
//         }
//
//         render() {
//             return <Component {...this.props} getTranslate={this.getTranslate}/>
//         }
//     }
//     return LangData;
// }



export const withLang = (Component) => {
    const LangData = (props) => {

        const apiService = new ApiService();

        const dispatch = useDispatch();
        const changeLoaderStatus = (status) => dispatch(changeLoaderStatusAC(status));
        const translates = useSelector((state) => state.main.translates);
        const setTranslates = (translates) => dispatch(setTranslatesAC(translates))

        // const [translates, setTranslates] = useState(null);

        const getTranslate = (category, key) => {
            if(!translates) return 'Не вказано';

            if(!translates[key]) {
                apiService.createTranslate({
                    key: key,
                    category: category,
                    lang: localStorage.getItem('medbuy_store_main_config_lang') ? localStorage.getItem('medbuy_store_main_config_lang') : 1,
                    value: key

                })
                    .then(res => {
                        return res;
                    })
                // return 'Не вказано';
            } else {
                return translates[key];
            }
        }
        useEffect(() => {
            if(!translates) {
            }
        },[]);

        return <Component {...props} getTranslate={getTranslate}/>
    }
    return LangData;
}