import React from "react";

const ContactUs = (props) => {
    return (
        <div className={'modal fade show'} id="modal-lg">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        {/*<h4 className="modal-title">Напишіть Нам, {props.user.name}</h4>*/}
                        <h4 className="modal-title">{props.getTranslate('front_modal_contact_us','modal_contact_us_title')}</h4>
                        <button type="button" className="close view_programm_close_button" onClick={() => props.toggleViewContactModal(false, [])}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body overflow-auto view_programm_container">
                        <form onSubmit={(event) => {
                            event.preventDefault();
                            props.send(props.form.theme, props.form.message, props.user);
                        }}>
                            <div className="form-row">
                                <div className="col">
                                    <input type="text"
                                           className="form-control"
                                           value={props.form && props.form.theme}
                                           onChange={(event) => props.changeValue('theme', event.target.value)}
                                           placeholder={props.getTranslate('front_modal_contact_us','modal_contact_us_input_object')}
                                           required={'required'}
                                    />
                                </div>
                            </div>
                            <br/>
                            <div className="form-row">
                                <div className="col">
                                    <textarea className="form-control"
                                              placeholder={props.getTranslate('front_modal_contact_us','modal_contact_us_input_text')}
                                              value={props.form && props.form.message}
                                              onChange={(event) => props.changeValue('message', event.target.value)}
                                              required={'required'}
                                    />
                                </div>
                            </div>
                            <br/>
                            <div className="form-row">
                                <div className="col">
                                    <button type={"submit"} className="btn btn-success">{props.getTranslate('front_modal_contact_us','modal_contact_us_button_send')}</button>
                                </div>
                            </div>
                            {props.form && props.form.isSend && <>
                                    <div className="form-row">
                                        <div className="col">
                                            <p className={'text-success text-center'}>{props.getTranslate('front_modal_contact_us','modal_contact_us_text_success')}</p>
                                        </div>
                                    </div>
                                </>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;