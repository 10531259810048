import React from 'react';
import {connect} from "react-redux";
import Account from "./Account";
import {
    addPartnerProductSaleAC,
    changePartnerBonusFormValueAC,
    changePartnerProductSaleAC,
    countPartnerProductSaleAC, createPartnerBonusThunk,
    deletePartnerProductSaleAC,
    getDirectoryDataThunk,
    setManufacturersDataThunk,
    toggleAccountActiveTabAC,
    toggleOrderProfileActiveDataAC,
    toggleViewContactModalAC
} from "../../store/reducers/MainReducer";
import AccountProfile from "./AccountProfile";
import AccountOrders from "./AccountOrders";
import AccountAddresses from "./AccountAddresses";
import {actionLogoutAC} from "../../store/reducers/AuthReducer";
import AccountPartnerOrder from "./AccountPartnerOrder";
import AccountPartnerProduct from "./AccountPartnerProduct";
import AccountPayments from "./AccountPayments";
import AccountPartnerProductSale from "./AccountPartnerProductSale";

import ApiService from "../../api/api";
import AccountPartnerSale from "./AccountPartnerSale";
import AccountPartnerBonus from "./AccountPartnerBonus";
import {compose} from "redux";
import {withLang} from "../HOC/withLang/withLang";
import onClickOutside from "react-onclickoutside";



class AccountContainer extends React.Component {

    constructor(props){
        super(props);
        this.getTab = this.getTab.bind(this);
        this.getCode = this.getCode.bind(this);
        this.setPage = this.setPage.bind(this);
        this.apiService = new ApiService();
        this.state = {
            qrc: null,
            page: 1,
            pageSize: 3,
            pageStart: 1,
            pageFinish: 3
        }
    }
    componentDidMount() {
        this.props.directory();
        this.props.getManufacturers();
    }

    getCode(customer, products) {
        this.apiService.getQRC(customer, products).then(res => {
            this.setState({
                qrc: res
            }) ;
        });
    }

    getTab(alias) {
        switch (alias) {
            case 'profile':
                return <AccountProfile
                    user={this.props.user}
                    getTranslate={this.props.getTranslate}
                />
            case 'orders':
                return <AccountOrders
                    user={this.props.user}
                    orderOpen={this.props.orderOpen}
                    toggleActive={this.props.toggleActive}
                    directoryData={this.props.directoryData}
                    manufacturers={this.props.manufacturers}
                    paginationDataCount={this.props.user && this.props.user.orders && this.props.user.orders.filter(e => Number(e.type) === 1 || Number(e.type) === 2).length}
                    getTranslate={this.props.getTranslate}
                />
            case 'addresses':
                return <AccountAddresses
                    user={this.props.user}
                    directoryData={this.props.directoryData}
                    getTranslate={this.props.getTranslate}
                />
            case 'partner_orders':
                return <AccountPartnerOrder
                    user={this.props.user}
                    orderOpen={this.props.orderOpen}
                    toggleActive={this.props.toggleActive}
                    directoryData={this.props.directoryData}
                    manufacturers={this.props.manufacturers}
                    paginationDataCount={this.props.user && this.props.user.orders && this.props.user.orders.filter(e => Number(e.type) === 3 || Number(e.type) === 4).length}
                    getTranslate={this.props.getTranslate}
                />
            case 'partner_products':
                return <AccountPartnerProduct
                    user={this.props.user}
                    orderOpen={this.props.orderOpen}
                    toggleActive={this.props.toggleActive}
                    directoryData={this.props.directoryData}
                    manufacturers={this.props.manufacturers}
                    paginationDataCount={this.props.user && this.props.user.partner_products && this.props.user.partner_products.length}
                    getTranslate={this.props.getTranslate}
                />
            case 'payments':
                return <AccountPayments
                    user={this.props.user}
                    orderOpen={this.props.orderOpen}
                    toggleActive={this.props.toggleActive}
                    directoryData={this.props.directoryData}
                    manufacturers={this.props.manufacturers}
                    paginationDataCount={this.props.user && this.props.user.orders && this.props.user.orders.filter(e => Number(e.type) === 1 || Number(e.type) === 2).length}
                    getTranslate={this.props.getTranslate}
                />
            case 'partner_product_sale':
                return <AccountPartnerSale
                    user={this.props.user}
                    orderOpen={this.props.orderOpen}
                    toggleActive={this.props.toggleActive}
                    directoryData={this.props.directoryData}
                    manufacturers={this.props.manufacturers}
                    paginationDataCount={this.props.user && this.props.user.partner_products_sale && this.props.user.partner_products_sale.length}
                    getTranslate={this.props.getTranslate}
                />
            case 'partner_sale':
                return <AccountPartnerProductSale
                    user={this.props.user}
                    orderOpen={this.props.orderOpen}
                    toggleActive={this.props.toggleActive}
                    directoryData={this.props.directoryData}
                    manufacturers={this.props.manufacturers}
                    changePartnerProduct={this.props.changePartnerProduct}
                    addPartnerProduct={this.props.addPartnerProduct}
                    partnerSaleProductChange={this.props.partnerSaleProductChange}
                    countPartnerProduct={this.props.countPartnerProduct}
                    delPartnerProduct={this.props.delPartnerProduct}
                    partnerSaleProducts={this.props.partnerSaleProducts}
                    getCode={this.getCode}
                    qrc={this.state.qrc}
                    getTranslate={this.props.getTranslate}
                />
            case 'partner_bonus':
                return <AccountPartnerBonus
                    user={this.props.user}
                    directoryData={this.props.directoryData}
                    changeValue={this.props.changeValue}
                    form={this.props.form}
                    createPartnerBonus={this.props.createPartnerBonus}
                    getTranslate={this.props.getTranslate}
                />
        }
    }

    setPage(page, count) {
        let finishPage = this.state.pageSize * page;
        let startPage = this.state.pageSize * (page -1);
        this.setState({
            page: page,
            pageStart: startPage,
            pageFinish: finishPage,
        });
    }

    render() {

        return <Account activeTab={this.props.activeTab}
                        toggleTab={this.props.toggleTab}
                        tab={this.getTab(this.props.uri ? this.props.uri.match.params.tab : 'profile')}
                        tabName={this.props.uri && this.props.uri.match.params.tab ? this.props.uri.match.params.tab : ""}
                        logout={this.props.logout}
                        user={this.props.user}
                        toggleViewContactModal={this.props.toggleViewContactModal}
                        directoryData={this.props.directoryData}
                        getTranslate={this.props.getTranslate}
        />
    }
}

let mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        isAuth: state.auth.isAuth,
        activeTab: state.auth.accountActiveTab,
        orderOpen: state.main.orderOpen,
        directoryData: state.main.directoryData,
        manufacturers: state.main.manufacturers,
        partnerSaleProductChange: state.main.partnerSaleProductChange,
        partnerSaleProducts: state.main.partnerSaleProducts,
        form: state.main.PartnerBonusForm,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        toggleActive: (id) => dispatch(toggleOrderProfileActiveDataAC(id)),
        toggleTab: (status) => dispatch(toggleAccountActiveTabAC(status)),
        logout: (status) => dispatch(actionLogoutAC()),
        directory: (directory) => dispatch(getDirectoryDataThunk(directory)),
        getManufacturers: () => dispatch(setManufacturersDataThunk()),
        toggleViewContactModal: (status) => dispatch(toggleViewContactModalAC(status)),
        addPartnerProduct: (product) => dispatch(addPartnerProductSaleAC(product)),
        delPartnerProduct: (id) => dispatch(deletePartnerProductSaleAC(id)),
        countPartnerProduct: (id, count) => dispatch(countPartnerProductSaleAC(id, count)),
        changePartnerProduct: (id) => dispatch(changePartnerProductSaleAC(id)),
        changeValue: (input, value) => dispatch(changePartnerBonusFormValueAC(input, value)),
        createPartnerBonus: (bonus, id_customer) => dispatch(createPartnerBonusThunk(bonus, id_customer)),
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang,
    onClickOutside
)(AccountContainer);