import React from 'react';
import {connect} from "react-redux";
import {
    changeProductsCategoryFilterThunk,
    setManufacturersDataThunk,
    getFilteredProductsThunk,
    changeInputsRangePriceAC,
    setFiltersAttributesDataThunk,
    setSearchedProductsDataThunk,
    cleanProductsFiltersDataAC,
    getDirectoryDataThunk
} from "../../../store/reducers/MainReducer";
import InputRange from "react-input-range";
import 'react-input-range/lib/css/index.css';
import Filters from "./Filters";
import ApiService from "../../../api/api";
import './Filters.css';
import {compose} from "redux";
import {withLang} from "../../HOC/withLang/withLang";
import onClickOutside from "react-onclickoutside";


class FiltersContainer extends React.Component {

    apiService = new ApiService();

    constructor(props){
        super(props);
        this.renderInputRange = this.renderInputRange.bind(this);
    }

    componentDidMount() {
        this.props.getDirectory();
        this.props.getManufacturers();
        switch(this.props.type) {
            case 'category':
                this.props.getAttributeFilters('category', {category: this.props.categoryAlias});
                break;
            case 'search':
                this.props.getAttributeFilters('search', {});
                break;
        }

    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if(nextProps.categoryAlias !== this.props.categoryAlias) {
            this.props.getAttributeFilters('category', {category: nextProps.categoryAlias});
        }
    }

    render() {
        return <Filters changeFilter={this.props.changeFilter}
                        inputRange={this.renderInputRange()}
                        filters={this.props.filters}
                        activeCategory={this.props.activeCategory}
                        filterProducts={this.props.filterProducts}
                        manufacturers={this.props.manufacturers}
                        attributes={this.props.filtersAttributes && this.props.filtersAttributes.filter(e => [27,29].includes(Number(e.id)))}
                        cleanFilters={this.props.cleanFilters}
                        type={this.props.type}
                        directoryData={this.props.directoryData}
                        searchInput={this.props.searchInput}
                        searchProducts={this.props.searchProducts}
                        page={this.props.page}
                        getTranslate={this.props.getTranslate}
        />
    }

    renderInputRange() {
        return {
            input: <InputRange
                draggableTrack
                maxValue={this.props.filtersPriceRange ? Number(this.props.filtersPriceRange.max) : 0}
                minValue={this.props.filtersPriceRange ? Number(this.props.filtersPriceRange.min) : 0}
                onChange={value => this.props.changePriceRange(value)}
                onChangeComplete={value => {
                    this.props.changeFilter('price_min', value.min);
                    this.props.changeFilter('price_max', value.max);
                }}
                value={this.props.productsInputsRange} />,
            max: this.props.productsInputsRange.max,
            min: this.props.productsInputsRange.min,
        }
    }
}

let mapStateToProps = (state) => {
    return {
        activeCategory: state.main.productsActiveCategory,
        filters: state.main.productsCategoryFilters,
        filtersAttributes: state.main.filtersAttributes.attributes,
        filtersPriceRange: state.main.filtersAttributes.price,
        manufacturers: state.main.manufacturers,
        categoryTree: state.main.categoryTree,
        productsInputsRange: state.main.productsInputsRange,
        searchInput: state.main.searchInput,
        directoryData: state.main.directoryData,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        changeFilter: (input, value, type) => dispatch(changeProductsCategoryFilterThunk(input, value, type)),
        getManufacturers: () => dispatch(setManufacturersDataThunk()),
        filterProducts: (filters, page = 1) => dispatch(getFilteredProductsThunk(filters,page)),
        changePriceRange: (range) => dispatch(changeInputsRangePriceAC(range)),
        getAttributeFilters: (type, data) => dispatch(setFiltersAttributesDataThunk(type, data)),
        searchProducts: (key, filters, page = 1) => dispatch(setSearchedProductsDataThunk(key, filters, page)),
        cleanFilters: () => dispatch(cleanProductsFiltersDataAC()),
        getDirectory: (directory) => dispatch(getDirectoryDataThunk(directory)),
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang,
    onClickOutside
)(FiltersContainer);