import React from 'react';
import './AccountActivate.css';
import {Link} from "react-router-dom";
import LangLink from "../../Common/LangLink";

const AccountActivateConfirm = (props) => {
    return (<>
        <div className="activate_account_page_container">
            <h1>{props.getTranslate('front_account_activate_confirm','account_activate_confirm_title')}</h1>
            <p>
            </p>
            <div className={'row'}>
                <div className="col-md-3">
                    <LangLink to={'/login'} className="btn custom_button btn-block">{props.getTranslate('front_account_activate_confirm','account_activate_confirm_button_title')}</LangLink>
                </div>
            </div>
        </div>
    </>)
}

export default AccountActivateConfirm;