import React from 'react';

class ApiService extends React.Component {

    //TODO: ссылка под замену (АПИ)

    _baseUrl = 'https://api.medbuy.online/api';
    // _baseUrl = 'https://test.api.medbuy.online/api';

    async _getData(url, method, data, headers = {}, resType = 'json') {

        let body = null;
        let options = {
            method: method,
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': '12345',
                'Language': localStorage.getItem('medbuy_store_main_config_lang'),
                ...headers
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
        }
        if (method === 'POST') {
            options.body = JSON.stringify(data);
        }
        const res = await fetch(`${this._baseUrl}${url}`, options);

        if (resType === 'json') {
            body = await res.json();
        }
        if (resType === 'text') {
            body = await res.text();
        }

        if (!res.count > 0) {

        }

        return body;

    }

    getMenuItems = async () => {
        return [
            {
                id: 1,
                name: 'Інфекційні захворювання',
                alias: 'infections',
                parents: []
            },
            {
                id: 2,
                name: 'Біохімія',
                alias: 'biohimic',
                parents: [
                    {
                        id: 3,
                        name: 'Біохімія крові',
                        alias: 'biohimic_blood',
                        parents: []
                    },
                    {
                        id: 4,
                        name: 'Біохімія сечі',
                        alias: 'biohimic_secha',
                        parents: []
                    }
                ]
            },
            {
                id: 5,
                name: 'Гематологія',
                alias: 'gematology',
                parents: []
            },
        ];
    }

    getProduct = async (id) => {
        if (!id) {
            return false;
        }
        const res = await this._getData(
            `/products/${id}`,
            'GET',
            {},
            {},
            'json'
        );
        if (res && res.data && res.data.id) {
            return this._transformProduct(res.data);
        } else {
            return false;
        }
    }
    _transformProduct = (data) => {
        return {
            id: data.id,
            name: data.name,
            manufacturer: data.manufacturer,
            manufacturer_name: data.manufacturer_name,
            long_name: data.long_name,
            analyte: data.analyte,
            description: data.description,
            model: data.model,
            rates: data.rate,
            alias: data.alias,
            count: data.count.count_all,
            price: data.price,
            in_pack: data.in_pack,
            price_one: data.price_one,
            documents: data.documents ? data.documents : [],
            old_price_one: data.old_price_one ? data.old_price_one : null,
            old_price: data.old_price ? data.old_price : null,
            discount_percent: data.discount_percent ? data.discount_percent : null,
            images: data.images ? data.images.map(e => {
                return {
                    id: e.id,
                    link: e.src,
                    isMain: Number(e.in_main) > 0
                }
            }) : [],
            tags: data.tags,
            recommend_products: data.recommend_products,
            seo: data.seo ? {
                title: data.seo.title,
                description: data.seo.description,
                keywords: data.seo.keywords
            } : {
                title: '',
                description: '',
                keywords: ''
            },
            options: data.attributes ? data.attributes.map(e => {
                return {
                    id: e.id,
                    name: e.name,
                    value: e.value
                }
            }) : []
        }
    }

    getProducts = async () => {
        const res = await this._getData(
            `/products`,
            'POST',
            {},
            {"Point-Action": "filter"},
            'json'
        );
        if (res && res.data) {
            return {
                data: this._transformProducts(res.data.data),
                count: res.data.count
            };
        } else {
            return false;
        }
    }

    getProductsHomeRelated = async () => {
        const res = await this._getData(
            `/products`,
            'POST',
            {},
            {"Point-Action": "related"},
            'json'
        );
        if (res && res.data) {
            return {
                data: this._transformProducts(res.data.data),
                count: res.data.count
            };
        } else {
            return false;
        }
    }

    checkEmail = async (email) => {
        const res = await this._getData(
            `/customers`,
            'POST',
            {
                email: email
            },
            {"Point-Action": "check_email"},
            'json'
        );
        if (res && res.data && res.data.check) {
            return true
        } else {
            return false;
        }
    }

    getProductsCategory = async (category, page = 1) => {
        const res = await this._getData(
            `/products`,
            'POST',
            {
                "category": category,
                "page": page,
            },
            {'Point-Action': 'category'},
            'json'
        );
        if (res && res.data) {
            return {
                data: this._transformProducts(res.data.data),
                count: res.data.count
            };
        } else {
            return false;
        }
    }

    _transformProducts = (data) => {
        return data && data.filter(e => Number(e.active)).map(e => {
            let image = e.images && e.images.filter(i => Number(i.in_main))[0];
            return {
                id: e.id,
                name: e.name,
                price: e.price,
                image: image && image.src,
                model: e.model,
                in_pack: e.in_pack,
                alias: e.alias,
                new_product: e.new_product,
                price_one: e.price_one === e.price ? null : e.price_one,
                manufacturer_name: e.manufacturer_name,
                old_price_one: e.old_price_one ? e.old_price_one : null,
                old_price: e.old_price ? e.old_price : null,
                discount_percent: e.discount_percent ? e.discount_percent : null,
                count: e.count.count_all,
                options: e.attributes ? e.attributes.map(pr => {
                    if(pr) {
                        return {
                            id: pr.id,
                            name: pr.name,
                            value: pr.value
                        }
                    }
                }) : [],
            }
        })
    }

    getCategoryTree = async () => {
        const res = await this._getData(
            `/categories`,
            'POST',
            {},
            {"Point-Action": "tree"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    getProductsFiltered = async (filters, page = 1) => {
        const res = await this._getData(
            `/products`,
            'POST',
            {
                page: page,
                ...filters
            },
            {"Point-Action": "filter"},
            'json'
        );
        if (res && res.data) {
            return {
                data: this._transformProducts(res.data.data),
                count: res.data.count
            };
        } else {
            return false;
        }
    }

    getProductsSearch = async (key, filters = {}, page = 1) => {
        const res = await this._getData(
            `/products`,
            'POST',
            {
                page: page,
                key: key,
                activity: 1,
                ...filters
            },
            {"Point-Action": "search"},
            'json'
        );
        if (res && res.data) {
            return {
                data: this._transformProducts(res.data.data),
                count: res.data.count
            };
        } else {
            return false;
        }
    }

    getLanguages = async () => {
        const res = await this._getData(
            `/languages`,
            'GET',
            {},
            {},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    sendOrder = async (order, products, type = 1, user_id) => {
        const res = await this._getData(
            `/orders`,
            'POST',
            {
                ...order,
                // city: order.city ? order.city.value : '',
                // district: order.district ? order.district.value : '',
                // region: order.region ? order.region.value : '',
                products: products.map((product) => {
                    product.description = '';
                    product.recommend_products = [];
                    return product;
                }),
                type: type,
                id_customer: user_id
            },
            {"Point-Action": "create"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    sendContactAccount = async (theme, message, user) => {
        const res = await this._getData(
            `/help`,
            'POST',
            {
                theme: theme,
                message: message,
                user: user
            },
            {"Point-Action": "contact"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    getTranslates = async () => {
        const res = await this._getData(
            `/translates`,
            'POST',
            {
                lang: localStorage.getItem('medbuy_store_main_config_lang') ? localStorage.getItem('medbuy_store_main_config_lang') : 1,
            },
            {"Point-Action": "front"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    createTranslate = async (translate) => {
        const res = await this._getData(
            `/translates`,
            'POST',
            {
                ...translate
            },
            {"Point-Action": "create"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }


    getManufacturers = async () => {
        const res = await this._getData(
            `/manufacturers`,
            'GET',
            {},
            {},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    getFilters = async (type, data) => {
        const res = await this._getData(
            `/filters`,
            'POST',
            {...data},
            {"Point-Action": type},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    getPriceList = async (lang) => {
        const res = await this._getData(
            `/products`,
            'POST',
            {
                lang: lang
            },
            {"Point-Action": "price-list"},
            'json'
        );
        if (res && res.data && res.data.link) {
           window.open(res.data.link);
        } else {
            return false;
        }
    }

    checkPromocode = async (promocode, user_id) => {
        const res = await this._getData(
            `/sell-managers`,
            'POST',
            {
                promocode: promocode,
                user_id: user_id
            },
            {"Point-Action": "promocode"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    getAuth = async (login, pass) => {
        if (!login || !pass) {
            return false;
        }
        const res = await this._getData(
            `/auth`,
            'POST',
            {email: login, password: pass},
            {'Point-Action': 'customer'},
            'json'
        );
        if (res && res.data && res.data.status && res.data.status === 'success') {
            return res.data.token;
        } else if(res && res.data && res.data.status && res.data.status === 'failed') {
            switch (res.data.message) {
                case 'Password or login error':
                    return false;
                case 'Client not active':
                    window.location.href = '/account/activate/' + res.data.token;
                    break;
                default:
                    return false;
            }
        } else {
            return false;
        }
    };

    resetPassword = async (email) => {
        if (!email) {
            return false;
        }
        const res = await this._getData(
            `/auth`,
            'POST',
            {email: email},
            {'Point-Action': 'reset_password'},
            'json'
        );
        if (res && res.data) {
            return {
                status: true
            };
        } else if(res && res.error) {
            return {
                status: false,
                message: 'Емейл не знайдено в базі даних'
            }
        } else {
            return {
                status: false
            };
        }
    };

    setPassword = async (password, token) => {

        if (!password || !token) {
            return false;
        }
        const res = await this._getData(
            `/auth`,
            'POST',
            {
                password: password,
                token: token
            },
            {'Point-Action': 'set_password'},
            'json'
        );
        if (res && res.data) {
            return {
                status: true
            };
        } else {
            return {
                status: false
            };
        }
    };

    getAuthData = async (token = localStorage.getItem('medbuy_store_user_token')) => {
        if (!token) {
            return false;
        }
        const res = await this._getData(
            `/auth`,
            'POST',
            {token: token},
            {'Point-Action': 'token'},
            'json'
        );
        if (res && res.data && res.data.id) {
            return this._transformAuth(res.data);
        } else {
            return false;
        }
    };

    _transformAuth = (data) => {
        return {
            id: data.id,
            name: data.name,
            email: data.email,
            phone: data.phone,
            token: data.token,
            isPartner: data.partner,
            companies: data.companies,
            discount: data.discount,
            partner_products : data.partner_products ? data.partner_products.map(pp => {
                return {
                    name: pp.name,
                    model: pp.model,
                    count: pp.count,
                    manufacturer: pp.manufacturer,
                    alias: pp.alias,
                    price: pp.price,
                    price_one: pp.price_one,
                }
            }) : [],
            partner_products_sale : data.partner_products_sale ? data.partner_products_sale.map(pps => {
                return {
                    alias: pps.product_alias,
                    name: pps.product_name,
                    count: pps.count,
                    price: pps.price,
                    price_one: pps.price_one,
                    bonus: pps.bonus,
                    date: pps.date,
                }
            }) : [],
            address: data.address ? data.address.map(ad => {
                return {
                    id: ad.id,
                    id_customer: ad.id_customer,
                    region: ad.region,
                    city: ad.city,
                    district: ad.district,
                    street: ad.street,
                    number_build: ad.number_build,
                    date: ad.date,

                }
            }) : [],
            partner_bonus: data.partner_bonus ? data.partner_bonus.map(bo => {
                return {
                    bonus: bo.bonus,
                    status: bo.status,
                    date: bo.date,
                }
            }) : [],
            partner_info : data.partner_info ? data.partner_info.map(pi => {
                return {
                    rang: pi.rang,
                    // ipn: pi.ipn,
                    bonus: pi.bonus,
                    // product_sale: pi.product_sale,
                    // specialty: pi.specialty,
                    // address_register: pi.address_register,
                    // pasport_series: pi.pasport_series,
                    // pasport_organization: pi.pasport_organization,
                    // pasport_data: pi.pasport_data,
                    // bank_name: pi.bank_name,
                    // bank_code: pi.bank_code,
                    // bank_iban: pi.bank_iban
                }
            }) : [],
            id_customer: data.id_customer,
            middle_name: data.middle_name,
            orders: data.orders ? data.orders.map(o => {
                return {
                    comment: o.comment,
                    customer_email: o.customer_email,
                    customer_middle_name: o.customer_middle_name,
                    customer_name: o.customer_name,
                    customer_surname: o.customer_surname,
                    date: o.date,
                    discount: o.discount,
                    district: o.district,
                    city: o.city,
                    np_warehouse: o.np_warehouse,
                    id: o.id,
                    id_customer: o.id_customer,
                    id_delivery_type: o.id_delivery_type,
                    id_sell_manager: o.id_sell_manager,
                    parent: o.parent,
                    payment: o.payment,
                    products: o.products ? o.products.map(p => {
                        return {
                            count: p.count,
                            id: p.id,
                            manufacturer: p.info.manufacturer,
                            model: p.info.model,
                            name: p.info.name,
                            alias: p.info.alias,
                            price: p.price
                        }
                    }) : [],
                    payments: o.payments ? o.payments.map(pa => {
                        return {
                            invoice_number: pa.invoice_number,
                            payment_code: pa.payment_code,
                            status: pa.status,
                            date: pa.date,
                        }
                    }) : [],
                    region: o.region,
                    status: o.status,
                    street: o.street,
                    street_house: o.street_house,
                    total: o.total,
                    type: o.type,
                    documents: o.documents ? o.documents.map(doc => {
                        return {
                            id: doc.id,
                            id_order: doc.id_order,
                            type: doc.type,
                            link: doc.link,
                            date: doc.date
                        }
                    }) : [],
                }
            }) : [],
            surname: data.surname,
            franchisees: data.franchisees
        }
    }

    registrationCustomer = async (form) => {
        if (!form) {
            return false;
        }
        const res = await this._getData(
            `/customers`,
            'POST',
            {...form},
            {'Point-Action': 'registration'},
            'json'
        );
        if (res && res.data && res.data.id && res.data.token) {
            return res.data.token;
        } else {
            return false;
        }
    };

    setCookie = async (value, id_customer) => {

        const res = await this._getData(
            `/customers`,
            'POST',
            {
                value: Boolean(value) ? 1 : 0,
                id_customer: id_customer
            },
            {'Point-Action': 'add_cookie'},
            'json'
        );
        if (res && res.data && res.data.status) {
            return true;
        } else {
            return false;
        }
    };

    checkCookie = async (id_customer) => {

        const res = await this._getData(
            `/customers`,
            'POST',
            {
                id_customer: id_customer
            },
            {'Point-Action': 'check_cookie'},
            'json'
        );
        if (res && res.data && res.data.check) {
            return true;
        } else {
            return false;
        }
    };

    updateUser = async (user) => {

        const res = await this._getData(
            `/customers`,
            'POST',
            {
                id_customer: user.id,
                customer: user
            },
            {'Point-Action': 'update_customer'},
            'json'
        );
        if(res && res.data && res.data.status) {
            return true;
        } else {
            return false;
        }
    };

    getCustomersOrders = async (id_customer) => {

        if(!id_customer) return false;

        const res = await this._getData(
            `/customers`,
            'POST',
            {
                id_customer: id_customer,
            },
            {'Point-Action': 'get_orders'},
            'json'
        );
        if(res && res.data) {
            return this._transformCustomerOrders(res.data);
        } else {
            return false;
        }
    }

    _transformCustomerOrders = (orders) => {
       return orders ? orders.map(o => {
            return {
                comment: o.comment,
                customer_email: o.customer_email,
                customer_middle_name: o.customer_middle_name,
                customer_name: o.customer_name,
                customer_surname: o.customer_surname,
                date: o.date,
                discount: o.discount,
                district: o.district,
                city: o.city,
                np_warehouse: o.np_warehouse,
                id: o.id,
                id_customer: o.id_customer,
                id_delivery_type: o.id_delivery_type,
                id_sell_manager: o.id_sell_manager,
                parent: o.parent,
                payment: o.payment,
                products: o.products ? o.products.map(p => {
                    return {
                        count: p.count,
                        id: p.id,
                        manufacturer: p.info.manufacturer,
                        model: p.info.model,
                        name: p.info.name,
                        alias: p.info.alias,
                        price: p.price
                    }
                }) : [],
                payments: o.payments ? o.payments.map(pa => {
                    return {
                        invoice_number: pa.invoice_number,
                        payment_code: pa.payment_code,
                        status: pa.status,
                        date: pa.date,
                    }
                }) : [],
                region: o.region,
                status: o.status,
                street: o.street,
                street_house: o.street_house,
                total: o.total,
                type: o.type,
                documents: o.documents ? o.documents.map(doc => {
                    return {
                        id: doc.id,
                        id_order: doc.id_order,
                        type: doc.type,
                        link: doc.link,
                        date: doc.date
                    }
                }) : [],
            }
        }) : []
    }

    getDirectory = async () => {
        const res = await this._getData(
            `/directory`,
            'GET',
            {},
            {"Point-Action": "related"},
            'json'
        );
        if (res && res.data) {
            return this._transformDirectory(res.data);
        } else {
            return false;
        }
    }
    _transformDirectory = (data) => {
        return {
            regions: data.regions ? data.regions.map(o => {
                return {
                    id: o.id,
                    name: o.name,
                    date: o.date
                }
            }) : [],
            districts: data.districts ? data.districts.map(d => {
                return {
                    id: d.id,
                    id_region: d.id_region,
                    name: d.name,
                    date: d.date
                }
            }) : [],
            cities: data.cities ? data.cities.map(c => {
                return {
                    id: c.id,
                    id_region: c.id_region,
                    name: c.name,
                    date: c.date
                }
            }) : [],
            np_warehouse: data.warehouses ? data.warehouses.map(c => {
                return {
                    id: c.id,
                    id_city: c.id_city,
                    name: c.name,
                    number: c.number,
                    address: c.address,
                    date: c.date
                }
            }) : [],
            status: data.status ? data.status.map(s => {
                return {
                    id: s.id,
                    id_order_status: s.id_order_status,
                    lang: s.lang,
                    value: s.value,
                    date: s.date
                }
            }) : [],
            currencies: data.currencies ? data.currencies.map(cr => {
                return {
                    id: cr.id,
                    name: cr.name,
                    amount: cr.amount,
                    code: cr.code,
                    text: cr.text,
                    date: cr.date
                }
            }) : [],
            categories: data.categories ? data.categories.map(cr => {
                return {
                    id: cr.id,
                    name: cr.value,
                }
            }) : [],
            in_pack: data.in_pack ? data.in_pack : [],
            specialty: data.specialty ? data.specialty.map(sp => {
                return {
                    id: sp.id,
                    name: sp.name,
                    date: sp.date
                }
            }) : [],
            customer_partner_rang: data.customer_partner_rang ? data.customer_partner_rang.map(cpr => {
                return {
                    id: cpr.id,
                    name: cpr.name,
                    bonus_rate: cpr.bonus_rate,
                    must_sale: cpr.must_sale,
                    date: cpr.date
                }
            }) : [],
        }
    }

    getSpecialty = async () => {
        const res = await this._getData(
            `/specialty`,
            'GET',
            {},
            {"Point-Action": "specialty"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    getQRC = async (customer, products) => {
        const res = await this._getData(
            `/qr-code`,
            'POST',
            {
                customer: customer,
                products: products
            },
            {"Point-Action": "create"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }

    createPartner = async (partner) => {
        const res = await this._getData(
            `/partners`,
            'POST',
            {...partner},
            {"Point-Action": "create"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }
    createPartnerBonus = async (bonus, id_customer) => {

        if(!bonus || !id_customer) return false;

        const res = await this._getData(
            `/partners`,
            'POST',
            {
                id_customer: id_customer,
                bonus: bonus
            },
            {"Point-Action": "get_bonus"},
            'json'
        );
        if (res && res.data && res.data.status) {
            return res.data.link;
        } else {
            return false;
        }
    }

    getWFPHash = async (data) => {
        const res = await this._getData(
            `/wfp`,
            'POST',
            {...data},
            {"Point-Action": "hash"},
            'json'
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    }
}

    export
    default
    ApiService;
