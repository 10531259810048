import React from 'react';
import {connect} from "react-redux";
import './CalculateWindow.css';
import CalculateWindow from "./CalculateWindow";
import {changeOrderFormData, setCartDiscountsDataAC, setCartTotalsAC} from "../../../store/reducers/MainReducer";
import {checkAuthDataThunk} from "../../../store/reducers/AuthReducer";
import {compose} from "redux";
import {withLang} from "../../HOC/withLang/withLang";
import onClickOutside from "react-onclickoutside";


class CalculateWindowContainer extends React.Component {

    constructor(props){
        super(props);
        this.discountCart = false;
        this.discountPersonal = false;
        this.discountPromocode = false;
        this.getTotals = this.getTotals.bind(this);
        this.getCartCountDiscount = this.getCartCountDiscount.bind(this);
        this.getPersonalDiscount = this.getPersonalDiscount.bind(this);
        this.getPromocodeDiscount = this.getPromocodeDiscount.bind(this);
        this.getDiscounts = this.getDiscounts.bind(this);
    }

    getTotals(products, user, discount) {
        if(products) {
            let amount = products ? products.reduce(function(accumulator, currentValue, index, array) {
                return accumulator + (currentValue.count * currentValue.price);
            }, 0) : 0;

            amount = this.getDiscounts(products, amount, user, discount);

            return amount.toFixed(0);
        }
    }

    getDiscounts(products, amount, user, discount) {
        if(!this.props.user || !this.props.user.franchisees) {
            amount = this.getCartCountDiscount(products, amount);
            amount = this.getPersonalDiscount(products, amount, user);
        }
        amount = this.getPromocodeDiscount(amount, discount);
        return amount;
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if(
            (JSON.stringify(nextProps.products) !== JSON.stringify(this.props.products)) ||
            (JSON.stringify(nextProps.productsInStock) !== JSON.stringify(this.props.productsInStock)) ||
            (JSON.stringify(nextProps.productsPreOrder) !== JSON.stringify(this.props.productsPreOrder)) ||
            (nextProps.promocode !== this.props.promocode) ||
            (JSON.stringify(nextProps.user) !== JSON.stringify(this.props.user))
        ) {
            this.getTotals(nextProps.products, nextProps.user, nextProps.promocode);
        }
    }

    getCartCountDiscount(products, amount) {

        const products_count = products.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.count;
        }, 0);


        if(products_count >= 5 && products_count < 10 ) {
            this.discountCart = {
                percent: 5,
                amount: (Number(amount) * 0.05).toFixed(0)
            }
            return Number(amount) * 0.95;
        }
        if(products_count >= 10 && products_count < 15 ) {
            this.discountCart = {
                percent: 7,
                amount: (Number(amount) * 0.07).toFixed(0)
            }
            return Number(amount) * 0.93;
        }
        if(products_count >= 15 && products_count < 20 ) {
            this.discountCart = {
                percent: 10,
                amount: (Number(amount) * 0.10).toFixed(0)
            }
            return Number(amount) * 0.90;
        }
        if(products_count >= 20 ) {
            this.discountCart = {
                percent: 12,
                amount: (Number(amount) * 0.12).toFixed(0)
            }
            return Number(amount) * 0.88;
        }

        return amount;
    }

    getPersonalDiscount(products, amount, user) {
        let user_data = user ? user : this.props.user;
        if(user_data && user_data) {
            this.discountPersonal = {
                percent: Number(user_data.discount) * 100,
                amount: (amount * Number(user_data.discount)).toFixed(0)
            }
            return amount * (1 - Number(user_data.discount))
        }
        return amount;
    }

    getPromocodeDiscount(amount, discount) {
        let discount_data = discount ? discount : this.props.promocode;
        if(discount_data) {
            this.discountPromocode = {
                percent: Number(discount) * 100,
                amount: (amount * Number(discount)).toFixed(0)
            }
            return amount * (1 - Number(discount))
        }
        return amount;
    }

    render() {
        return <CalculateWindow totals={this.getTotals(this.props.products, this.props.user, this.props.promocode)}
                                type={this.props.type}
                                discountCart={this.discountCart}
                                discountPersonal={this.discountPersonal}
                                discountPromocode={this.discountPromocode}
                                changeOrderValue={this.props.changeOrderValue}
                                asPartner={this.props.formOrder.asPartner}
                                user={this.props.user}
                                full={this.props.full}
                                isAuth={this.props.isAuth}
                                getTranslate={this.props.getTranslate}
        />
    }
}

let mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        productsInStock: state.main.cart.products,
        productsPreOrder: state.main.cart.productsPreOrder,
        promocode: state.main.order.promocodeDiscount,
        isAuth: state.auth.isAuth,
        formOrder: state.main.order,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        setDiscounts: (discounts) => dispatch(setCartDiscountsDataAC(discounts)),
        setTotals: (totals) => dispatch(setCartTotalsAC(totals)),
        getAuth: () => dispatch(checkAuthDataThunk()),
        changeOrderValue: (input, value) => dispatch(changeOrderFormData(input, value)),
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang,
    onClickOutside
)(CalculateWindowContainer);