import React from 'react';
import './Registration.css';
import InputMask from 'react-input-mask';
import ReCAPTCHA from "react-google-recaptcha";

const Registration = (props) => {
    return (
        <div className="registration">
            <div className="registration-title">
                <h3>{props.getTranslate('front_registration','registration_title')}</h3>
            </div>
            <form className={"register_form"} onSubmit={(event) => {
                event.preventDefault();
                props.sendForm(props.form);
            }}>
                <div className="col-md-12">
                    <div className={'registration_sub_title'}>
                        <h4>{props.getTranslate('front_registration','registration_get_data_title')}</h4>
                    </div>
                    <div className="form-group">
                        <label>{props.getTranslate('front_registration','registration_input_name')}<span className={'text-danger'}>*</span></label>
                        <input type="text"
                               className="form-control"
                               placeholder=""
                               value={props.form.name}
                               onChange={(event) => props.changeValue("name", event.target.value)}
                               required
                        />
                    </div>
                    <div className="form-group">
                        <label>{props.getTranslate('front_registration','registration_input_surname')}<span className={'text-danger'}>*</span></label>
                        <input type="text"
                               className="form-control"
                               placeholder=""
                               value={props.form.surname}
                               onChange={(event) => props.changeValue("surname", event.target.value)}
                               required
                        />
                    </div>
                    <div className="form-group">
                        <label>{props.getTranslate('front_registration','registration_input_last_name')}</label>
                        <input type="text"
                               className="form-control"
                               placeholder=""
                               value={props.form.middle_name}
                               onChange={(event) => props.changeValue("middle_name", event.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label>{props.getTranslate('front_registration','registration_input_phone')}<span className={'text-danger'}>*</span></label>
                        <InputMask mask="+380 99 999 9999"
                                   maskChar=" "
                                   className="form-control"
                                   placeholder=""
                                   value={props.form.phone}
                                   onChange={(event) => props.changeValue("phone", event.target.value)}
                                   required={'required'}
                        />
                    </div>
                    <div className="form-group">
                        <label>Email<span className={'text-danger'}>*</span></label>
                        <input type="email"
                               className="form-control"
                               placeholder=""
                               value={props.form.email}
                               onChange={(event) => {
                                   props.checkEmail(event.target.value)
                                   props.changeValue("email", event.target.value)
                               }}
                               required
                        />
                        {props.form && !props.form.validEmail && <small className={'text-danger'}>{props.getTranslate('front_registration','registration_register_email_error')}</small>}
                    </div>
                    <div className="form-group registration_news">
                        <div className="custom-control custom-checkbox mr-sm-2 registration_add_company_data">
                            <input type="checkbox"
                                   className="custom-control-input"
                                   value={props.form.regCompany}
                                   onChange={(event) => props.changeValue("regCompany", event.target.checked)}
                                   id="reg_company_checkbox"
                                   checked={props.form.regCompany ? 'checked' : ''}
                            />
                            <label className="custom-control-label" htmlFor="reg_company_checkbox">
                                {props.getTranslate('front_registration','registration_register_company_title')}
                            </label>
                        </div>
                    </div>
                    {props.form.regCompany ?
                        <>
                            <div className={'registration_sub_title'}>
                                <h4>{props.getTranslate('front_registration','registration_get_data_company_title')}</h4>
                            </div>
                            <div className="form-group">
                                <label>{props.getTranslate('front_registration','registration_input_company_name')}<span className={'text-danger'}>*</span></label>
                                <input type="text"
                                       className="form-control"
                                       placeholder=""
                                       value={props.form.company_name}
                                       onChange={(event) => props.changeValue("company_name", event.target.value)}
                                       required
                                />
                            </div>
                            <div className="form-group">
                                <label>{props.getTranslate('front_registration','registration_input_company_code')}<span className={'text-danger'}>*</span></label>
                                <input type="text"
                                       className="form-control"
                                       placeholder=""
                                       value={props.form.company_code}
                                       onChange={(event) => props.changeValue("company_code", event.target.value)}
                                       required
                                />
                            </div>
                            <div className="form-group">
                                <label>{props.getTranslate('front_registration','registration_input_company_address')}<span className={'text-danger'}>*</span></label>
                                <input type="text"
                                       className="form-control"
                                       placeholder=""
                                       value={props.form.company_address}
                                       onChange={(event) => props.changeValue("company_address", event.target.value)}
                                       required
                                />
                            </div>
                            <div className="form-group">
                                <label>
                                    {props.getTranslate('front_registration','registration_input_company_number_pdv')}
                                    <br/>
                                    <small>{props.getTranslate('front_registration','registration_input_company_number_pdv_info')}</small>
                                </label>
                                <input type="text"
                                       className="form-control"
                                       placeholder=""
                                       value={props.form.company_number_pdv}
                                       onChange={(event) => props.changeValue("company_number_pdv", event.target.value)}
                                />
                            </div>
                        </>
                    :
                        ''
                    }

                    <div className={'registration_sub_title'}>
                        <h4>{props.getTranslate('front_registration','registration_create_password_title')}</h4>
                    </div>
                    <div className="form-group">
                        <label>{props.getTranslate('front_registration','registration_input_password')}<span className={'text-danger'}>*</span></label>
                        <input type="password"
                               className="form-control"
                               placeholder=""
                               value={props.form.password}
                               onChange={(event) => props.changeValue("password", event.target.value)}
                               required
                        />
                    </div>
                    <div className="form-group">
                        <label>
                            {props.getTranslate('front_registration','registration_input_password')}<br/>
                            <small>{props.getTranslate('front_registration','registration_input_password_again')}</small>
                            <span className={'text-danger'}>*</span>
                        </label>
                        <input type="password"
                               className="form-control"
                               placeholder=""
                               value={props.form.password_check}
                               onChange={(event) => props.changeValue("password_check", event.target.value)}
                               required
                        />
                    </div>
                    <div className="form-group">
                        <ReCAPTCHA
                            sitekey="6LcW9cAUAAAAACUUdNuGmjuoMbXG8Sd1F5LLmm0v"
                            onChange={() => props.changeValue("captcha",true)}
                        />
                    </div>
                    <div className={'registration_sub_title'}>
                        <h4>{props.getTranslate('front_registration','registration_news_email_title')}</h4>
                    </div>
                    <div className="form-group registration_news">
                        <div className="custom-control custom-checkbox mr-sm-2 registration_add_company_data">
                            <input type="checkbox"
                                   className="custom-control-input"
                                   id="newsletter_yes"
                                   value={props.form.newsletter}
                                   onChange={(event) => props.changeValue("newsletter", event.target.checked)}
                                   checked={props.form.newsletter ? 'checked' : ''}
                            />
                            <label className="custom-control-label" htmlFor="newsletter_yes">
                                {props.getTranslate('front_registration','registration_news_email_true_title')}
                            </label>
                        </div>
                    </div>
                    <div className="form-group registration_button">
                        {props.form && props.form.validEmail &&
                            <button type="submit" className="btn btn-success" disabled={props.form.captcha ? "" : "disabled"}>{props.getTranslate('front_registration','registration_button_title')}</button>
                        }
                    </div>
                </div>

            </form>
        </div>


    )
}

export default Registration;