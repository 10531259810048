import React from 'react';
import './Login.css';
import {Link} from "react-router-dom";
import LangLink from "../Common/LangLink";
import {SeoTagsStatic} from "../SeoTagsStatic";

const Login = (props) => {
    return (
        <>
            <SeoTagsStatic page={'login'}/>
            <div className="auth">
                <div className="login-box">
                    <div className="login-logo">
                        <p>{props.getTranslate('front_login','login_authorization_title')}</p>
                    </div>
                    <div className="card">
                        <div className="card-body login-card-body">
                            <form onSubmit={(event) => {
                                event.preventDefault();
                                props.getAuth(props.form.login, props.form.pass)
                            }}>
                                <div className="input-group mb-3">
                                    <input type="text"
                                           className="form-control"
                                           placeholder="Email"
                                           value={props.form.login}
                                           onChange={(event) => props.changeValue("login", event.target.value)}
                                    />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope"> </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <input type="password"
                                           className="form-control"
                                           placeholder={props.getTranslate('front_login','login_password')}
                                           value={props.form.pass}
                                           onChange={(event) => props.changeValue("pass", event.target.value)}
                                    />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-lock"> </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <button type="submit" className="btn custom_button btn-block">{props.getTranslate('front_login','login_button_entry')}</button>
                                    </div>
                                </div>
                            </form>
                            {props.form.isFailure ? <div className={'text-center text-danger'}>
                                <small>{props.getTranslate('front_login','login_error')}</small>
                            </div> : ''}
                            <div className="social-auth-links text-center mb-3">
                                <p className="mb-1">
                                    <LangLink to={'/account/reset_password'}>{props.getTranslate('front_login','login_forget_password')}</LangLink>
                                </p>
                            </div>
                            <div className="social-auth-links text-center mb-3">
                                <p className="mb-1">
                                    <a href="/registration">{props.getTranslate('front_login','login_button_register')}</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Login;