import React from 'react';

const PrivacyPolicy = (props) => {
    return <>
        <div className={"products_gallery_main_title"}>
            <h3>Публічна оферта</h3>
        </div>
        <div className="container">
            <div className={'text-right'}>
                <p>Затверджено Рішенням власника</p>
                <p>від «25» листопада 2020 року №25-11/20</p>
            </div>
            <div>
                <p className={'text-center'}><b>ДОГОВІР ПУБЛІЧНОЇ ОФЕРТИ</b></p>
                <p><b>ПРИВАТНЕ ПІДПРИЄМСТВО «МЕДБАЙ»</b>, що належним чином зареєстроване відповідно до законодавства
                    України
                    (ідентифікаційний код юридичної особи 32658838), місцезнаходження: Україна, 04205, місто Київ,
                    вулиця
                    Тимошенка Маршала, будинок 29, квартира 113, в особі Директора – Білого Віктора Михайловича, котрий
                    діє на
                    підставі Статуту, керуючись статтями 633, 641 Цивільного кодексу України, пропонує необмеженому колу
                    осіб
                    приєднатися до цього Договору публічної оферти, який є пропозицією про укладення договору роздрібної
                    купівлі-продажу на нижчевикладених умовах:</p>
            </div>
            <div>
                <p><b>1. Терміни та визначення</b></p>
                <p><i>Акційна знижка</i> - це знижка, яка надається на певний Товар. Розмір знижки та умови її надання
                    (за їх
                    наявності) вказуються на сторінці Товару.</p>
                <p><i>Акцепт</i> – повне, безумовне та беззастережне прийняття Покупцем умов оферти. Здійснення
                    Замовлення
                    будь-якого Товару або послуги на сайті Продавця свідчить про прийняття Покупцем умов оферти.</p>
                <p><i>Договір</i> – цей Договір публічної оферти.</p>
                <p><i>Замовлення</i> – належним чином оформлений запит Покупця, що включає перелік Товарів, обраних в
                    каталозі
                    Інтернет-магазину, що є актом прийняття рішення Покупцем про купівлю даних Товарів.</p>
                <p><i>Інтернет-магазин</i> - офіційний інтернет-магазин Продавця, розташований в мережі Інтернет за
                    адресою:
                    домен medbuy.online та його субдомени. В рамках цього договору, поняття Інтернет-магазин, сайт, а
                    також
                    інтернет адреса medbuy.online і всі похідні визначення є рівносильними і трактуються ідентично, за
                    контекстом оферти.</p>
                <p><i>Корзина</i> - перелік Товарів, які Покупець планує придбати. В корзині можна вносити зміни до
                    кількості та
                    виду Товарів до оформлення Замовлення.</p>
                <p><i>Покупець</i> - будь-яка фізична особа, відвідувач веб-сайту, розміщеного в мережі Інтернет за
                    адресою:
                    домен medbuy.online та його субдомени, яка має намір зробити Замовлення Товару та/або здійснює
                    Замовлення з
                    метою покупки Товару, і тим самим підтверджує свою згоду з усіма умовами даної оферти.</p>
                <p><i>Продавець</i> - ПРИВАТНЕ ПІДПРИЄМСТВО «МЕДБАЙ» (Адреса місцезнаходження: Україна, 04205, місто
                    Київ,
                    вулиця Тимошенка Маршала, будинок 29, офіс 113, тел.: +38098-353-55-60, e-mail: info@medbuy.online.
                </p>
                <p><i>Публічна оферта</i> – пропозиція Продавця (викладена на сайті Продавця), адресована необмеженому
                    колу
                    фізичних осіб укласти даний договір на визначених Продавцем умовах (надалі за текстом – оферта).</p>
                <p><i>Реєстрація</i> – зазначення Покупцем особистих даних в спеціальній формі, завдяки якій
                    здійснюється
                    подальша взаємодія Інтернет-магазину та Покупця.</p>
                <p><i>Сторона</i> – Покупець або Продавець окремо</p>
                <p><i>Сторони</i> – Покупець і Продавець разом.</p>
                <p><i>Товар</i> - об’єкт угоди Сторін за цим Договором, представлений в Інтернет-магазині з метою їх
                    продажу.
                </p>
            </div>
            <div>
                <p><b>2. Загальні положення</b></p>
                <p>2.1. Продавець здійснює продаж Товарів через Інтернет-магазин, розміщений в мережі Інтернет за
                    адресою: домен
                    medbuy.online та його субдомени.</p>
                <p>2.2. Здійснюючи Замовлення Товару в Інтернет-магазині, Покупець погоджується з умовами продажу,
                    розміщеними
                    на сайті Товарів. У разі незгоди з умовами продажу Покупець повинен негайно припинити використання
                    сервісу і
                    покинути Інтернет-магазин.</p>
                <p>2.3. Діючі умови продажу Товарів, а також розміщена на сайті інформація про Товари є публічною
                    офертою
                    відповідно до ст. 633 та ст. 641 Цивільного кодексу України.</p>
                <p>2.4. Діючі умови можуть бути змінені Продавцем без повідомлення про це Покупця в односторонньому
                    порядку.
                    Нова редакція умов цього Договору вступає в силу з моменту її опублікування на сайті, якщо інше не
                    буде
                    передбачено самим Договором.</p>
                <p>2.5. Договір набуває чинності з моменту підтвердження Продавцем Покупцеві в будь-якій формі прийняття
                    Замовлення при оформленні Покупцем Замовлення на сайті, а також з моменту прийняття від Покупця
                    Замовлення
                    за телефоном, вказаним на сайті.</p>
                <p>2.6. Повідомляючи Продавцю свій номер телефону та адресу електронної пошти, Покупець дає згоду на
                    використання зазначених засобів зв’язку Продавцем, а також третіми особами, залученими ним до
                    виконання
                    зобов’язань перед Покупцем.</p>
                <p>Зазначені дані можуть використовуватися з метою поширення інформації про передачу Замовлення на
                    доставку, а
                    також іншу інформацію, безпосередньо пов’язану з виконанням зобов’язань перед Покупцем в рамках цієї
                    оферти.
                </p>
                <p>Також, зазначені Покупцем дані можуть використовуватися для розповсюдження рекламних акцій, новин про
                    акції,
                    знижки, а також інших дій Продавця.</p>
                <p>Здійснюючи Замовлення, Покупець погоджуються з тим, що Продавець може доручити виконання Договору
                    третій
                    особі, при цьому залишаючись відповідальним за його виконання.</p>
            </div>
            <div>
                <p><b>3. Реєстрація на сайті</b></p>

                <p>3.1. Ідентифікація Покупця при реєстрації на сайті Продавця здійснюється через особисту електронну
                    пошту
                    Покупця або через власні акаунти Покупця в соціальних мережах.</p>
                <p>3.2. В процесі реєстрації Покупець заповнює на сайті реєстраційну форму із зазначенням П.І.Б.,
                    електронної
                    адреси, номеру телефона, генерує унікальний пароль доступу, а також заповнює інші дані, передбачені
                    реєстраційною формою. Доступ до Облікового запису Покупця виникає після проходження реєстрації та
                    його
                    активації за допомогою листа, що надсилається на зазначену Покупцем електронну адресу.</p>
                <p>3.3. Покупець зобов’язаний надавати правдиву та повну інформацію при заповненні форми для реєстрації
                    на сайті
                    та слідкувати за її актуальністю. Покупець підтверджує та гарантує, що надані ним дані в процесі
                    реєстрації
                    на сайті є правдивими.</p>
                <p>3.4. Якщо адміністрація сайту виявить, що інформація надана Покупцем, є неправдивою, то реєстрація
                    Покупця на
                    сайті може бути скасована або доступ до Облікового запису Покупця може бути (тимчасово) призупинений
                    до
                    моменту виправлення такої недостовірної інформації. Про виявлення недостовірної інформації
                    адміністрація
                    сайту сповіщає Покупця шляхом направлення відповідного повідомлення згідно контактних даних, наданих
                    Покупцем при реєстрації.</p>
                <p>3.5. Користування сайтом відбувається за допомогою входу через інтернет браузери, встановлені на
                    комп’ютері
                    чи іншому електронному пристрої Покупця. Продавець не несе відповідальності за неповне чи некоректне
                    відображення інформації на сайті, що пов’язане з несвоєчасним оновленням версій інтернет браузера
                    Покупця.
                </p>
                <p>3.6. Відповідальність за збереження конфіденційності власного Облікового запису (логіна та пароля), а
                    також
                    за всі
                    дії, здійснені з Обліковим записом, несе безпосередньо сам Покупець.</p>
                <p>3.7. Покупець не має права передавати, продавати чи іншим способом відчужувати свої логін та пароль з
                    метою
                    доступу до сайту третіх осіб. Відповідальність за дії третьої особи, якій був переданий логін та
                    пароль для
                    доступу до сайту, несе Покупець.</p>
                <p>3.8. Якщо у Покупця є підстави вважати, що його логін та пароль, які використовуються для входу на
                    сайт, були
                    розкриті третім особам або можуть бути використані третіми особами, Покупець повинен негайно
                    повідомити про
                    це Продавця та змінити дані авторизації для входу на сайт. Продавець не несе відповідальності за
                    будь-які
                    збитки, спричинені несанкціонованим використанням Облікового запису Покупця.</p>
                <p>3.9. Акцептуючи цю оферту, Покупець погоджується отримувати інформаційні повідомлення на вказану при
                    реєстрації адресу електронної пошти та/або номер мобільного телефону.</p>
                <p>3.10. Комунікація Покупця з іншими покупцями на сайті здійснюється за допомогою обміну коментарями
                    під
                    розміщеними оголошеннями. Адміністрація сайту не несе відповідальності за зміст повідомлень,
                    залишених
                    покупцями сайту у коментарях.</p>
            </div>
            <div>
                <p><b>4. Предмет Договору</b></p>
                <p>4.1. Дана оферта є публічним договором, який вважається укладеним між Продавцем, з однієї сторони, та
                    Покупцем, з іншої сторони, з моменту Акцепту останнім всіх без винятку умов та положень даної
                    оферти.</p>
                <p>4.2. В порядку та на умовах, визначених цією офертою, Продавець зобов’язується продати та поставити
                    Покупцю
                    Товари, що реалізуються в Інтернет-магазині, а Покупець зобов’язується оплатити та прийняти їх. </p>
                <p>4.3. Товари реалізуються на підставі цього Договору. Діями, що свідчать про згоду дотримуватись умов
                    цього
                    Договору, є Акцепт Покупцем умов публічної оферти цього Договору.</p>
            </div>
            <div>
                <p><b>5. Права та обов’язки Сторін</b></p>
                <p>5.1. Покупець має право:</p>
                <p>5.1.1. Користуватися всіма додатковими пропозиціями та акціями Продавця, зокрема, але не виключно:
                    знижками,
                    спеціальними бонусними пропозиціями тощо, що надаються Продавцем на власний розсуд та при наявності
                    умов їх
                    надання.</p>
                <p>5.1.2. Отримувати необхідну та достовірну інформацію про Покупця, Товари, що ним реалізуються, а
                    також іншу
                    пов’язану з ними інформацію.</p>
                <p>5.1.3. Письмово звертатись до Продавця з пропозиціями щодо покращення клієнтських сервісів.</p>
                <p>5.2. Покупець зобов’язується:</p>
                <p>5.2.1. Надавати повну та достовірну інформацію, а за потреби документи, необхідні для ідентифікації
                    Покупця,
                    в тому числі адресу своєї електронної пошти, контактні номери телефону, адресу доставки.</p>
                <p>5.2.2. Належним чином зберігати надані йому логін і пароль до особистого кабінету Покупця та
                    повідомляти
                    Продавця про втрату або отримання несанкціонованого доступу третіх осіб до таких даних.</p>
                <p>5.2.3. Оплачувати вартість Замовлених Товарів за вказаними Продавцем цінами.</p>
                <p>5.3. Продавець має право:</p>
                <p>5.3.1. Залучати третіх осіб для виконання своїх зобов’язань за цим Договором.</p>
                <p>5.3.2. Приймати оплату за Товари, що реалізуються через Інтернет-магазин на рахунок/рахунки Продавця,
                    зокрема, зазначені на сайті.</p>
                <p>5.3.3. Перевіряти надану Покупцем інформацію, а також виконання Покупцем умов Договору.</p>
                <p>5.3.4. Призупинити виконання цього Договору на період перевірки виконання Покупцем умов Договору, в
                    разі
                    виникнення обґрунтованих сумнівів у належному виконанні Покупцем та/або обґрунтованої підозри у
                    порушенні
                    умов Договору.</p>
                <p>5.3.5. Вимагати від Покупця виконання умов цього Договору.</p>
                <p>5.3.6. Використовувати адресу електронної пошти, зазначену Покупцем, для направлення листів та
                    повідомлень
                    Покупцю.</p>
                <p>5.4. Продавець зобов’язується:</p>
                <p>5.4.1. Забезпечувати Покупцю можливість отримання Товарів належної якості відповідно до цього
                    Договору.</p>
                <p>5.4.2. Дотримуватись умов цього Договору при реалізації Товарів через Інтернет-магазин.</p>
                <p>5.4.3. Забезпечувати Покупцю можливість отримання інформації про Продавця та Товари, що ним
                    реалізуються.</p>
                <p>5.4.4. Приймати до розгляду письмові пропозиції Покупця щодо покращення клієнтських сервісів.</p>
            </div>
            <div>
                <p><b>6. Товар та порядок здійснення покупки</b></p>
                <p>6.1. Продавець забезпечує наявність у себе представлених в Інтернет-магазині Товарів.
                    Фотографії/зображення
                    Товару є ілюстраціями до нього, і фактичний зовнішній вигляд Товару може відрізнятися від таких
                    фотографій/зображень. Супроводжуючі Товар описи і характеристики не є вичерпними і можуть містити
                    друкарські
                    помилки або неточності. З метою уточнення інформації щодо даного Товару Покупець може в будь-який
                    час
                    звернутися до служби підтримки.</p>
                <p>6.2. При відсутності у Продавця замовлених Покупцем Товарів, Продавець має право виключити вказаний
                    Товар із
                    Замовлення або анулювати Замовлення, повідомивши про це Покупця за допомогою листа на електронну
                    пошту
                    Покупця або зв’язавшись у будь-який спосіб з Покупцем по телефону.</p>
                <p>6.3. Покупець несе повну відповідальність за надання недостовірних даних, яке зробило неможливим
                    виконання
                    Продавцем своїх зобов’язань перед Покупцем.</p>
                <p>6.4. У разі повної або часткової відсутності заздалегідь оплаченого Замовлення, Покупцеві
                    пропонується
                    заміна. У разі відсутності Товару на заміну повна вартість анульованого Товару повертається
                    Продавцем
                    Покупцю способом, що погоджується між Продавцем і Покупцем окремо, або способом, яким Покупцем була
                    здійснена оплата за Товар.</p>
                <p>6.5. Максимальний термін обробки і збору Замовлення становить 5 робочих днів з дати отримання
                    Продавцем
                    такого Замовлення. Після оформлення Замовлення на сайті Покупцеві надається інформація про
                    орієнтовну дату
                    доставки шляхом надсилання повідомлення на електронну адресу, вказану Покупцем під час оформлення
                    Замовлення, або у будь-який спосіб по телефону.</p>
            </div>
            <div>
                <p>7. Доставка Замовлення</p>
                <p>7.1. Способи, строк та вартість доставки Товарів вказані на сайті Продавця та обумовлені умовами
                    цього
                    Договору.</p>
                <p>7.2. Доставка Товарів Покупцеві здійснюється за умови передоплати Замовлення у розмірі – 100% від
                    вартості
                    Замовлення. </p>
                <p>7.3. Доставка Товарів Покупцеві здійснюється шляхом кур’єрської доставки в містах, де розташовані
                    відділення
                    та/або склади Продавця, або поштовим відправленням.</p>
                <p>7.4. Кур’єрська доставка здійснюється за рахунок Продавця за адресою, вказаною Покупцем у Замовленні.
                    Замовлення вручається особисто Покупцю або третій особі, вказаній у Замовленні.</p>
                <p>7.5. Доставка Замовлень по території України здійснюється поштовими відправленнями через поштових
                    операторів
                    «Нова Пошта», «Укрпошта», інших операторів зазначених на сайті Продавця, за рахунок Продавця.</p>
                <p>7.6. Відправка Замовлень здійснюється у 3-денний строк від дати підтвердження Замовлення при
                    наявності Товару
                    на складі. Під час доставки Замовлення вручається Покупцеві або третій особі, вказаній у Замовленні
                    в якості
                    Покупця чи отримувача.</p>
                <p>7.7. Відповідальність за випадкове пошкодження або знищення Товару переходить до Покупця з моменту
                    передачі
                    йому Замовлення та проставлення в документах, що підтверджують доставку Замовлення, підпису
                    отримувача.</p>
            </div>
            <div>
                <p><b>8. Ціна та оплата Товару</b></p>
                <p>8.1. Ціна Товару зазначається в національній валюті і включає в себе всі податки, передбачені
                    законодавством
                    України.</p>
                <p>8.2. Ціна Товару зазначається на сайті. У разі неправильного зазначення ціни замовленого Товару,
                    Продавець
                    інформує про це Покупця для підтвердження Замовлення з відкоригованою ціною або анулювання
                    Замовлення.</p>
                <p>8.3. Ціна Товару на сайті може бути змінена Продавцем в односторонньому порядку. При цьому ціна на
                    вже
                    замовлений Покупцем Товар зміні не підлягає.</p>
                <p>8.4. Оплата Товару здійснюється Покупцем після підтвердження Продавцем можливості виконання
                    Замовлення на
                    умовах передоплати у розмірі 100% від вартості Замовлення.</p>
                <p>8.5. Продавець має право надавати знижки на Товари і встановлювати програму бонусів. Види знижок,
                    бонусів,
                    порядок і умови їх нарахування, вказані на сайті, можуть бути змінені Продавцем в односторонньому
                    порядку.
                </p>
            </div>
            <div>
                <p><b>9. Порядок повернення Товару та коштів</b></p>
                <p>9.1. Цей розділ стосується повернення виключно тих Товарів, що не входять до переліку товарів,
                    визначених
                    Постановою Кабінету Міністрів України від 19.03.1994 р. № 172 "Про реалізацію окремих положень
                    Закону
                    України "Про захист прав споживачів".
                    Повернення Товарів медичного призначення (лікарських засобів та медичних виробів), а також сплачених
                    Покупцем коштів за придбані Товари медичного призначення відбувається лише у випадку підтвердження
                    неналежної якості таких Товарів, що здійснюється на умовах визначених чинним законодавством України
                    і цим
                    Договором та лише у випадку пред’явлення розрахункового документу, що підтверджує придбання
                    відповідного
                    Товару медичного призначення неналежної якості.
                    Факт неналежної якості Товарів медичного призначення підтверджується: (і) висновком щодо якості
                    лікарських
                    засобів, виданим підпорядкованою лабораторією територіального органу Державної служби України з
                    лікарських
                    засобів та контролю за наркотиками та/або уповноваженою лабораторією; (іі) розпорядженням Державної
                    служби
                    України з лікарських засобів щодо заборони обігу лікарського засобу.
                    У випадку встановлення у визначеному законом порядку продажу Товару медичного призначення неналежної
                    якості,
                    Покупець має право на свій розсуд або просити повернути сплачені кошти за такий Товар або замінити
                    його на
                    аналогічний Товар належної якості.</p>
                <p>9.2. Покупець має право відмовитися від всього отриманого, але не використаного Товару та розірвати
                    Договір
                    відповідно до Закону України «Про захист прав споживачів» протягом 14 календарних днів.
                    Повернути або обміняти Товар протягом терміну, встановленого Законом України "Про захист прав
                    споживачів",
                    Покупець може, звернувшись за контактними даними, вказаними на сайті.</p>
                <p>9.3. Товар, що повертається, повинен супроводжуватись власноруч заповненою письмовою заявою Покупця
                    про
                    повернення такого Товару із зазначенням причини його повернення, а також реквізитів рахунку Покупця
                    для
                    повернення вартості Товару. Відправка Товарів, що повертаються, здійснюється за рахунок Покупця.</p>
                <p>9.4. Повернення Товару належної якості:</p>
                <p>9.4.1. Повернення Товару належної якості можливо протягом 14 календарних днів з моменту його
                    отримання за
                    умовами, що Товар не був у використанні, а також були збережені: його товарний вигляд, споживчі
                    властивості,
                    прикріплені етикетки, ярлики, а також (при наявності) фабрична упаковка. Не допускається нанесення
                    на
                    упаковку будь-яких написів, поміток тощо.</p>
                <p>9.4.2. У разі відмови Покупця від Товару згідно пункту 9.4.1 Договору Продавець повертає йому
                    вартість Товару
                    протягом 7 (семи) робочих днів з дати отримання Товару.</p>
                <p>9.4.3. Повернення вартості Товару здійснюється виключно особі, зазначеній в бланку Замовлення на
                    такий Товар.
                    Повернення здійснюється переказом коштів за реквізитами Покупця, зазначеними в заяві про повернення
                    Товару,
                    виходячи з вартості Товару на момент його покупки. Покупцеві повертається тільки вартість Товару.
                    Вартість
                    пересилання повернення Товару оплачує Покупець.</p>
                <p>9.5. Повернення Товару неналежної якості:</p>
                <p> 9.5.1. Під Товаром неналежної якості розуміється Товар, який не може забезпечити виконання своїх
                    функціональних якостей. Відмінність елементів дизайну, кольору або оформлення від заявлених в описі
                    на сайті
                    не є ознакою неналежної якості Товару та/або неможливості його використання за призначенням.</p>
                <p>9.5.2. Після фактичного прийняття Покупцем Замовлення претензії до зовнішніх дефектів Товару, його
                    кількості,
                    комплектності та товарного вигляду не приймаються.</p>
                <p>9.5.3. Якщо Покупцеві був переданий Товар неналежної якості, Покупець має право відповідно до Закону
                    України
                    «Про захист прав споживачів» вимагати повернення сплачених грошових коштів.</p>
                <p>9.5.4. Гарантійний термін на Товари встановлюється виробником і вказується на етикетці або
                    коробці.</p>
                <p>9.5.5. Продавець не відповідає за недоліки Товару, які виникли після передачі його Покупцю внаслідок
                    порушення Покупцем правил користування або зберігання Товару, дій третіх осіб або обставин
                    непереборної
                    сили.</p>
                <p>9.5.6. Якщо причиною повернення послужила помилка Продавця Інтернет-магазину, Покупцеві повертається
                    повна
                    вартість Замовлення. Також Продавець Інтернет-магазину оплачує зворотню пересилку Товару. Під
                    помилкою
                    працівника Продавця Інтернет-магазину мається на увазі: відправлення Товару іншої моделі, об’єму або
                    Товару
                    іншого виробника. Невідповідність розміру чи кольору упаковки Товару фотографії Товару на сайті
                    Продавця не
                    вважається помилкою Продавця та не може слугувати причиною повернення Товару, оскільки на різних
                    відображуючих пристроях одне і те ж зображення може виглядати по різному.</p>
            </div>
            <div>
                <p><b>10. Конфіденційність і захист персональної інформації</b></p>
                <p>10.1. Персональні дані Покупця обробляються відповідно до Закону України «Про захист персональних
                    даних».</p>
                <p>10.2. Персональні дані збираються в обсязі, необхідному для оформлення, підтвердження, оплати та
                    доставки
                    Замовлення та виключно з метою дотримання вимог, які регулюють правовідносини у сфері
                    бухгалтерського та
                    податкового обліку, реклами. Термін зберігання і обробки персональних даних Покупця становить три
                    роки.</p>
                <p>10.3. Надаючи свої персональні дані під час реєстрації на сайті, Покупець погоджується на їх обробку
                    Продавцем, в тому числі з метою просування Продавцем Товарів та послуг.</p>
                <p>10.4. Продавець має право відправляти інформаційні, у тому числі рекламні повідомлення на електронну
                    пошту і
                    мобільний телефон Покупця.
                    Покупець має право відмовитися від отримання рекламної та іншої інформації без пояснення причин
                    відмови.
                    Сервісні повідомлення, які інформують Покупця про Замовлення та етапи його обробки, відправляються
                    автоматично і не можуть бути відхилені Покупцем.</p>
                <p>10.5. Продавець має право використовувати технологію «сookies». «Cookies» не містять конфіденційної
                    інформації та не передаються третім особам.</p>
                <p>10.6. Продавець не несе відповідальність за відомості, надані Покупцем на сайті в загальнодоступній
                    формі.
                </p>
                <p>10.7. Для поліпшення якості обслуговування Продавець має право здійснювати запис телефонних розмов з
                    Покупцем. При цьому Продавець зобов’язується: попереджати спроби несанкціонованого доступу до
                    інформації,
                    отриманої в ході телефонних переговорів, та/або передачу третім особам, які не мають безпосереднього
                    відношення до виконання Замовлень, відповідно до Закону України «Про інформацію».</p>
                <p>10.8. Продавець зобов’язується забезпечити конфіденційність та безпеку персональних даних Покупця під
                    час їх
                    обробки. Співробітники Продавця здійснюють обробку персональних даних Покупця виключно у зв’язку з
                    виконанням їхніх професійних обов’язків та зобов’язуються не допускати розголошення персональних
                    даних, які
                    їм були довірені або стали відомі у зв’язку з виконанням професійних, службових та трудових
                    обов’язків.</p>
                <p>10.9. Надані Покупцем персональні дані можуть бути передані виключно у порядку, встановленому
                    законодавством
                    України про захист персональних даних.</p>
            </div>
            <div>
                <p><b>11. Термін дії публічної оферти</b></p>
                <p>11.1. Діюча публічна оферта набуває чинності з моменту Акцепту Покупцем і діє до моменту відкликання
                    Акцепту
                    публічної оферти.</p>
                <p>11.2. Замовляючи Товар в Інтернет-магазині, Покупець погоджується з умовами даної публічної оферти. У
                    разі
                    незгоди з умовами продажу Користувач повинен негайно припинити використання сервісу та покинути
                    сайт.</p>
                <div>
                    <p><b></b>12. Особливі умови</p>
                    <p>12.1. Продавець має право передавати свої права та обов’язки перед Покупцями третім особам.</p>
                    <p>12.2. Інтернет-магазин та супутні сервіси можуть бути тимчасово/частково або повністю недоступні
                        у
                        зв’язку з проведенням профілактичних робіт або з інших технічних причин. Технічна служба
                        Продавця має
                        право періодично проводити необхідні профілактичні або інші роботи з попереднім повідомленням
                        Покупців
                        або без такого повідомлення.</p>
                </div>
                <p><b>13. Відповідальність сторін</b></p>
                <p>13.1. Сторони несуть відповідальність, передбачену чинним законодавством України.</p>
                <p>13.2. Сторони не несуть відповідальність за порушення своїх зобов’язань за цим Договором, якщо воно
                    сталося
                    не з їх вини. Сторона вважається невинуватою, якщо вона доведе, що вжила всіх залежних від неї
                    заходів для
                    належного виконання Договору.</p>
                <p>13.3. Сторони не несуть відповідальність один перед одним за непрямий збиток (у тому числі за
                    недоотриманий
                    прибуток).</p>
                <p>13.4. Продавець не несе відповідальності, якщо порушення ним зобов’язань було зумовлено дією обставин
                    непереборної сили (форс-мажору). Під обставинами непереборної сили маються на увазі: пожежі,
                    землетруси,
                    інші природні явища, стихійні лиха, дії третіх осіб, військові дії, карантинні обмеження, прийняття
                    законодавчих актів та інші незалежні від Продавця обставини, які роблять неможливим своєчасне, повне
                    та
                    належне виконання Продавцем своїх зобов’язань за Договором.</p>
                <p>13.5. Відповідальність Продавця перед Покупцем у випадку пред’явлення останнім вимог/претензій про
                    відшкодування шкоди та компенсації моральної шкоди в результаті не поставки та/або поставки Товарів
                    неналежної якості, що підтверджується висновком експерта з контролю якості, відшкодовується виключно
                    в межах
                    вартості відповідних Товарів, оплачених Покупцем.</p>
                <p>13.6. Покупець не несе відповідальності за неналежне функціонування та недоступність певних сегментів
                    мережі
                    Інтернет, які знаходяться поза контролем Продавця.</p>
                <div>
                    <p><b>14. Порядок вирішення спорів</b></p>
                    <p>14.1. До відносин між Покупцем і Продавцем застосовуються положення законодавства України.</p>
                    <p>14.2. Визнання будь-якого положення або пункту даного Договору чи додатків до нього не дійсним,
                        не
                        впливає на дійсність інших положень і умов Договору.</p>
                    <p>14.3. У разі виникнення питань і претензій з боку Покупця, він повинен звернутися до Продавця за
                        розміщеним на сайті контактами.</p>
                    <p>14.4. Усі виникаючі спори та суперечки сторони зобов’язані намагатися вирішити шляхом
                        переговорів.</p>
                    <p>14.5. У разі якщо сторони не дійшли згоди, всі спори та суперечки вирішуються в судовому порядку
                        за
                        місцезнаходженням Продавця.</p>
                </div>
                <div>
                    <p><b>15. Реквізити Продавця</b></p>
                    <p><b>ПП «МЕДБАЙ»</b></p>
                    <p>Місцезнаходження: Україна, 04205, місто Київ, вулиця Тимошенка Маршала, будинок 29, oф. 113.</p>
                    <p>Ідентифікаційний код юридичної особи: 32658838</p>
                    <p>Банківські реквізити: IBAN: UA96 3005 2800 0002 6004 0000 0194 4, в АТ «ОТП банк», МФО 300528</p>
                    <p>Адреса місцезнаходження банку: Україна, 01033, місто Київ, вулиця Жилянська, 43</p>
                    <p>Телефон: +380 98 353 5560</p>
                    <p>Адреса електронної пошти: info@medbuy.online</p>
                </div>
            </div>
        </div>
    </>
}

export default PrivacyPolicy;