import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import {formatSum} from "../../store/reducers/MainReducer";
import {compose} from "redux";
import {withPagination} from "../HOC/withPagination/withPagination";
import {formateDateStore} from "../../App";
import {useDispatch} from "react-redux";
import {getCustomerOrdersDataThunk} from "../../store/reducers/AuthReducer";

const AccountBonus = (props) => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCustomerOrdersDataThunk())
    }, []);

    const checkValues = () => {
        if(props.form.bonus) {
            if(props.form.bonus < 1000)
             return {
                check: false,
                 message: `${props.getTranslate('front_account','account_bonus_info')}`
             }
            if(props.user && props.form.bonus > props.user.partner_info[0].bonus) {
                return {
                    check: false,
                    message: `${props.getTranslate('front_account','account_bonus_customer_amount')} - ${Number(props.user.partner_info[0].bonus).toFixed(2)}`
                }
            }
            return {
                check: true
            }
        }
        return {
            check: true
        }
    }

    return <>
        <div className="container-fluid">
            <div className={"row"}>
                <div className="col-md-12">
                    <h4 className="info_page_title">{props.getTranslate('front_account','account_bonus_get_title')}</h4>
                    {props.user && props.user.partner_info && props.user.partner_info[0] && props.user.partner_info[0].bonus ?
                        <p>{props.getTranslate('front_account','account_bonus_amount_in_partner')} {props.user && props.user.partner_info && props.user.partner_info[0] && props.user.partner_info[0].bonus ? props.user.partner_info[0].bonus : ""}</p>
                    : ""}
                </div>
            </div>
            <div className={"row"}>
                <div className="col-md-12">
                    <form className={'partner_bonus_form ' + (!checkValues().check ? 'was-validated' : '')} onSubmit={(event) => {
                        event.preventDefault();
                        props.createPartnerBonus(props.form.bonus, props.user.id);
                    }}>
                        <div className="form-group">
                            <div className="input-group">
                                <input className={'form-control'}
                                       value={props.form && props.form.bonus}
                                       type={'number'}
                                       onChange={(event) => props.changeValue('bonus', event.target.value)}
                                       max={props.user && props.user.partner_info && props.user.partner_info[0] ? Number(props.user.partner_info[0].bonus).toFixed(2) : 0}
                                       min={1000}
                                       required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <button
                                className={"btn btn-success"}
                                type={'submit'}
                            >{props.getTranslate('front_account','account_button_bonus_get')}</button>
                        </div>
                    </form>
                    { !checkValues().check &&
                    <div className="error_value_form">
                        {checkValues().message}
                    </div>
                    }
                </div>
            </div>
        </div>
        {props.user && props.user.partner_bonus && props.user.partner_bonus[0] && props.user.partner_bonus[0].bonus > 0 ?
        <div className="container-fluid">
            <div className={"row"}>
                <div className="col-md-12">
                    <h4 className="info_page_title">{props.getTranslate('front_account','account_previously_payments')}</h4>
                </div>
            </div>
            <div className="card">
                <table className="table table-striped text-center">
                    <thead className={"account_table_head"}>
                    <th>{props.getTranslate('front_account','account_table_bonus_title_column')}</th>
                    <th>{props.getTranslate('front_account','account_table_status_title_column')}</th>
                    <th>{props.getTranslate('front_account','account_table_date_title_column')}</th>
                    </thead>
                    <tbody>
                        {props.user && props.user.partner_bonus && props.user.partner_bonus.map(e => {
                            return <>
                                <tr>
                                    <td>{e.bonus}</td>
                                    <td>{e.status && e.status == 0 ?
                                        <span className={"text-info"}>{props.getTranslate('front_account','account_table_status_padding')}</span>
                                        : e.status == 1 ?
                                        <span className={"text-success"}>{props.getTranslate('front_account','account_table_status_success')}</span>
                                        : ""}
                                    </td>
                                    <td>{formateDateStore(e.date)}</td>
                                </tr>
                            </>
                        })}
                    </tbody>
                </table>
            </div>
        </div> : ""}
    </>
}
export default compose(
    withPagination
)(AccountBonus);
