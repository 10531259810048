import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import {formatSum} from "../../store/reducers/MainReducer";
import {compose} from "redux";
import {withPagination} from "../HOC/withPagination/withPagination";
import {formateDateStore} from "../../App";
import {useDispatch} from "react-redux";
import {getCustomerOrdersDataThunk} from "../../store/reducers/AuthReducer";
import LangLink from "../Common/LangLink";

const AccountPartnerOrders = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCustomerOrdersDataThunk())
    }, []);
    return <>
        <div className="container-fluid">
            <div className="card">
                <table className="table table-striped text-center">
                    <thead className={"account_table_head"}>
                    <th>ID</th>
                    <th>{props.getTranslate('front_account','account_table_sum_title_column')}</th>
                    <th>{props.getTranslate('front_account','account_table_status_title_column')}</th>
                    <th>{props.getTranslate('front_account','account_table_date_title_column')}</th>
                    <th></th>
                    </thead>
                    <tbody>
                    {props.user && props.user.orders && props.user.orders
                        .filter(e => Number(e.type) === 3 || Number(e.type) === 4)
                        .filter((e,i) => {

                            if(((i + 1) > props.paginationPages.pageStart || ((i + 1) === 1) && props.paginationPages.page === 1) && (i + 1) <= props.paginationPages.pageFinish) {
                                return true;
                            } else {
                                return false;
                            }
                        })
                        .map(e => {
                        return <>
                            <tr>
                                <td>{e.id}</td>
                                <td>₴ {formatSum(e.total)}</td>
                                <td>
                                    {props.directoryData && props.directoryData.status && props.directoryData.status.filter(c => {
                                        return Number(c.id) === Number(e.status)
                                    })[0].value }
                                </td>
                                <td>{formateDateStore(e.date)}</td>
                                <td>
                                    <i className="fas fa-angle-down"
                                       onClick={() => props.toggleActive(e.id)}>
                                    </i>
                                </td>
                            </tr>
                            {Number(props.orderOpen) === Number(e.id) ?
                                <tr>
                                    <td colSpan={5}>
                                        <table className={'table table-bordered text-center'}>
                                            <thead className={"account_table_head"}>
                                            <tr>
                                                <th>{props.getTranslate('front_account','account_table_name_title_column')}</th>
                                                <th>{props.getTranslate('front_account','account_table_model_title_column')}</th>
                                                <th>{props.getTranslate('front_account','account_table_manufacturer_title_column')}</th>
                                                <th>{props.getTranslate('front_account','account_table_price_title_column')}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {e.products && e.products.map(p => {
                                                return <tr>
                                                    <td><LangLink to={"/products/"+p.alias}>{p.name}</LangLink></td>
                                                    <td>{p.model}</td>
                                                    <td>
                                                        {props.manufacturers && props.manufacturers.filter(c => {
                                                            return Number(c.id) === Number(p.manufacturer)
                                                        })[0].name }
                                                    </td>
                                                    <td>₴ {formatSum(p.price)}</td>
                                                </tr>
                                            })}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                :
                                ''
                            }
                        </>
                    })}
                    </tbody>
                </table>
            </div>
            {props.paginationHTML}
        </div>
    </>
}
export default compose(
    withPagination
)(AccountPartnerOrders);
