import React from 'react';
import ProductsGalleryContainer from "../Products/Gallery/ProductsGalleryContainer";
import SliderOne from './../../image/tubuva_ukr.jpg'
import SliderOneEN from './../../image/tubuva_en.jpg'
import SliderOneRU from './../../image/tubuva_ru.jpg'
import SliderSeven from './../../image/slider_7.png'
import SliderSevenEN from './../../image/slider_7_en.png'
import SliderSevenRU from './../../image/slider_7_ru.png'
import SliderThreePart2 from './../../image/testline_ukr.jpg'
import SliderThreePart2EN from './../../image/testline_en.jpg'
import SliderThreePart2RU from './../../image/testline_ru.jpg'
import SliderNWOne from './../../image/slider_nw.png'
import SliderNWEN from './../../image/slider_nw_en.png'
import SliderNWRU from './../../image/slider_nw_ru.png'
import SliderQL from './../../image/ql.png'
import SliderQLEN from './../../image/ql_en.png'
import SliderQLRU from './../../image/ql_ru.png'
import './Home.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MetaTags from "react-meta-tags";
import LangLink from "../Common/LangLink";
import {SeoTagsStatic} from "../SeoTagsStatic";

const sliderOneLink = localStorage.getItem('medbuy_store_main_config_lang') &&
localStorage.getItem('medbuy_store_main_config_lang') == 1 ? "https://docacademy.com.ua/050523/" : "/search/%D0%B3%D0%B5%D0%BF%D0%B0%D1%82%D0%B8%D1%82";

const SliderOneImg = localStorage.getItem('medbuy_store_main_config_lang') &&
localStorage.getItem('medbuy_store_main_config_lang') == 1 ? SliderOne
    : localStorage.getItem('medbuy_store_main_config_lang') == 2 ? SliderOneEN
        : localStorage.getItem('medbuy_store_main_config_lang') == 3 ? SliderOneRU : " ";

const SliderSevenImg = localStorage.getItem('medbuy_store_main_config_lang') &&
localStorage.getItem('medbuy_store_main_config_lang') == 1 ? SliderSeven
    : localStorage.getItem('medbuy_store_main_config_lang') == 2 ? SliderSevenEN
        : localStorage.getItem('medbuy_store_main_config_lang') == 3 ? SliderSevenRU : " ";

const SliderNWImg = localStorage.getItem('medbuy_store_main_config_lang') &&
localStorage.getItem('medbuy_store_main_config_lang') == 1 ? SliderNWOne
    : localStorage.getItem('medbuy_store_main_config_lang') == 2 ? SliderNWEN
        : localStorage.getItem('medbuy_store_main_config_lang') == 3 ? SliderNWRU : " ";

const SliderThreeImg = localStorage.getItem('medbuy_store_main_config_lang') &&
localStorage.getItem('medbuy_store_main_config_lang') == 1 ? SliderThreePart2
    : localStorage.getItem('medbuy_store_main_config_lang') == 2 ? SliderThreePart2EN
        : localStorage.getItem('medbuy_store_main_config_lang') == 3 ? SliderThreePart2RU : " ";

const SliderQLImg = localStorage.getItem('medbuy_store_main_config_lang') &&
localStorage.getItem('medbuy_store_main_config_lang') == 1 ? SliderQL
    : localStorage.getItem('medbuy_store_main_config_lang') == 2 ? SliderQLEN
        : localStorage.getItem('medbuy_store_main_config_lang') == 3 ? SliderQLRU : " ";


const Home = (props) => {
    const slider_settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        fade: false,
        autoplaySpeed: 4500
    }
    return (
        <>
            <SeoTagsStatic page={'home'}/>
            <MetaTags>
            {/*<title>{"Магазин медичних експрес-тестів | MedBuy"}</title>*/}
            <meta name="description"
                  content={"Компанія MedBuy є дистриб’ютором діагностичних експрес-тестів, призначених для професійного використання кваліфікованим медичним персоналом. Купити швидкий (експрес) тест онлайн для діагностики коронавірусу COVID-19,експрес - тести на антиген, на коронавірус, за партнерською програмою, отримати безкоштовно, є в наявності, експрес тест для домашнього використання"}/>
            <meta name="keywords"
                  content={"Rapid Test, замовлення, доставка, тестування на коронавірус, експрес тест на корононавірус covid-19, експрес тест на антиген, вірусу, грипп, ковід,  штам дельта, delta, алергія, непереносимість глютену, тест смужки для аналізу, крові, сечі, скринінгове дослідження, діагностика, інфекційних захворювань, мононуклеоз, алкоголь, наркотичну залежність, сифиліс, гонорею, гепатит, прокальцитонін, d-димер, тиреотропний гормон, с-реактивний білок"}/>
        </MetaTags>
            <div className={'home_main_container'}>
                <div className="home_main_slider_section">
                    <Slider {...slider_settings}>
                            {/*<a href="">*/}
                            {/*    <img src={SliderNWImg} alt=""/>*/}
                            {/*</a>*/}
                            <LangLink isA={true}  href={"/search/Medical%20Distribution%20Agency%20s.r.o"}>
                                <img src={SliderOneImg} alt=""/>
                            </LangLink>
                            <a target={"_blank"} href="https://ql.org.ua/uk/">
                                <img src={SliderQLImg} alt=""/>
                            </a>
                            <LangLink isA={true}  href="/category/Enzyme_immunoassay_(EIA)">
                                <img src={SliderThreeImg} alt=""/>
                            </LangLink>
                    </Slider>
                </div>
                <div className="home_recommends_products">
                    <ProductsGalleryContainer
                        title={props.getTranslate('front_product_category', 'product_category_title_recomend_product')}
                        count={8} type={'related'} view={'grid'}/>
                </div>
            </div>
        </>
    )
}

export default Home;


{/*<ImageSlider height='504px'*/
}
{/*             width='100%'*/
}
{/*             autoPlay={true}*/
}
{/*             showArrows={false}*/
}
{/*             showDots={true}*/
}
{/*             duration={10}*/
}
{/*             data={[*/
}
{/*    {*/
}
{/*        src: SliderOne,*/
}
{/*        // title: 'Image 1'*/
}
{/*    },*/
}
{/*    {*/
}
{/*        src: SliderTwo,*/
}
{/*        title: 'Image 2'*/
}
{/*    },*/
}
{/*    {*/
}
{/*        src: SliderOne,*/
}
{/*        title: 'Image 3'*/
}
{/*    },*/
}
{/*]} />*/
}