import React from 'react';
import './Loader.css';
import LoaderImage from '../../../image/loader.gif'

const Loader = (props) => {
    return <div className={'loader_container_main'}>
        <div className="loader_image">
            <img src={LoaderImage} />
        </div>
        <div className="loader_message_container">

        </div>
    </div>
}

export default Loader;









// import React from 'react';

//
// const Loader = (props) => {
//     return(
//         <div className={'lodaerContainerMain'}>
//             <div className="loaderInner">
//                 <img src={LoaderImage} alt=""/>
//             </div>
//             <div>
//                 <h3>Loading all data...</h3>
//             </div>
//         </div>
//     )
// }
//
// export default Loader;