import React from 'react';

class ApiNovaPoshta extends React.Component {

    //TODO: ссылка под замену (АПИ)

    _baseUrl = 'https://api.novaposhta.ua/v2.0/json';
    _apiKey = 'b5ade808e47d62d845b7fa53b1a8a7e9';

    async _getData(url, method, data) {

        let sendData = {
            "apiKey": this._apiKey,
            "modelName": data.model,
            "calledMethod": data.methods,
            "methodProperties": data.properties
        }

        let body = null;
        let options = {
            method: method,
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
        }
        if (method === 'POST') {
            options.body = JSON.stringify(sendData);
        }
        const res = await fetch(`${this._baseUrl}${url}`, options);

        body = await res.json();

        if (!res.success) {

        }

        return body;

    }

    getRegions = async () => {
        const res = await this._getData(
            `/Address`,
            'POST',
            {
                model: 'Address',
                methods: 'getAreas',
                properties: {}
            }
        );
        if (res && res.success) {
            return res.data
        } else {
            return false;
        }
    }

    getCities = async () => {
        const res = await this._getData(
            `/AddressGeneral`,
            'POST',
            {
                model: 'AddressGeneral',
                methods: 'getAreas',
                properties: {}
            }
        );
        if (res && res.success) {
            return res.data
        } else {
            return false;
        }
    }
}

export default ApiNovaPoshta;