import React from 'react';
import {connect} from "react-redux";
import Footer from "./Footer";
import {compose} from "redux";
import {withLang} from "../../HOC/withLang/withLang";
import onClickOutside from "react-onclickoutside";
import {sendCustomerRegistrationThunk} from "../../../store/reducers/AuthReducer";
import {setCookieThunk} from "../../../store/reducers/MainReducer";


class FooterContainer extends React.Component {
    componentDidMount() {
    }

    render() {
        return <Footer
            getTranslate={this.props.getTranslate}
            sendForm={this.props.sendForm}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        sendForm: (value, id_customer) => dispatch(setCookieThunk(value, id_customer)),
    }
}

let mapStateToProps = (state) => {
    return {
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang,
    onClickOutside
)(FooterContainer);
// export default connect(mapStateToProps, mapDispatchToProps)(FooterContainer);