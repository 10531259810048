import React from 'react';
import Login from "./Login";
import {connect} from "react-redux";
import ApiService from '../../api/api.js';
import {changeAuthFormValueAC, getAuthDataThunk} from "../../store/reducers/AuthReducer";
import {compose} from "redux";
import {withLang} from "../HOC/withLang/withLang";
import onClickOutside from "react-onclickoutside";


class LoginContainer extends React.Component {

    constructor(props){
        super(props);
        this.auth = this.auth.bind(this);
    }

    apiService = new ApiService();

    auth(login, pass) {
        this.props.getAuth(login, pass);
    }

    render() {
        return <Login
            form={this.props.form}
            changeValue={this.props.changeValue}
            getAuth={this.auth}
            getTranslate={this.props.getTranslate}
        />
    }
}

let mapStateToProps = (state) => {
    return {
        form: state.auth.authForm
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        changeValue: (input, value) => dispatch(changeAuthFormValueAC(input, value)),
        getAuth: (login, pass) => dispatch(getAuthDataThunk(login, pass))
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang,
    onClickOutside
)(LoginContainer);