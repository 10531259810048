import React from 'react';
import '../../../plugins/flag-icon-css/css/flag-icon.min.css'
import './Footer.css';
import ImageLogo from '../../../image/logo.png';
// import ImageLogo from '../../../image/logo.svg';
import ImageFacebook from '../../../image/facebook.png';
import ImageInstagram from '../../../image/instagram.png';
import ImageTelegram from '../../../image/telegram.png';
import ImageWatsap from '../../../image/watsap.png';
import ImageViber from '../../../image/viber.png';
import {Link} from "react-router-dom";
import FooterCookie from "./FooterCookie";
import LangLink from "../LangLink";

const Footer = (props) => {
    return (
        <>
            <FooterCookie getTranslate={props.getTranslate} sendForm={props.sendForm}/>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="footer_logo"><LangLink to={'/'}><img src={ImageLogo}/></LangLink></div>
                            <div className="footer_image_block">
                                <a target={"_blank"} href={'https://www.facebook.com/medbuy.online/'}><img src={ImageFacebook}/></a>
                                <a target={"_blank"} href={'https://www.instagram.com/medbuy.online/'}><img src={ImageInstagram}/></a>
                                <a target={"_blank"} href={'viber://chat?number=%2B380983535560'}><img src={ImageViber}/></a>
                            </div>
                            <div className="footer_corporate">medbuy.online © 2021 - 2024</div>
                        </div>
                        <div className="col-md-4 footer_menu">
                            <LangLink
                                to={"/privacy_policy"}>{props.getTranslate('front_footer_menu', 'menu_public_offer_title')}</LangLink>
                            <LangLink
                                to={"/partner_program"}>{props.getTranslate('front_footer_menu', 'menu_partner_program_footer_title')}</LangLink>
                            <LangLink
                                to={"/payment_information"}>{props.getTranslate('front_footer_menu', 'menu_order_payment_delivery_title')}</LangLink>
                        </div>
                        <div className="col-md-4 footer_contacts">
                            <span><a href="tel:+380983535560">+380 98 353 5560</a></span>
                            <span>{props.getTranslate('front_footer_menu', 'footer_address')}</span>
                            <span><a href="mailto:info@medbuy.online">info@medbuy.online</a></span>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className="col-md-8 footer_seo_title">
                            <h1>{props.getTranslate('front_footer_seo_title', 'buy_test_in_ukraine')}</h1>
                        </div>
                        {/*<div className="col-md-4 seo_market_style">*/}
                        {/*    <a href="https://seomarket.ua" target={"_blank"}>{props.getTranslate('front_footer_seomarket_text', 'text_with_seomarket')}<span>Seomarket</span></a>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;