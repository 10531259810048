import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import Select from "react-select";
import {useDispatch} from "react-redux";
import {getCustomerOrdersDataThunk} from "../../store/reducers/AuthReducer";
import LangLink from "../Common/LangLink";

const AccountPartnerProductSale = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCustomerOrdersDataThunk())
    }, []);
    return <>
        <div className="container-fluid">
            <div className="partner_add_product_sale_container">
                <Select onChange={(selectedOption) => props.changePartnerProduct(selectedOption.value)}
                        value={props.partnerSaleProductChange ? props.partnerSaleProductChange.label : ''}
                        placeholder={props.getTranslate('front_account','account_select_placeholder')}
                        className={'form-select-container'}
                        required={'required'}
                        options={props.user && props.user.partner_products && props.user.partner_products
                            .filter(p => {
                                let check = true;
                                props.partnerSaleProducts && props.partnerSaleProducts.forEach(ccc => {
                                    if (ccc.alias === p.alias) {
                                        check = false;
                                    }
                                });
                                return check;
                            })
                            .map(p => {
                                return {
                                    value: p.alias,
                                    label: p.name
                                }
                            })}/>
                <button className={'btn btn-success'} onClick={() => {
                    let product = props.user.partner_products.filter(e => e.alias === props.partnerSaleProductChange)[0];
                    if (product && product.alias) {
                        props.addPartnerProduct({
                            ...product
                        })
                    }
                }}>+
                </button>
            </div>
        </div>

        {props.partnerSaleProducts && props.partnerSaleProducts[0] && props.partnerSaleProducts[0].price && Number(props.partnerSaleProducts[0].price) > 0 ?
            <div className="container-fluid">
                <div className="card">
                    <table className={'partner_sale_table table table-bordered text-center'}>
                        <thead className={"account_table_head"}>
                        <tr>
                            <th>{props.getTranslate('front_account','account_table_product_title_column')}</th>
                            <th>{props.getTranslate('front_account','account_table_price_title_column')}</th>
                            <th>{props.getTranslate('front_account','account_table_model_title_column')}</th>
                            <th>{props.getTranslate('front_account','account_table_count_title_column')}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {props.partnerSaleProducts && props.partnerSaleProducts.map(p => {
                            return <tr>
                                <td><LangLink to={"/products/" + p.alias}>{p.name}</LangLink></td>
                                <td>₴ {p.price_one}</td>
                                <td>{p.model}</td>
                                <td className={"count_partner_sale_td"}>
                                    <input className={"form-control"}
                                        type={'number'}
                                           onChange={(event) => {
                                               props.countPartnerProduct(p.alias, event.target.value && Number(event.target.value) <= Number(props.user.partner_products.filter(c => c.alias === p.alias)[0].count) ? event.target.value : p.count)
                                           }}
                                           value={p.count}
                                    />
                                    <small>{props.getTranslate('front_account','account_can_sale')}: {props.user.partner_products.filter(c => c.alias === p.alias)[0].count}</small>
                                </td>
                                <td>
                                    <button className={'btn btn-danger'} onClick={() => {
                                        props.delPartnerProduct(p.alias)
                                    }}>
                                        -
                                    </button>
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                </div>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <button className={"btn btn-success"} onClick={() => props.getCode(props.user.id, props.partnerSaleProducts)}>
                            {props.getTranslate('front_account','account_button_create_sale_partner')}
                        </button>
                    </div>
                </div>
                {props.qrc ? <img src={props.qrc.image} alt={'QRC'} /> : ''}
                {props.qrc ? <a className={"btn btn-info"} href={props.qrc.link} target={'_blank'} >{props.getTranslate('front_account','account_button_pay')}</a> : ''}
            </div>
            : "" }
    </>
}

export default AccountPartnerProductSale;