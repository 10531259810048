import React, {useState} from 'react';
import InputMask from 'react-input-mask';
import {useDispatch} from "react-redux";
import {changeUserDataValueAC, updateUserDataThunk} from "../../store/reducers/AuthReducer";

const AccountProfile = (props) => {

    const dispatch = useDispatch();
    const [isOpen, toggleOpen] = useState(false);
    const changeValue = (input, value) => dispatch(changeUserDataValueAC(input, value))


    return <>
        <div className="container-fluid">
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">{props.getTranslate('front_account','account_profile_title')}</h4>
                        <span className={"profile_personal_data"} onClick={() => toggleOpen(!isOpen)}>
                        {isOpen ?
                            <>
                                <i className="fas fa-times"></i> {props.getTranslate('front_account', 'account_profile_edit_exit')}
                            </>
                            :
                            <>
                                <i className="fas fa-pen"></i> {props.getTranslate('front_account', 'account_profile_edit data')}
                            </>
                        }
                    </span>
                </div>
                <div className="card-body">
                    <div className="form-group row ">
                        <div className={isOpen ? "col-sm-4 profile_item_container profile_edit_container" : "col-sm-4 profile_item_container"}>
                            <span>{props.getTranslate('front_account','account_profile_surname')}:</span>
                            {isOpen ? <input
                                value={props.user.surname}
                                className="form-control"
                                onChange={(event) => changeValue("surname", event.target.value)}
                            /> : <span>{props.user.surname} </span>}
                        </div>
                        <div className={isOpen ? "col-sm-4 profile_item_container profile_edit_container" : "col-sm-4 profile_item_container"}>
                        <span>{props.getTranslate('front_account','account_profile_name')}:</span>
                            {isOpen ? <input
                                value={props.user.name}
                                className="form-control"
                                onChange={(event) => changeValue("name", event.target.value)}
                            /> : <span>{props.user.name} </span>}
                        </div>
                        <div className={isOpen ? "col-sm-4 profile_item_container profile_edit_container" : "col-sm-4 profile_item_container"}>
                            <span>{props.getTranslate('front_account','account_profile_last_name')}:</span>
                            {isOpen ? <input
                                value={props.user.middle_name}
                                className="form-control"
                                onChange={(event) => changeValue("middle_name", event.target.value)}
                            /> : <span>{props.user.middle_name} </span>}
                        </div>
                    </div>
                    <div className="form-group row ">
                        <div className={isOpen ? "col-sm-4 profile_item_container profile_edit_container" : "col-sm-4 profile_item_container"}>
                            <span>Email:</span>
                            {isOpen ? <input
                                type="email"
                                value={props.user.email}
                                className="form-control"
                                onChange={(event) => changeValue("email", event.target.value)}
                            /> : <span>{props.user.email} </span>}
                        </div>
                        <div className={isOpen ? "col-sm-4 profile_item_container profile_edit_container" : "col-sm-4 profile_item_container"}>
                            <span>{props.getTranslate('front_account','account_profile_phone')}:</span>
                            {isOpen ? <InputMask mask="+380 99 999 9999"
                                                 maskChar=" "
                                                 className="form-control"
                                                 placeholder=""
                                                 value={props.user.phone}
                                                 onChange={(event) => changeValue("phone", event.target.value)}
                                                 required={'required'}
                            /> : <span>{props.user.phone} </span>}
                        </div>
                        {props.user.discount && props.user.discount > 0 ?
                        <div className="col-sm-4 profile_item_container">
                            <span>{props.getTranslate('front_account','account_profile_person_discount')}:</span>
                            <span>{Number(props.user.discount*100).toFixed(0)}%</span>
                        </div> : ''}
                    </div>
                    {isOpen ?
                        <div className="form-group row ">
                            <div className="col-sm-4 profile_item_container">
                                <button className="btn btn-success"
                                        type={'button'}
                                        onClick={() => {
                                    dispatch(updateUserDataThunk());
                                    toggleOpen(false);
                                }}>{props.getTranslate('front_account','account_profile_button_save')}</button>
                            </div>
                        </div>
                    : ""}
                </div>
            </div>
        </div>
    </>
}

export default AccountProfile;