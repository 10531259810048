import React from 'react';
import {connect} from "react-redux";
import ProductsCategory from "./ProductsCategory";
import {
    setProductsActiveCategory,
    setCategoryProductsDataThunk,
    changeProductsCategoryFilterThunk,
    setManufacturersDataThunk,
    getFilteredProductsThunk,
    changeInputsRangePriceAC, cleanProductsFiltersDataAC
} from "../../../store/reducers/MainReducer";
import InputRange from "react-input-range";
import 'react-input-range/lib/css/index.css';
import './ProductsCategory.css';
import {compose} from "redux";
import {withLang} from "../../HOC/withLang/withLang";
import onClickOutside from "react-onclickoutside";


class ProductsCategoryContainer extends React.Component {

    constructor(props){
        super(props);
        this.getCategoryData = this.getCategoryData.bind(this);
        this.page = 1;
    }


    getCategoryData() {
        let category = null;
        this.props.categoryTree.forEach(e => {
            if(e.alias === this.props.activeCategory) {
                category = e;
            } else {
                e.childs && e.childs.forEach(c => {
                    if(c.alias === this.props.activeCategory) {
                        category = c;
                        category.parent_data = e;
                    }
                })
            }
        });

        return category;
    }

    componentDidMount() {
        this.page = (new URLSearchParams(window.location.search)).get('page');
        this.props.getProducts(this.props.uri.match.params.category, this.page ? this.page : 1);
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        let new_page = (new URLSearchParams(window.location.search)).get('page');
        if(this.page !== new_page) {
            this.page = (new URLSearchParams(window.location.search)).get('page');
            let filters = {...this.props.filters};
            filters.category = nextProps.uri.match.params.category;
            this.props.filterProducts(filters, this.page);
        }
        if(nextProps.uri.match.params.category !== this.props.activeCategory) {
            this.props.cleanFilters();
            this.props.getProducts(nextProps.uri.match.params.category, this.page);
        }
    }

    render() {
        return <ProductsCategory products={this.props.products}
                                 getProducts={this.props.getProducts}
                                 activeCategory={this.props.uri.match.params.category}
                                 countAll={this.props.countAll}
                                 category={this.getCategoryData()}
                                 page={this.page}
                                 getTranslate={this.props.getTranslate}
        />
    }
}

let mapStateToProps = (state) => {
    return {
        products: state.main.categoryProducts,
        activeCategory: state.main.productsActiveCategory,
        categoryTree: state.main.categoryTree,
        countAll: state.main.paginationCount,
        filters: state.main.productsCategoryFilters,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        getProducts: (category, page) => dispatch(setCategoryProductsDataThunk(category, page)),
        filterProducts: (filters, page = 1) => dispatch(getFilteredProductsThunk(filters,page)),
        cleanFilters: () => dispatch(cleanProductsFiltersDataAC()),
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang,
    onClickOutside
)(ProductsCategoryContainer);