import {Link} from "react-router-dom";
import './PaymentInfo.css';
import SliderOne from "../../../image/slider_1.jpg";
import SliderOneEN from "../../../image/slider_1_en.jpg";
import SliderOneRU from "../../../image/slider_1_ru.jpg";
import MetaTags from "react-meta-tags";
import React from "react";
import {SeoTagsStatic} from "../../SeoTagsStatic";

const TextLanguageUkr = <>
    <SeoTagsStatic page={'payment_information'}/>
    <MetaTags>
        {/*<title>{"Замовлення, оплата та доставка експрес-тестів | MedBuy"}</title>*/}
        <meta name="description" content={"Замовити швидкі (експрес) тести, оплата безготівкова, доставляємо експрес тести по всій Україні"} />
        {/*<meta name="keywords" content={""} />*/}
    </MetaTags>
    <div className={"products_gallery_main_title"}>
        <h3>Оплата та доставка</h3>
    </div>

    <h4 className={"info_page_title"}>Замовлення</h4>
    Оформити замовлення простіше за все через кошик в особистому кабінеті магазину medbuy.online. Також можна
    зробити замовлення телефоном +380 98 353 5560 з понеділка по п’ятницю з 9:00 до 18:00 або в будь-який час
    електронною поштою <a className={"info_page_link"}
    href={"mailto:info@medbuy.online"}>info@medbuy.online</a>.

    <h4 className={"info_page_title"}>Оплата</h4>
    В магазині <a className={"info_page_link"} href="/"
                                     target="_blank">medbuy.online</a> можна оплатити замовлення такими
    способами:
    Банківською картою Mastercard або VISA на сайті магазину через платіжну систему WayForPay
    Оплатою рахунку безготівковим переказом (рахунок буде автоматично згенеровано на основі реєстраційних даних
    клієнта)

    <h4 className={"info_page_title"}>Доставка</h4>
    Доставка товарів по Україні здійснюється кур’єрською службою (міста Київ та Одеса) або компанією «Нова
    Пошта».

    Відправлення замовлень здійснюється протягом 1-3 робочих днів від дати підтвердження замовлення магазином за
    умови наявності товару на складі та отримання оплати. Доставка у Києві та Одесі зазвичай відбувається
    наступного робочого дня після підтвердження замовлення: з понеділка до п’ятниці з 12:00 до 18:00. По Україні
    товар доставляється до обраного клієнтом відділення «Нової Пошти».

    <h4 className={"info_page_title"}>Отримання</h4>
    При отриманні замовлення через кур’єра або службу «Нова Пошта» необхідно переконатися у відсутності видимих
    механічних ушкоджень упаковки товару. Клієнт має право відмовитися від отриманого, але не використаного
    товару протягом 14 календарних днів з моменту його отримання. Товар супроводжує видаткова накладна.
</>

const TextLanguageEng = <>
    <SeoTagsStatic page={'payment_information'}/>
    <MetaTags>
        {/*<title>{"Замовлення, оплата та доставка експрес-тестів | MedBuy"}</title>*/}
        <meta name="description" content={"Замовити швидкі (експрес) тести, оплата безготівкова, доставляємо експрес тести по всій Україні"} />
        {/*<meta name="keywords" content={""} />*/}
    </MetaTags>
    <div className={"products_gallery_main_title"}>
        <h3>Payment and Delivery</h3>
    </div>
    <h4 className={"info_page_title"}>Ordering</h4>
    The easiest way to order our products is to use your cart in our online store medbuy.online. You can also place an
    order by phone at +380 98 353 5560 Monday through Friday from 9:00 to 18:00, or by e-mail at <a className={"info_page_link"}
    href={"mailto:info@medbuy.online"}>info@medbuy.online</a>.

    <h4 className={"info_page_title"}>Payment</h4>
    <a className={"info_page_link"} href="/" target="_blank">medbuy.online</a> offers you the
    following payment options: VISA/MasterCard through WayForPay online, or via a regular bank transfer (we will
    automatically send you an invoice for your order).

    <h4 className={"info_page_title"}>Delivery</h4>
    We deliver using a courier in Kyiv and Odesa, and through Nova Poshta elsewhere all over Ukraine. Normally, we ship
    your order within 1-3 working days since order confirmation, provided the ordered products are in stock and we have
    received your payment. We deliver to Kyiv and Odessa usually the day after an order is confirmed, Monday to Friday
    from 12:00 to 18:00. We ship to other destinations in Ukraine through a respective Nova Poshta branch selected by
    the customer.

    <h4 className={"info_page_title"}>Receiving</h4>
    When receiving your order from a courier or Nova Poshta, please do a visual inspection of its packaging – it should
    bear no mechanical damage. You can return us a received but unused product during 14 days since its receipt. A sales
    invoice is enclosed with the shipment.
</>

const TextLanguageRus = <>
    <SeoTagsStatic page={'payment_information'}/>
    <MetaTags>
        {/*<title>{"Замовлення, оплата та доставка експрес-тестів | MedBuy"}</title>*/}
        <meta name="description" content={"Замовити швидкі (експрес) тести, оплата безготівкова, доставляємо експрес тести по всій Україні"} />
        {/*<meta name="keywords" content={""} />*/}
    </MetaTags>
    <div className={"products_gallery_main_title"}>
        <h3>Оплата и доставка</h3>
    </div>
    <h4 className={"info_page_title"}>Заказ</h4>
    Оформить заказ проще всего через корзину в личном кабинете магазина medbuy.online. Также можно сделать заказ по
    телефону +380 98 353 5560 с понедельника по пятницу с 9:00 до 18:00 или в любое время по электронной почте <a
    className={"info_page_link"} href={"mailto:info@medbuy.online"}>info@medbuy.online</a>.

    <h4 className={"info_page_title"}>Оплата</h4>
    В магазине <a className={"info_page_link"} href="/" target="_blank">medbuy.online</a> можно оплатить
    заказ следующими способами: банковской картой Mastercard или VISA на сайте магазина через платежную систему
    WayForPay либо банковским переводом на счёт компании MedBuy (мы автоматически пришлём Вам счёт на оплату на основе
    Ваших регистрационных данных).

    <h4 className={"info_page_title"}>Доставка</h4>
    Доставка товаров по Украине осуществляется курьерской службой (города Киев и Одесса) или компанией «Новая Почта».
    Отправка заказов осуществляется в течение 1-3 рабочих дней от даты подтверждения заказа магазином при условии
    наличия товара на складе и получения оплаты. Доставка в Киеве и Одессе обычно происходит на следующий рабочий день
    после подтверждения заказа с понедельника по пятницу с 12:00 до 18:00. По Украине товар доставляется до выбранного
    клиентом отделения «Новой Почты».

    <h4 className={"info_page_title"}>Получение</h4>
    При получении заказа через курьера или службу «Новая Почта» необходимо убедиться в отсутствии видимых механических
    повреждений упаковки товара. Клиент вправе отказаться от полученного, но не использованного товара в течение 14
    календарных дней с момента его получения. Товар сопровождает расходная накладная.
</>

const TextLanguage = localStorage.getItem('medbuy_store_main_config_lang') &&
localStorage.getItem('medbuy_store_main_config_lang') == 1 ? TextLanguageUkr
    : localStorage.getItem('medbuy_store_main_config_lang') == 2 ? TextLanguageEng
        : localStorage.getItem('medbuy_store_main_config_lang') == 3 ? TextLanguageRus : " ";


const PaymentInfo = (props) => {
    return (
        TextLanguage
    )
}

export default PaymentInfo;