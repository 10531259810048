import ImageSuccess from './../../../image/success.png'
import {Link} from "react-router-dom";
import LangLink from "../../Common/LangLink";

const Success = (props) => {
    if(props.orderType === "order") {
        props.cleanCart(1)
    }
    if(props.orderType === "preorder") {
        props.cleanCart(2)
    }

    return (
        <>
            <div className={'row'}>
                <div className={'success_img col-md-12'}>
                    <img src={ImageSuccess} />
                </div>
            </div>
            <div className="row">
                <div className="success_title col-md-12">
                    {props.orderType === "order" ?
                        <h3>{props.getTranslate('front_success','success_order_confirm')}. {props.orderId ? '#' + props.orderId : ''}</h3>
                        : props.orderType === "partner" ?
                        <h3>{props.getTranslate('front_success','success_testing_confirm')} {props.orderId ? '#' + props.orderId : ''}</h3>
                        : ""}
                </div>
            </div>
            <div className="row">
                <div className="success_order_info col-md-12">
                    {/*<p>Номер замовлення: {props.order && props.order.id}</p>*/}
                </div>
            </div>
            <div className="row">
                <div className="success_button col-md-12">
                    <LangLink className={"blue_button"} to={"/account"}>{props.getTranslate('front_success','success_button_person_cabinet')}</LangLink>
                    {/*{props.order && props.order.receipt ? <a className={"orange_button"} href={props.order.receipt}>Рахунок фактура</a> : ''}*/}
                    <LangLink className={"orange_button"} to={"/home"}>{props.getTranslate('front_success','success_button_go_home')}</LangLink>
                </div>
            </div>
        </>
    )}

export default Success;