import React from 'react';
import {connect} from "react-redux";
import Search from "./Search";
import {changeSearchInputDataAC, setSearchedProductsDataThunk} from "../../store/reducers/MainReducer";
import {compose} from "redux";
import {withLang} from "../HOC/withLang/withLang";
import onClickOutside from "react-onclickoutside";

class SearchContainer extends React.Component {

    constructor(props){
        super(props);
        this.page = 1;
    }

    componentDidMount() {
        this.page = (new URLSearchParams(window.location.search)).get('page');
        this.props.getProducts(this.props.uri.match.params.key, {}, this.page)
        this.props.changeSearch(this.props.uri.match.params.key)
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        let new_page = (new URLSearchParams(window.location.search)).get('page');
        if(this.page !== new_page) {
            this.page = (new URLSearchParams(window.location.search)).get('page');
            this.props.getProducts(nextProps.uri.match.params.key, nextProps.filters,this.page);
            this.props.changeSearch(nextProps.uri.match.params.key);
        }
        if(nextProps.uri.match.params.key !== this.props.uri.match.params.key) {
            this.props.getProducts(nextProps.uri.match.params.key, nextProps.filters,this.page);
            this.props.changeSearch(nextProps.uri.match.params.key);
        }
    }

    render() {
        return <Search products={this.props.products} countAll={this.props.countAll} keyword={this.props.uri.match.params.key} getTranslate={this.props.getTranslate}/>;
    }
}

let mapStateToProps = (state) => {
    return {
        products: state.main.searchedApiProducts,
        countAll: state.main.paginationCount,
        filters: state.main.productsCategoryFilters,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        getProducts: (key, filters, page) => dispatch(setSearchedProductsDataThunk(key, filters, page)),
        changeSearch: (value) => dispatch(changeSearchInputDataAC(value)),
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang,
    onClickOutside
)(SearchContainer);