import React from 'react';
import Header from "./Header.jsx";
import {connect} from "react-redux";
import './Header.css';
import {
    changeSearchInputDataAC,
    setCartStorageAllDataAC,
    setCategoryTreeDataThunk,
    toggleCartOpenAC
} from "../../../store/reducers/MainReducer";
import {compose} from "redux";
import {withLang} from "../../HOC/withLang/withLang";
class HeaderContainer extends React.Component {

    constructor(props) {
        super(props);
        this.getCartAmount = this.getCartAmount.bind(this)
    }

    componentDidMount() {
        let in_stock = localStorage.getItem('cart_products') ? JSON.parse(localStorage.getItem('cart_products')) : [];
        let pre_order = localStorage.getItem('cart_pre_order') ? JSON.parse(localStorage.getItem('cart_pre_order')) : [];
        this.props.setCartFromStorage(in_stock, pre_order);
        this.props.setCategoryTree();
    }

    getCartAmount () {
        let in_stock =  this.props.cartProducts ? this.props.cartProducts.reduce(function(accumulator, currentValue, index, array) {
            return accumulator + (currentValue.count * currentValue.price);
        }, 0) : 0;
        let pre_order =  this.props.cartProductsPreOrder ? this.props.cartProductsPreOrder.reduce(function(accumulator, currentValue, index, array) {
            return accumulator + (currentValue.count * currentValue.price);
        }, 0) : 0;
        let all_counts = Number(this.props.cartProducts ? this.props.cartProducts.length : 0) +  Number(this.props.cartProductsPreOrder ? this.props.cartProductsPreOrder.length : 0);
        return {
            amount: in_stock + pre_order,
            count: all_counts,
        };
    }

    render() {
        return <Header toggle={this.props.toggle}
                       cartTotals={this.getCartAmount()}
                       user={this.props.user}
                       searchInput={this.props.searchInput}
                       changeSearch={this.props.changeSearch}
                       getTranslate={this.props.getTranslate}
                       languages={this.props.languages}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        toggle: (status) => dispatch(toggleCartOpenAC(status)),
        setCartFromStorage: (in_stock, pre_order) => dispatch(setCartStorageAllDataAC(in_stock, pre_order)),
        setCategoryTree: () => dispatch(setCategoryTreeDataThunk()),
        changeSearch: (value) => dispatch(changeSearchInputDataAC(value)),
    }
}

let mapStateToProps = (state) => {
    return {
        cartProducts: state.main.cart.products,
        cartProductsPreOrder: state.main.cart.productsPreOrder,
        user: state.auth.user,
        searchInput: state.main.searchInput,
        languages: state.main.languages
    }
}

// export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang
)(HeaderContainer);
