import {Link} from "react-router-dom";
import './PartnerProgramme.css';
import Select from "react-select";
import React, {useEffect} from 'react';
import MetaTags from "react-meta-tags";
import LangLink from "../../Common/LangLink";
import {SeoTagsStatic} from "../../SeoTagsStatic";

const TextLanguageUkr = <>
    <SeoTagsStatic page={'partner_program'}/>
    <MetaTags>
        {/*<title>{"Партнерська програма для медпрацівників | MedBuy"}</title>*/}
        <meta name="description" content={"Програма для заохочення лікарів, які довіряють методу експрес-тестування за допомогою ІХА, яка надає змогу безкоштовно отримувати експрес-тести, використовувати їх з діагностичної метою, заробляти бонусні бали та конвертувати їх в грошові кошти. Скринінг"} />
        <meta name="keywords" content={"Нарахування балів, бонусні бали, конвертуються, в гроші, зареєструватися, купити експрес-тест, отримати бали, сертифікат, інструкція, знижка, активувати промокод"} />
    </MetaTags>
    <div className={"products_gallery_main_title"}>
        <h3>Партнерська Програма</h3>
    </div>
    <p>Запрошуємо медпрацівників доєднатися до партнерської програми компанії MedBuy. Програма створена
        насамперед для заохочення лікарів, які довіряють методу експрес-тестування за допомогою
        імунохроматографічного аналізу і зацікавлені у популяризації швидких тестів. Програма діє на умовах,
        визначених <a className={"info_page_link"} target="_blank" href="https://medbuy.online/partner_program.pdf">правилами
            програми</a>.
    </p>

    <h4 className={"info_page_title"}>Як працює програма?</h4>

    <p>При реєстрації в програмі лікар створює свій власний онлайн-кабінет за адресою <a className={"info_page_link"}
                                                                                         target="_blank"
                                                                                         href="/">medbuy.online</a> та
        укладає партнерську угоду з компанією MedBuy на умовах публічної оферти, яка дає змогу на законних
        підставах безкоштовно отримувати експрес-тести, використовувати їх з діагностичної метою, заробляти
        бонусні бали та конвертувати їх в грошові кошти. Через онлайн-кабінет лікар самостійно замовляє
        професійні діагностичні експрес-тести, що будуть згодом доставлені лікареві кур’єром або поштовою службою. </p>

    <p><b>Замовлення та доставка тестів нічого не вартує лікареві.</b></p>

    <p>Лікар на власний розсуд рекомендує експрес-тестування своїм пацієнтам з метою діагностики або скринінгу.
        Пацієнти, які погоджуються на тестування, можуть легко і швидко оплатити потрібний тест власною
        банківською карткою через надійну платіжну систему за допомогою спеціального QR-коду, який надає лікар.
        Після оплати лікар отримує її підтвердження через SMS та електронну пошту і виконує діагностичний тест.
        За виконання тесту на бонусний рахунок лікареві нараховуються бонуси (кількість бонусних балів
        нараховується умовно як відсоток вартості проведеного тесту). Відсоткова ставка для нарахування бонусів
        залежить від статусу лікаря в партнерській програмі:</p>
    <ul>
        <li>Гіппократ: 10%</li>
        <li>Авіценна: 12.5% (статус надається після 100 проведених тестувань)</li>
        <li>Парацельс: 15% (статус надається після 500 проведених тестувань)</li>
    </ul>

    <p>Бонуси також нараховуються як подяка за залучення до програми нових учасників на умовах, визначених
        правилами програми, а також за участь в опитуваннях та інших заходах компанії MedBuy.</p>

    <p>В онлайн-кабінеті автоматично обліковуються всі замовлення і призначення тестів лікарем в межах
        партнерської програми. Якщо замовлені тести не використовуються лікарем і їхній термін придатності
        збігає, компанія MedBuy має право за погодженням із лікарем замінити їх на аналогічні тести з довшим
        терміном придатності або повернути на склад. </p>

    <p>Стан бонусного рахунку в будь-який час можна перевірити в особистому кабінеті. Якщо на рахунку накопичено
        1000 і більше бонусів, їх можна щомісяця конвертувати в грошові кошти з розрахунку 1 бонус = 1,24 гривні
        і отримувати на рахунок (розрахунковий або картковий), відкритий в українському банку. Компанія MedBuy
        нараховує та сплачує необхідні податки та збори на грошові виплати учасникам партнерської програми.</p>

    <p>Особистий кабінет автоматично зберігає історію накопичення та використання бонусів.</p>

    <h4 className={"info_page_title"}>Які вигоди від участі?</h4>
    <ul>
        <li>Можливість на безоплатних умовах мати запас широкого асортименту експрес-тестів на своєму робочому
            місці
        </li>
        <li>Можливість отримувати й накопичувати бонуси за використання тестів, конвертувати їх в грошові кошти
            і легально отримувати їх на свій банківський рахунок (всі необхідні податки сплачуються компанією
            MedBuy)
        </li>
        <li>Пріоритетна доставка замовлених тестів кур’єрською службою або поштовим оператором</li>
        <li>Пріоритетна технічна підтримка за виділеною лінією (e-mail/телефон/Viber/Whatsapp/Telegram)</li>
        <li>Пріоритетна участь в навчальних та інформаційних заходах компанії MedBuy</li>
        <li>Знижки <a className={"info_page_link"} href="/">в онлайн-магазині компанії </a>MedBuy для учасників програми
        </li>

    </ul>
    <h4 className={"info_page_title"}>Як стати учасником програми?</h4>
    <ul>
        <li>Ознайомитися і погодитися з правилами програми (у разі необхідності, наші співробітники охоче
            нададуть Вам роз’яснення за Вашим запитом)
        </li>
        <li>Заповнити профіль учасника і прийняти публічну оферту, яка є законною підставою партнерських
            відносин
        </li>
        <li>Створити власний онлайн-кабінет</li>
        <li><a className={"info_page_link"} href="/">Замовити потрібні тести</a> через свій онлайн-кабінет</li>
    </ul>

    <p id={'form_partner_reg'}>Запитання щодо партнерської програми можна поставити за телефоном +380 98 353 5560 або
        ел. поштою на
        адресу <a className={"info_page_link"} target="_blank"
                  href="mailto:doctor@medbuy.online">doctor@medbuy.online</a>.</p>
</>

const TextLanguageEng = <>
    <SeoTagsStatic page={'partner_program'}/>
    <MetaTags>
        {/*<title>{"Партнерська програма для медпрацівників | MedBuy"}</title>*/}
        <meta name="description" content={"Програма для заохочення лікарів, які довіряють методу експрес-тестування за допомогою ІХА, яка надає змогу безкоштовно отримувати експрес-тести, використовувати їх з діагностичної метою, заробляти бонусні бали та конвертувати їх в грошові кошти. Скринінг"} />
        <meta name="keywords" content={"Нарахування балів, бонусні бали, конвертуються, в гроші, зареєструватися, купити експрес-тест, отримати бали, сертифікат, інструкція, знижка, активувати промокод"} />
    </MetaTags>
    <div className={"products_gallery_main_title"}>
        <h3>Partner Programme</h3>
    </div>
    <p>We encourage health professionals to join the MedBuy Partner Programme. The programme provides incentives for
        physicians who practice rapid testing using our immunochromatographic assays and are interested in promoting
        rapid tests. The Programme is guided by its <a className={"info_page_link"} target="_blank"
                                                       href="https://medbuy.online/partner_program.pdf">terms and
            conditions</a> (Ukrainian version only).
    </p>

    <h4 className={"info_page_title"}>Quick Guide</h4>

    <p>Upon registering for the Programme, a doctor creates his/her own personal cabinet online at <a
        className={"info_page_link"} target="_blank"
        href="/">medbuy.online</a> and becomes our partner after accepting the MedBuy’s public offer. Our partners are
        eligible to legally receive rapid tests free of charge, use them for diagnostic purposes, earn bonus points and
        easily convert them into cash. A doctor can order rapid tests online through the personal cabinet, and they will
        be soon delivered by a courier or postal service. </p>

    <p><b>Rapid tests are free for our partners. The delivery is also on us.</b></p>

    <p>A doctor recommends rapid testing to his/her patients as may be required for diagnostics or screening. Patients
        who agree to get tested can easily and quickly pay for the test with their banking card via a trusted and secure
        payment system using a QR code provided by a doctor. A doctor receives a payment confirmation via SMS and e-mail
        and performs the rapid test. As a reward for performing the test, a doctor receives bonus points credited to
        his/her personal bonus account. The amount of bonus points earned equals a certain percentage of the cost of the
        performed test. This percentage rate for bonus accruals depends on the doctor’s status in the Partner Programme,
        as follows:</p>
    <ul>
        <li>Hippocrates: 10%</li>
        <li>Avicenna: 12.5% (status is awarded after 100 tests performed)</li>
        <li>Paracelsus: 15% (status is awarded after 500 tests performed)</li>
    </ul>

    <p>Bonus points are also earned for engaging new partners to join the Programme, and for participation in opinion
        surveys and other activities by MedBuy.</p>

    <p>The partner’s online cabinet automatically registers all rapid test orders and performances under the Partner
        Programme. If the ordered tests remain unused and their expiry date is approaching, MedBuy can replace or recall
        them. </p>

    <p>The bonus account can be verified any time in the personal cabinet. If the bonus account has 1,000 or more
        points, they can be converted into cash (conversion rate 1 bonus point = ₴1.24) and transferred into a partner’s
        bank account (checking or card account) in a Ukrainian bank. MedBuy will take care of all related taxation and
        tax payments for our partners.</p>

    <p>The personal cabinet automatically maintains all bonus accrual and utilization records.</p>

    <h4 className={"info_page_title"}>Partner Benefits</h4>
    <ul>
        <li>Availability of a free stock of rapid tests right at the workplace</li>
        <li>Earning bonus points and converting them into cash in full compliance with the current legislation (all
            taxes are paid by MedBuy)
        </li>
        <li>Priority delivery of rapid tests by a courier or postal service</li>
        <li>Priority technical support by dedicated channels (e-mail/phone/messengers)</li>
        <li>Priority participation in educational and informational events by MedBuy</li>
        <li>Discounts <a className={"info_page_link"} href="/">in MedBuy online store </a></li>

    </ul>
    <h4 className={"info_page_title"}>Becoming a Partner</h4>
    <ul>
        <li>Read and accept the terms and conditions of the Partner Programme (if required, our staff will be happy to
            provide you with further clarifications)
        </li>
        <li>Fill in the user profile and accept the public offer, which is the legal basis for partner relationships
        </li>
        <li>Setup a personal cabinet online</li>
        <li><a className={"info_page_link"} href="/">Order the required rapid tests</a> through the personal cabinet
        </li>
    </ul>

    <p id={'form_partner_reg'}>Questions or queries regarding the Partner Programme can be addressed to our consultants
        at +380 98 353 5560 or <a className={"info_page_link"} target="_blank"
                                  href="mailto:doctor@medbuy.online">doctor@medbuy.online</a>.</p>
</>

const TextLanguageRus = <>
    <SeoTagsStatic page={'partner_program'}/>
    <MetaTags>
        {/*<title>{"Партнерська програма для медпрацівників | MedBuy"}</title>*/}
        <meta name="description" content={"Програма для заохочення лікарів, які довіряють методу експрес-тестування за допомогою ІХА, яка надає змогу безкоштовно отримувати експрес-тести, використовувати їх з діагностичної метою, заробляти бонусні бали та конвертувати їх в грошові кошти. Скринінг"} />
        <meta name="keywords" content={"Нарахування балів, бонусні бали, конвертуються, в гроші, зареєструватися, купити експрес-тест, отримати бали, сертифікат, інструкція, знижка, активувати промокод"} />
    </MetaTags>
    <div className={"products_gallery_main_title"}>
        <h3>Партнёрская программа</h3>
    </div>
    <p>Приглашаем медработников воспользоваться партнёрской программой компании MedBuy. Программа создана, прежде всего,
        для поощрения врачей, доверяющих методу экспресс-диагностики с помощью иммунохроматографического анализа и
        заинтересованных в популяризации экспресс-тестов. Программа действует на условиях, определенным <a
            className={"info_page_link"} target="_blank" href="https://medbuy.online/partner_program.pdf">правилами
            программы</a> (только украинская версия).
    </p>

    <h4 className={"info_page_title"}>Как работает программа?</h4>

    <p>При регистрации в программе врач создает свой собственный онлайн-кабинет по адресу <a
        className={"info_page_link"}
        target="_blank"
        href="/">medbuy.online</a> и заключает партнёрское соглашение с компанией MedBuy на условиях публичной оферты,
        которое позволяет на законных основаниях бесплатно получать экспресс-тесты, использовать их с диагностической
        целью, зарабатывать бонусные баллы и конвертировать их в денежные средства. Через онлайн-кабинет врач
        самостоятельно заказывает профессиональные диагностические экспресс-тесты, которые будут впоследствии доставлены
        врачу курьером или почтовой службой.</p>

    <p><b>Заказ и доставка тестов для врача бесплатны.</b></p>

    <p>Врач на своё усмотрение рекомендует экспресс-тестирование своим пациентам для диагностики или скрининга.
        Пациенты, которые соглашаются на тестирование, могут легко и быстро оплатить нужный тест собственной банковской
        картой через надёжную платежную систему с помощью специального QR-кода, который предоставляет врач. После оплаты
        врач получает её подтверждение через SMS и электронную почту и выполняет диагностический тест. При выполнении
        теста на бонусный счёт врачу начисляются бонусы (количество бонусных баллов начисляется условно как процент
        стоимости проведенного теста). Процентная ставка для начисления бонусов зависит от статуса врача в партнёрской
        программе:</p>
    <ul>
        <li>Гиппократ: 10%</li>
        <li>Авиценна: 12.5% (статус предоставляется после 100 проведенных тестирований)</li>
        <li>Парацельс: 15% (статус предоставляется после 500 проведенных тестирований)</li>
    </ul>

    <p>Бонусы также начисляются как благодарность за привлечение к программе новых участников на условиях, определенных
        правилами программы, а также за участие в опросах и других мероприятиях компании MedBuy.</p>

    <p>В онлайн-кабинете автоматически учитываются все заказы и назначения тестов врачом в рамках партнёрской программы.
        Если заказанные тесты не используются врачом и их срок годности истекает, компания MedBuy вправе по согласованию
        с врачом заменить их на аналогичные тесты с более длительным сроком годности или вернуть на склад.</p>

    <p>В онлайн-кабинете автоматически учитываются все заказы и назначения тестов врачом в рамках партнёрской программы.
        Если заказанные тесты не используются врачом и их срок годности истекает, компания MedBuy вправе по согласованию
        с врачом заменить их на аналогичные тесты с более длительным сроком годности или вернуть на склад.</p>

    <p>Личный кабинет автоматически сохраняет историю накопления и использования бонусов.</p>

    <h4 className={"info_page_title"}>Какие выгоды от партнёрства?</h4>
    <ul>
        <li>Возможность на бесплатных условиях иметь в своём распоряжении широкий ассортимент экспресс-тестов
        </li>
        <li>Возможность получать и накапливать бонусы за использование тестов, конвертировать их в денежные средства и легально получать их на свой банковский счёт (все необходимые налоги уплачиваются компанией MedBuy)
        </li>
        <li>Приоритетная доставка заказанных тестов курьерской службой или почтовым оператором</li>
        <li>Приоритетная техническая поддержка по выделенной линии (e-mail / телефон / Viber / WhatsApp / Telegram)</li>
        <li>Приоритетное участие в учебных и информационных мероприятиях компании MedBuy</li>
        <li>Скидки <a className={"info_page_link"} href="/">в онлайн-магазине компании MedBuy </a>для участников программы
        </li>

    </ul>
    <h4 className={"info_page_title"}>Как стать партнёром?</h4>
    <ul>
        <li>Ознакомиться и согласиться с правилами программы (в случае необходимости, наши сотрудники охотно предоставят Вам разъяснения по Вашему запросу)
        </li>
        <li>Заполнить профиль участника и принять публичную оферту, которая является законным основанием партнёрских отношений
        </li>
        <li>Создать собственный онлайн-кабинет</li>
        <li><a className={"info_page_link"} href="/">Заказать нужные тесты </a> через свой онлайн-кабинет</li>
    </ul>

    <p id={'form_partner_reg'}>Вопросы по партнёрской программы можно задать по телефону +380 98 353 5560 или эл. почте на адрес <a className={"info_page_link"} target="_blank"
                  href="mailto:doctor@medbuy.online">doctor@medbuy.online</a>.</p>
</>

const TextLanguage = localStorage.getItem('medbuy_store_main_config_lang') &&
        localStorage.getItem('medbuy_store_main_config_lang') == 1 ? TextLanguageUkr
        : localStorage.getItem('medbuy_store_main_config_lang') == 2 ? TextLanguageEng
        : localStorage.getItem('medbuy_store_main_config_lang') == 3 ? TextLanguageRus : " ";

const PartnerProgramme = (props) => {

    useEffect(() => {
        if (props.scroll === 'reg') {
            setTimeout(function () {
                const element = document.getElementById('form_partner_reg');
                element.scrollIntoView();
            }, 500);
        }
    }, []);

    return (
        <>
            {TextLanguage}

            {props.user && props.user.id && props.user.id > 0 ?

                props.user.id && Number(props.user.isPartner) < 1 ?
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();
                            let form = {...props.form};
                            form.id_customer = props.user.id;
                            props.createPartner(form);
                        }}
                    >
                        <h2 className={"partner_programm_title"}>
                            {props.getTranslate('front_info_page_partner_program','form_register_title')}
                        </h2>
                        <small className="form-text text-muted">
                            {props.getTranslate('front_info_page_partner_program','register_info_text')}
                        </small>

                        <h5 className={"partner_programm_sub_title"}>
                            {props.getTranslate('front_info_page_partner_program','personal_info_for_pay_bonus')}
                        </h5>
                        <div className="form-group">
                            <label>
                                {props.getTranslate('front_info_page_partner_program','form_label_speciality')}
                            </label>
                            <Select value={props.form && props.form.specialty ? props.form.specialty.label : ''}
                                    onChange={(selectedOption) => props.changeValue('specialty', selectedOption.value)}
                                    placeholder={props.getTranslate('front_info_page_partner_program','form_placeholder_speciality')}
                                    className={'form-select-container'}
                                    required={'required'}
                                    options={props.specialtyData && props.specialtyData.map(e => {
                                        return {
                                            value: e.id,
                                            label: e.name
                                        }
                                    })
                                    }/>
                        </div>
                        <div className="form-group">
                            <label>
                                {props.getTranslate('front_info_page_partner_program','form_label_address')}
                            </label>
                            <input
                                className="form-control"
                                value={props.form && props.form.address_register}
                                onChange={(event) => props.changeValue('address_register', event.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>{props.getTranslate('front_info_page_partner_program','form_label_passport')}</label>
                            <input
                                className="form-control"
                                value={props.form && props.form.pasport_series}
                                onChange={(event) => props.changeValue('pasport_series', event.target.value)}
                                required/>
                        </div>
                        <div className="form-group">
                            <label>
                                {props.getTranslate('front_info_page_partner_program','form_label_company_add_passport')}
                            </label>
                            <input
                                className="form-control"
                                value={props.form && props.form.pasport_organization}
                                onChange={(event) => props.changeValue('pasport_organization', event.target.value)}
                                required/>
                        </div>
                        <div className="form-group">
                            <label>
                                {props.getTranslate('front_info_page_partner_program','form_label_data_empty_passport')}
                            </label>
                            <input
                                type="date"
                                className="form-control"
                                value={props.form && props.form.pasport_data}
                                onChange={(event) => props.changeValue('pasport_data', event.target.value)}
                                required/>
                        </div>
                        <div className="form-group">
                            <label>
                                {props.getTranslate('front_info_page_partner_program','form_label_ipn')}
                            </label>
                            <input
                                type="number"
                                value={props.form && props.form.ipn}
                                onChange={(event) => props.changeValue('ipn', event.target.value)}
                                className="form-control" required/>
                        </div>
                        <div className="form-group">
                            <label>
                                {props.getTranslate('front_info_page_partner_program','form_label_bank_name')}
                            </label>
                            <input
                                className="form-control"
                                value={props.form && props.form.bank_name}
                                onChange={(event) => props.changeValue('bank_name', event.target.value)}
                                required/>
                        </div>
                        <div className="form-group">
                            <label>
                                {props.getTranslate('front_info_page_partner_program','form_label_bank_code')}
                            </label>
                            <input
                                className="form-control"
                                value={props.form && props.form.bank_code}
                                onChange={(event) => props.changeValue('bank_code', event.target.value)}
                                required/>
                        </div>
                        <div className="form-group">
                            <label>
                                    {props.getTranslate('front_info_page_partner_program','form_label_bank_iban')}
                            </label>
                            <input
                                className="form-control"
                                min="29"
                                max="29"
                                value={props.form && props.form.bank_iban}
                                onChange={(event) => props.changeValue('bank_iban', event.target.value)}
                                required/>
                            <small className="form-text text-muted all_small_teg">
                                {props.getTranslate('front_info_page_partner_program','form_label_pls_attentively')}
                            </small>
                        </div>
                        <div className="form-group form-check">
                            <input type="checkbox" className="form-check-input" id="exampleCheck1" required/>
                            <label className="form-check-label" htmlFor="exampleCheck1">
                                {props.getTranslate('front_info_page_partner_program','form_label_confirm_register_text')}
                            </label>
                        </div>
                        <button type="submit" className="btn btn-primary">
                            {props.getTranslate('front_info_page_partner_program','form_button_register')}
                        </button>
                    </form>
                    : <h3 className={"partner_alert"}>
                        {props.getTranslate('front_info_page_partner_program','info_text_partner_true')}
                        </h3>

                : <h3 className={"partner_alert"}><LangLink to={"/registration"}>{props.getTranslate('front_info_page_partner_program','link_for_guest')}</LangLink>/<LangLink
                    to={"/login"}>{props.getTranslate('front_info_page_partner_program','link_for_log_in')}</LangLink></h3>
            }
        </>
    )
}

export default PartnerProgramme;