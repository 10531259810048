import React from 'react';
import {connect} from "react-redux";
import Success from "./Success";
import './Success.css';
import {compose} from "redux";
import {withLang} from "../../HOC/withLang/withLang";
import onClickOutside from "react-onclickoutside";
import {cleanCartProductsTypesAC} from "../../../store/reducers/MainReducer";

class SuccessContainer extends React.Component {

    constructor(props){
        super(props);
    }

    componentDidMount() {}

    render() {
        return <Success order={this.props.order}
                        orderType={this.props.uri ? this.props.uri.match.params.types : false}
                        orderId={this.props.uri ? this.props.uri.match.params.id : false}
                        getTranslate={this.props.getTranslate}
                        cleanCart={this.props.cleanCart}
        />
    }
}

let mapStateToProps = (state) => {
    return {
        order: state.main.confirmOrder
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        cleanCart: (type) => dispatch(cleanCartProductsTypesAC(type))
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang,
    onClickOutside
)(SuccessContainer);