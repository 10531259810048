import React from 'react';
import Pagination from "./Pagination";
import {setCategoryProductsDataThunk} from "../../../store/reducers/MainReducer";
import {connect} from "react-redux";

class PaginationContainer extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return(
            <Pagination count={this.props.count} link={this.props.link} activePage={this.props.page}/>
        )
    }
}

let mapStateToProps = (state) => {
    return {
        count: state.main.paginationCount
    }
}

let mapDispatchToProps = (dispatch) => {
    return {

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PaginationContainer);
