import React, {useEffect} from 'react';
import {Route, Switch, Redirect, useParams} from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import './App.css';
import ApiService from './api/api';
import Loader from "./components/Common/Loader/Loader";
import './plugins/toastr/toastr.min.css';
import HeaderContainer from "./components/Common/Header/HeaderContainer";
import LoginContainer from "./components/Login/LoginContainer";
import HomeContainer from "./components/Home/HomeContainer";
import FooterContainer from "./components/Common/Footer/FooterContainer";
import ProductsDetailContainer from "./components/Products/Detail/ProductsDetailContainer";
import ProductsCategoryContainer from "./components/Products/Category/ProductsCategoryContainer";
import MenuContainer from "./components/Menu/MenuContainer";
import AccountContainer from "./components/Account/AccountContainer";
import CartContainer from "./components/Cart/CartContainer";
import CheckoutContainer from "./components/Order/Checkout/CheckoutContainer";
import ConfirmContainer from "./components/Order/Confirm/ConfirmContainer";
import AlertContainer from "./components/Common/Alert/AlertContainer";
import NotFound404 from "./components/Common/404/404Container";
import {checkAuthDataThunk} from "./store/reducers/AuthReducer";
import RegistrationContainer from "./components/Registration/RegistrationContainer";
import SuccessContainer from "./components/Order/Success/SuccessContainer";
import SearchContainer from "./components/Search/SeacrhContainer";
import {getLanguagesDataThunk, setAllTranslatesThunk} from "./store/reducers/MainReducer";
import PartnerProgrammeContainer from "./components/InfoPage/PartnerProgramme/PartnerProgrammeContainer";
import AboutContainer from "./components/InfoPage/About/AboutContainer";
import PaymentInfoContainer from "./components/InfoPage/PaymentInfo/PaymentInfoContainer";
import PrivacyPolicy from "./components/InfoPage/PrivacyPolicy/PrivacyPolicy";
import AccountActivateContainer from "./components/Login/AccountActivate/AccountActivateContainer";
import ResetPasswordContainer from "./components/Login/ResetPassword/ResetPasswordContainer";


class AppContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getAuth();
        this.props.getLanguages();
        this.props.setTranslates();
        if(!localStorage.getItem('medbuy_store_main_config_lang')) {
            localStorage.setItem('medbuy_store_main_config_lang', '1');
        }
    }

    render() {
        return (
            <App isAuth={this.props.isAuth} loader={this.props.loader} isLoading={this.props.loader.active}/>
        )
    }
}

export const languages = ['ru', 'ua', 'en'];

export const useCurrentLangPrefix = () => {
    const { lang } = useParams();
    if(!languages.includes(lang)) return '';
    return '/' + lang;
}

const AppRouter = ({ isAuth, isLoading }) => {
    const dispatch = useDispatch();
    const { lang } = useParams();
    const languagesData = useSelector((state) => state.main.languages);

    const setLang = (code) => {
        const langData = languagesData.find((e) => e.code === String(code).toUpperCase());
        if(!langData) return null;
        const currentLang = localStorage.getItem('medbuy_store_main_config_lang');
        localStorage.setItem('medbuy_store_main_config_lang', String(langData.id));
        if(currentLang !== langData.id) {
            window.location.reload();
        }
    }

    useEffect(() => {
        if(languages.includes(lang)) {
            setLang(lang);
        } else  {
            setLang('ua');
        }
    }, [lang, languagesData.length]);

    useEffect(() => {
        dispatch(getLanguagesDataThunk());
    }, [])


    console.log(lang)

    return <>
        <HeaderContainer/>
        <MenuContainer/>
        <main className={'container main_wrapper add_style'}>
            <div className="wrapper">
                <Switch>
                    <Route exact path={'/:lang?/home'}>
                        <HomeContainer/>
                    </Route>
                    <Route exact path={'/:lang?/checkout/:types'}
                           render={(match) => <CheckoutContainer uri={match}/>}/>
                    <Route exact path={'/:lang?/confirm'}>
                        <ConfirmContainer/>
                    </Route>
                    <Route exact path={'/:lang?/success'}>
                        <SuccessContainer/>
                    </Route>
                    <Route  path={'/:lang?/success/:types/:id'}
                            render={(match) => <SuccessContainer uri={match}/>}
                    />
                    <Route path={'/:lang?/search/:key'}
                           render={(match) => <SearchContainer uri={match}/>}
                    />
                    <Route exact path={'/:lang?/registration'}>
                        {isAuth ? <AccountContainer/> : <RegistrationContainer/>}
                    </Route>
                    <Route exact path={'/:lang?/login'}>
                        {isAuth ? <AccountContainer/> : <LoginContainer/>}
                    </Route>
                    <Route exact path={'/:lang?/account/activate/:token'}
                           render={(match) => isAuth ? <AccountContainer uri={match}/>  : <AccountActivateContainer uri={match}/>}/>
                    <Route exact path={'/:lang?/account/reset_password'}
                           render={(match) => isAuth ? <AccountContainer uri={match}/> : <ResetPasswordContainer uri={match}/> }/>
                    <Route exact path={'/:lang?/account/reset_password_form/:token'}
                           render={(match) => isAuth ? <AccountContainer uri={match}/> : <ResetPasswordContainer isForm={true} uri={match}/> }/>
                    <Route exact path={'/:lang?/account/activate_confirm/:token'}
                           render={(match) => isAuth ? <AccountContainer uri={match}/>  : <AccountActivateContainer isConfirm={true} uri={match}/>}/>
                    <Route exact path={'/:lang?/account/:tab'}
                           render={(match) => isAuth ? <AccountContainer uri={match}/>  : <LoginContainer/>}/>
                    <Route exact path={'/:lang?/account'}>
                        {isAuth ? <AccountContainer/> : <LoginContainer/>}
                    </Route>
                    <Route exact path={'/:lang?/products/:product'}
                           render={(match) => <ProductsDetailContainer uri={match}/>}/>
                    <Route exact path={'/:lang?/category/:category'}
                           render={(match) => <ProductsCategoryContainer uri={match}/>}
                    />
                    <Route path={'/:lang?/partner_program/:scroll'}
                           render={(match) => <PartnerProgrammeContainer uri={match}/>}
                    />
                    <Route exact path={'/:lang?/partner_program'}>
                        <PartnerProgrammeContainer/>
                    </Route>
                    <Route path={'/:lang?/about'}
                           render={(match) => <AboutContainer uri={match}/>}
                    />
                    <Route path={'/:lang?/privacy_policy'}>
                        <PrivacyPolicy/>
                    </Route>
                    <Route path={'/:lang?/payment_information'}>
                        <PaymentInfoContainer/>
                    </Route>
                    <Route exact path={'/:lang(ua|ru|en|)'}>
                        <HomeContainer/>
                    </Route>
                    <Route component={NotFound404} status={404}/>
                </Switch>
            </div>
        </main>
        <FooterContainer/>
        <CartContainer/>
        {isLoading ? <Loader /> : ''}
        <AlertContainer/>
    </>
}

class App extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <Route path={'/:lang?'}>
            <AppRouter isAuth={this.props.isAuth} isLoading={this.props.isLoading} />
        </Route>
        // if (this.props.isAuth) {
        //
        // } else {
        //   return (
        //       <div className={'main_wrapper'}>
        //            <LoginContainer/>
        //       </div>
        //   )
        // }

    };
}



let mapStateToProps = (state) => {
    return {
        isAuth: state.auth.isAuth,
        loader: state.main.loader
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        getAuth: () => dispatch(checkAuthDataThunk()),
        getLanguages: () => dispatch(getLanguagesDataThunk()),
        setTranslates: () => dispatch(setAllTranslatesThunk())
    }
}

export const formateDateStore = (date) => {
    date = new Date(date);
    let d = date.getDate();
    let m = date.getMonth() + 1; //Month from 0 to 11
    let y = date.getFullYear();
    let h = date.getHours();
    let min = date.getMinutes();
    let sec = date.getSeconds();
    return '' + (d <= 9 ? '0' + d : d) + '-' + (m<=9 ? '0' + m : m) + '-' + y + ' ' + `${h}:${min}:${sec}`;
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
