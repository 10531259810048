import React from 'react';
import './ResetPassword.css';

const ResetPassword = (props) => {

    return (<>
        <div className="activate_account_page_container">
            <h1>{props.getTranslate('front_reset_password','reset_password_title')}</h1>
            <p>
                {props.getTranslate('front_reset_password','reset_password_sub_title')}
            </p>
            <form onSubmit={(event) => {
                event.preventDefault();
                props.send(props.form.email);
            }}>
                <div className={'row'}>
                    <div className="col-md-3">
                        <div className="form-group">
                            <input type="text"
                                   className="form-control"
                                   value={props.form && props.form.email}
                                   onChange={(event) => props.changeValue('email', event.target.value)}
                                   required={'required'}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <button type="submit" className="btn custom_button btn-block">{props.getTranslate('front_reset_password','reset_password_button_title')}</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {props.form && props.form.success && <>
                            <p className={'text-success'}>{props.form.message}</p>
                        </>}
                        {props.form && props.form.fail && <>
                            <p className={'text-danger'}>{props.form.message}</p>
                        </>}
                    </div>
                </div>
            </form>
        </div>
    </>)
}

export default ResetPassword;