import React from 'react';

const Alert = (props) => {
    return(
        <div id="toast-container" className="toast-top-right">
            <div className={'toast ' + (props.type === 'error' ? 'toast-error' : 'toast-success')} aria-live="polite">
                <div className="toast-message">{props.message}</div>
            </div>
        </div>
    )
}

export default Alert;