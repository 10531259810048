import React from 'react';
import './Cart.css';
import ImageDelete from './../../image/delete.png'
import ImageMinus from './../../image/minus.png'
import ImagePlus from './../../image/plus.png'
import {formatSum} from "../../store/reducers/MainReducer";
import LangLink from "../Common/LangLink";

const Cart = (props) => {
    return (
        <div className={'modal fade show'}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content custom_overflow_hidden">
                    <div className="modal-header">
                        <h4 className="modal-title">{props.getTranslate('front_cart','cart_title')}</h4>
                        <button type="button" className="close" onClick={() => props.toggle(false)}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body row custom_overflow">
                        {props.productsPreOrder.length > 0 || props.products.length > 0 ? <>
                            {props.products && props.products.length > 0 ? <>
                        {/*товари в наявності*/}
                        <div className={'cart_title_type row'}>
                            <h4><i className="fas fa-cart-plus"></i> {props.getTranslate('front_common','in_stock')}</h4>
                        </div>
                        {props.products && props.products.map((e) => {
                            return (
                                <div className={'cart_container row'}>
                                    <div className={'col-md-8 cart_name_product'}>{e.name}</div>
                                    <div className={'col-md-3 cart_info_product'}>
                                        <div className={'cart_count_product'}>
                                            <button
                                                onClick={() => props.changeProduct(e.id, 'count', (Number(e.count) - 1 > 1 ? Number(e.count) - 1 : 1))}>
                                                <img src={ImageMinus}/>
                                            </button>
                                            <input className={'form-control'} value={e.count}/>
                                            <button
                                                onClick={() => props.changeProduct(e.id, 'count', (Number(e.count_max) >= Number(e.count) + 1 ? Number(e.count) + 1 : Number(e.count)))}>
                                                <img src={ImagePlus}/>
                                            </button>
                                        </div>
                                        {/*<div className={'cart_price_product'}>₴ {e.price}</div>*/}
                                        <div className={'cart_price_product'}>₴ {formatSum(Number(e.price) * Number(e.count))}</div>
                                    </div>
                                    <div className={'col-md-1 cart_delete_product'}>
                                        <button onClick={() => props.deleteProduct(e.id)}><img src={ImageDelete}/></button>
                                    </div>
                                </div>
                            )
                        })}
                            <div className={'cart_total_sum col-md-12'}>
                                <p className={'text-right'}>{props.getTranslate('front_cart','cart_amount_for_in_stock')}: ₴ {formatSum(props.totals.in_stock)}</p>
                            </div>
                            </> : ""}
                        {/*товари предзамовлення*/}

                        {props.productsPreOrder && props.productsPreOrder.length > 0 ? <>
                                <div className={'cart_title_type row'}>
                                    <h4><i className="fas fa-truck"></i> {props.getTranslate('front_common','preorder')}</h4>
                                </div>
                                {props.productsPreOrder && props.productsPreOrder.map((e) => {
                                    return (
                                        <div className={'cart_container row'}>
                                            <div className={'col-md-8 cart_name_product'}>{e.name}</div>
                                            <div className={'col-md-3 cart_info_product'}>
                                                <div className={'cart_count_product'}>
                                                    <button
                                                        onClick={() => props.changeProductPreOrder(e.id, 'count', (Number(e.count) - 1 > 1 ? Number(e.count) - 1 : 1))}>
                                                        <img src={ImageMinus}/>
                                                    </button>
                                                    <input className={'form-control'} value={e.count}/>
                                                    <button
                                                        onClick={() => props.changeProductPreOrder(e.id, 'count', Number(e.count) + 1)}>
                                                        <img src={ImagePlus}/>
                                                    </button>
                                                </div>
                                                {/*<div className={'cart_price_product'}>₴ {e.price}</div>*/}
                                                <div className={'cart_price_product'}>₴ {formatSum(Number(e.price) * Number(e.count))}</div>
                                            </div>
                                            <div className={'col-md-1 cart_delete_product'}>
                                                <button onClick={() => props.deleteProductPreOrder(e.id)}><img src={ImageDelete}/></button>
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className={'cart_total_sum col-md-12'}>
                                    <p className={'text-right cart_amount_for_preorder'}>{props.getTranslate('front_cart','cart_amount_for_preorder')}: ₴ {formatSum(props.totals.pre_order)}</p>
                                    <p className={'cart_info_price_change'}>{props.getTranslate('front_calculate','calculate_info_price_change')}</p>
                                </div>
                            </> : '' }
                        </> : <span className={"card_title_card_empty"}>{props.getTranslate('front_cart','cart_empty')}</span>}
                    </div>
                    <div className="modal-footer justify-content-between cart_modal_footer_button">
                        <p>{props.getTranslate('front_cart','cart_amount_sum')}: ₴ {formatSum(props.totals.total)}</p>
                        <LangLink isA={true} href={"/confirm"}>{props.getTranslate('front_cart','cart_created_order')}</LangLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cart;