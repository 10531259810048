import React, {useState} from 'react';
import './Account.css'
import ImagePerson from '../../image/account/person.png'
import ImageAccount from '../../image/account/my_account.png'
import ImageOrders from '../../image/account/my_orders.png'
import ImageContactUs from '../../image/account/contact_us.png'
import Breadcrumbs from "../Common/Breadcrumps/Breadcrumps";
import {Link} from "react-router-dom";
import ProductsGalleryContainer from "../Products/Gallery/ProductsGalleryContainer";
import ContactUsContainer from "../Modal/ContactUs/ContactUsContainer";
import {isMobile} from "../../store/reducers/MainReducer";
import LangLink from "../Common/LangLink";
import {SeoTagsStatic} from "../SeoTagsStatic";


const Account = (props) => {
    const [isOpen, toggleOpen] = useState(false);
    let status_test = props.user && props.user.isPartner && Number(props.user.isPartner) === 1 ? 1 : 0;

    // let statusName = props.user && props.user.partner_info && props.user.partner_info[0].rang && Number(props.user.partner_info[0].rang) > 0 && props.directoryData && props.directoryData.customer_partner_rang
    //         .filter(e => Number(e.id) === Number(props.user.partner_info[0].rang))[0].name;

    let statusName = props.directoryData && props.directoryData.customer_partner_rang && props.user &&
        props.user.partner_info[0] && props.user.partner_info[0].rang && Number(props.user.partner_info[0].rang) > 0 && props.directoryData.customer_partner_rang.filter(c => {
            return Number(c.id) === Number(props.user.partner_info[0].rang)
        })[0] && props.directoryData.customer_partner_rang.filter(c => {
            return Number(c.id) === Number(props.user.partner_info[0].rang)
        })[0].name;


    return (
        <div className={'account_container'}>
            <SeoTagsStatic page={'account'}/>
            <Breadcrumbs name={props.getTranslate('front_account','account_breadcrumbs_title')}
                         status={props.user && props.user.partner_info && props.user.partner_info[0] && props.user.partner_info[0].rang && Number(props.user.partner_info[0].rang) > 0
                             ? props.user.partner_info[0].rang : 1}
                         statusName={statusName}
            />
            <div className={'row'}>
                <div className={'account_menu_container col-md-3'}>
                    <LangLink className={'row'} to={'/account/profile'}>
                        <div className={'account_user_photo col-md-3'}><img src={ImagePerson}/></div>
                        <div className={'account_user_name col-md-9'}>
                            <span>{props.user.surname} {props.user.name}</span></div>
                    </LangLink>
                    {/*перевірка на партнерство*/}
                    {props.user && props.user.isPartner && Number(props.user.isPartner) < 1 ?
                        <div className={'account_partner_button'}>
                            <LangLink to={"/partner_program/reg"}>
                                <button>{props.getTranslate('front_account','account_button_partner_program_title')}</button>
                            </LangLink>
                        </div> : ""}

                    {isMobile() &&
                    <span className={"account_mobile_menu"} onClick={() => toggleOpen(!isOpen)}>
                        {isOpen ? <i className="fas fa-times"></i> :
                        <i className="fas fa-bars"></i>} {props.getTranslate('front_account','account_menu_title')}
                    </span>
                    }
                    {/*<Link className={'row account_person_info'} to={'/account/profile'}>*/}
                    {/*    <div className={'account_user_photo col-md-3'}><img src={ImageAccount}/></div>*/}
                    {/*    <div className={'account_user_name col-md-9'}><span>Мій обліковий запис</span></div>*/}
                    {/*</Link>*/}
                    {!isOpen && isMobile() ?
                        ''
                        :
                        <>
                            <div className={'account_menu_block'}>
                                <div className={'account_menu_title'}>
                                    <div className={'account_user_photo col-md-3'}><img src={ImageOrders}/></div>
                                    <div className={'account_user_name col-md-9'}><span>{props.getTranslate('front_account','account_menu_my_order')}</span></div>
                                </div>
                                <div className={'account_menu_items col-md-12'}>
                                    <LangLink to={'/account/orders'} onClick={() => toggleOpen(!isOpen)}
                                          className={'' + (props.tabName === 'orders' ? 'account_item_menu_active' : '')}>
                                        {props.getTranslate('front_account','account_menu_my_order_history')}
                                    </LangLink>
                                    <LangLink to={'/account/payments'} onClick={() => toggleOpen(!isOpen)}
                                          className={'' + (props.tabName === 'payments' ? 'account_item_menu_active' : '')}>
                                        {props.getTranslate('front_account','account_menu_my_payment_history')}</LangLink>
                                    <LangLink to={'/account/addresses'} onClick={() => toggleOpen(!isOpen)}
                                          className={'' + (props.tabName === 'addresses' ? 'account_item_menu_active' : '')}>
                                        {props.getTranslate('front_account','account_menu_my_address')}</LangLink>
                                </div>
                            </div>
                            {/*Меню партнера*/}
                            {props.user && props.user.isPartner && Number(props.user.isPartner) === 1 ?
                                <div className={'account_menu_block'}>
                                    <div className={'account_menu_title'}>
                                        <div className={'account_user_photo col-md-3'}><img src={ImageOrders}/></div>
                                        <div className={'account_user_name col-md-9'}><span>{props.getTranslate('front_account','account_menu_partner_title')}</span>
                                        </div>
                                    </div>

                                    <div className={'account_menu_items col-md-12'}>
                                        <LangLink to={'/account/partner_orders'} onClick={() => toggleOpen(!isOpen)}
                                              className={'' + (props.tabName === 'partner_orders' ? 'account_item_menu_active' : '')}>
                                            {props.getTranslate('front_account','account_menu_partner_order')}
                                        </LangLink>
                                        <LangLink to={'/account/partner_products'} onClick={() => toggleOpen(!isOpen)}
                                              className={'' + (props.tabName === 'partner_products' ? 'account_item_menu_active' : '')}>
                                            {props.getTranslate('front_account','account_menu_partner_products')}
                                        </LangLink>
                                        <LangLink to={'/account/partner_sale'} onClick={() => toggleOpen(!isOpen)}
                                              className={'' + (props.tabName === 'partner_sale' ? 'account_item_menu_active' : '')}>{props.getTranslate('front_account','account_menu_partner_testing')}</LangLink>
                                            <LangLink to={'/account/partner_product_sale'} onClick={() => toggleOpen(!isOpen)}
                                                  className={'' + (props.tabName === 'partner_product_sale' ? 'account_item_menu_active' : '')}>
                                                {props.getTranslate('front_account','account_menu_partner_testing_product')}
                                            </LangLink>
                                        <LangLink to={'/account/partner_bonus'} onClick={() => toggleOpen(!isOpen)}
                                              className={'' + (props.tabName === 'partner_bonus' ? 'account_item_menu_active' : '')}>
                                            {props.getTranslate('front_account','account_menu_partner_bonus')}
                                        </LangLink>
                                    </div>
                                </div> : " "}
                            <div className={'row account_pointer'}
                                 onClick={() => props.toggleViewContactModal(true)}>
                                <div className={'account_user_photo col-md-3'}><img src={ImageContactUs}/></div>
                                <div className={'account_user_name col-md-9'}><span>{props.getTranslate('front_account','account_menu_contact_us')}</span></div>
                            </div>
                            <div className={'row account_pointer account_user_exit_container'} onClick={() => props.logout()}>
                                <div className={'account_user_photo col-md-3 account_user_exit'}><i
                                    className="fas fa-sign-out-alt"></i></div>
                                <div className={'account_user_name col-md-9'}><span>{props.getTranslate('front_account','account_menu_exit')}</span></div>
                            </div>
                        </>
                    }
                </div>
                <div className={'account_content_container col-md-9'}>
                    {props.tab}
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <ProductsGalleryContainer title={props.getTranslate('front_account','account_product_view_previously')} count={4} type={'searched'} view={'grid'}/>
                </div>
            </div>
            <ContactUsContainer/>
        </div>
    )
}

export default Account;