import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import {formatSum} from "../../store/reducers/MainReducer";
import ImagePDF from "../../image/pdf.png";
import {compose} from "redux";
import { useDispatch } from 'react-redux';
import {withPagination} from "../HOC/withPagination/withPagination";
import {getCustomerOrdersDataThunk} from "../../store/reducers/AuthReducer";
import {formateDateStore} from "../../App";

const AccountOrders = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCustomerOrdersDataThunk())
    }, []);
    return <>
        <div className="container-fluid">
            <div className="card">
                <table className="table table-striped text-center">
                    <thead className={"account_table_head"}>
                    <th>ID</th>
                    <th>{props.getTranslate('front_account','account_table_sum_title_column')}</th>
                    <th>{props.getTranslate('front_account','account_table_status_title_column')}</th>
                    <th>{props.getTranslate('front_account','account_table_date_title_column')}</th>
                    <th></th>
                    </thead>
                    <tbody>
                    {props.user && props.user.orders && props.user.orders
                        .filter(e => Number(e.type) === 1 || Number(e.type) === 2)
                        .filter((e, i) => {

                            if (((i + 1) > props.paginationPages.pageStart || ((i + 1) === 1) && props.paginationPages.page === 1) && (i + 1) <= props.paginationPages.pageFinish) {
                                return true;
                            } else {
                                return false;
                            }
                        })
                        .map(e => {
                            return <>
                                <tr onClick={() => props.toggleActive(e.id)}>
                                    <td>{e.id}</td>
                                    <td>₴ {formatSum(e.total)}</td>
                                    <td>
                                        {props.directoryData && props.directoryData.status && props.directoryData.status.filter(c => {
                                            return Number(c.id) === Number(e.status)
                                        })[0].value}
                                    </td>
                                    <td>{formateDateStore(e.date)}</td>
                                    <td>
                                        <i className="fas fa-angle-down">
                                        </i>
                                    </td>
                                </tr>
                                {Number(props.orderOpen) === Number(e.id) ?
                                    <tr>
                                        <td colSpan={5}>
                                            <table className={'table table-bordered text-center'}>
                                                <thead className={"account_table_head"}>
                                                <tr>
                                                    <th>#</th>
                                                    <th>{props.getTranslate('front_account','account_table_date_title_column')}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    {e.payment && Number(e.payment) === 2 ?
                                                        <>
                                                            {e.payments && e.payments.map(p => {
                                                                return <>
                                                                    <td>{p.invoice_number}</td>
                                                                    <td>{p.date}</td>
                                                                </>
                                                            })}
                                                        </>
                                                        : Number(e.payment) === 1 ?
                                                            <>
                                                                {e.documents && e.documents.map(doc => {
                                                                    return <>
                                                                        {doc.type && doc.type === "receipt" ?
                                                                            <>
                                                                            <td>
                                                                                <a className={"account_order_invoice_link"} href={doc.link} target={"_blank"}>{props.getTranslate('front_account','account_button_download_invoice')} <img alt={""} src={ImagePDF}/></a>
                                                                            </td>
                                                                            <td>{doc.date}</td>
                                                                            </>
                                                                            : ""
                                                                        }
                                                                    </>
                                                                })}
                                                            </>
                                                            : ""}
                                                </tr>

                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    :
                                    ''
                                }
                            </>
                        })}
                    </tbody>
                </table>
            </div>
            {props.paginationHTML}
        </div>
    </>
}
export default compose(
    withPagination
)(AccountOrders);
