import React from 'react';
import ProductsGalleryContainer from "../Products/Gallery/ProductsGalleryContainer";
import { seo_titles } from '../../language/seo_titles';

import Breadcrumbs from "../Common/Breadcrumps/Breadcrumps";
import FiltersContainer from "../Products/Filters/FiltersContainer";
import PaginationContainer from "../Common/Pagination/PaginationContainer";
import MetaTags from "react-meta-tags";
import {useCurrentLangPrefix} from "../../App";
import {useParams} from "react-router-dom";
import {SeoTagsStatic} from "../SeoTagsStatic";

const Search = (props) => {
    return (
        <div className={'products_category_main_container'}>
            <SeoTagsStatic page={'search'}/>
            <Breadcrumbs name={props.getTranslate('front_search','search_title')}
                         pages={[]}
            />
            <div className="row">
                <div className="col-md-3">
                    <FiltersContainer type={'search'}/>
                </div>
                <div className="col-md-9">
                    <div className="row">
                        <div className="products_category_main_title">
                            <h1>{props.getTranslate('front_search','search_title')}</h1>
                            <span className={'products_category_main_title_count'}>{props.getTranslate('front_search','search_amount_title')}: {props.countAll}</span>
                        </div>
                    </div>
                    <ProductsGalleryContainer title={props.getTranslate('front_product_category','product_category_title_recomend_product')} count={9} type={'category'} view={'grid'} products={props.products}/>
                    <PaginationContainer link={`/search/${props.keyword}/?page=`} />
                </div>
            </div>
        </div>
    )
}

export default Search;