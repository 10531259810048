import React from 'react';
import {connect} from "react-redux";
import  PaymentInfo from "./PaymentInfo";
import './PaymentInfo.css';

class PaymentInfoContainer extends React.Component {

    constructor(props){
        super(props);
    }

    componentDidMount() {}

    render() {
        return < PaymentInfo/>
    }
}

let mapStateToProps = (state) => {
    return {}
}

let mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInfoContainer);