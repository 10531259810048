import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import {formatSum} from "../../store/reducers/MainReducer";
import {compose} from "redux";
import {withPagination} from "../HOC/withPagination/withPagination";
import {formateDateStore} from "../../App";
import {useDispatch} from "react-redux";
import {getCustomerOrdersDataThunk} from "../../store/reducers/AuthReducer";
import LangLink from "../Common/LangLink";

const AccountPartnerProduct = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCustomerOrdersDataThunk())
    }, []);
    return <>
        <div className="container-fluid">
            <div className="card">
                <table className={'table table-bordered text-center'}>
                    <thead className={"account_table_head"}>
                    <tr>
                        <th>{props.getTranslate('front_account','account_table_product_title_column')}</th>
                        <th>{props.getTranslate('front_account','account_table_price_title_column')}</th>
                        <th>{props.getTranslate('front_account','account_table_count_title_column')}</th>
                        <th>{props.getTranslate('front_account','account_table_bonus_title_column')}</th>
                        <th>{props.getTranslate('front_account','account_table_date_title_column')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.user && props.user.partner_products_sale && props.user.partner_products_sale
                        .filter((e,i) => {

                            if(((i + 1) > props.paginationPages.pageStart || ((i + 1) === 1) && props.paginationPages.page === 1) && (i + 1) <= props.paginationPages.pageFinish) {
                                return true;
                            } else {
                                return false;
                            }
                        })
                        .map(p => {
                        return <tr>
                            <td><LangLink to={"/products/" + p.alias}>{p.name}</LangLink></td>
                            <td>₴ {formatSum(p.price)}</td>
                            <td>{p.count}</td>
                            <td>₴ {p.bonus}</td>
                            <td>{formateDateStore(p.date)}</td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>
            {props.paginationHTML}
        </div>
    </>
}
export default compose(
    withPagination
)(AccountPartnerProduct);
