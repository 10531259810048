import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import {formatSum} from "../../store/reducers/MainReducer";
import {getCustomerOrdersDataThunk} from "../../store/reducers/AuthReducer";
import ImagePDF from './../../image/pdf.png'
import {compose} from "redux";
import { useDispatch } from 'react-redux';
import {withPagination} from "../HOC/withPagination/withPagination";
import {formateDateStore} from "../../App";
import LangLink from "../Common/LangLink";

const AccountOrders = (props) => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCustomerOrdersDataThunk())
    }, []);

    return <>
        <div className="container-fluid">
            <div className="card">
                <table className="table table-striped text-center">
                    <thead className={"account_table_head"}>
                    <th>ID</th>
                    <th>{props.getTranslate('front_account','account_table_sum_title_column')}</th>
                    <th>{props.getTranslate('front_account','account_table_status_title_column')}</th>
                    <th>{props.getTranslate('front_account','account_table_date_title_column')}</th>
                    <th></th>
                    </thead>
                    <tbody>
                    {props.user && props.user.orders && props.user.orders
                        .filter((e,i) => {
                            console.log(i);
                            return Number(e.type) === 1 || Number(e.type) === 2;
                        })
                        .filter((e,i) => {
                            if(((i + 1) > props.paginationPages.pageStart || ((i + 1) === 1) && props.paginationPages.page === 1) && (i + 1) <= props.paginationPages.pageFinish) {
                                return true;
                            } else {
                                return false;
                            }
                        })
                        .map(e => {
                            return <>
                                <tr onClick={() => props.toggleActive(e.id)}>
                                    <td>{e.id}</td>
                                    <td>₴ {formatSum(e.total)}</td>
                                    <td>
                                        {props.directoryData && props.directoryData.status && props.directoryData.status.filter(c => {
                                            return Number(c.id) === Number(e.status)
                                        })[0] && props.directoryData.status.filter(c => {
                                            return Number(c.id) === Number(e.status)
                                        })[0].value}
                                    </td>
                                    <td>{formateDateStore(e.date)}</td>
                                    <td>
                                        <i className="fas fa-angle-down">
                                        </i>
                                    </td>
                                </tr>
                                {Number(props.orderOpen) === Number(e.id) ?
                                    <tr>
                                        <td colSpan={5}>
                                            <table className={'table table-bordered text-center'}>
                                                <thead className={"account_table_head"}>
                                                <tr>
                                                    <th>{props.getTranslate('front_account','account_table_name_title_column')}</th>
                                                    <th>{props.getTranslate('front_account','account_table_model_title_column')}</th>
                                                    <th>{props.getTranslate('front_account','account_table_manufacturer_title_column')}</th>
                                                    <th>{props.getTranslate('front_account','account_table_count_title_column')}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {e.products && e.products.map(p => {
                                                    let manufacturer = props.manufacturers && props.manufacturers.filter(c => {
                                                        return Number(c.id) === Number(p.manufacturer)
                                                    })[0];
                                                    return <tr>
                                                        <td><LangLink to={"/products/" + p.alias}>{p.name}</LangLink></td>
                                                        <td>{p.model}</td>
                                                        <td>
                                                            {manufacturer && manufacturer.name}
                                                        </td>
                                                        <td>{p.count}</td>
                                                    </tr>
                                                })}
                                                {props.user && props.user.orders
                                                && Number(e.region) > 0
                                                && Number(e.city) > 0 ?
                                                    <tr>
                                                        <td colSpan={"4"}>
                                                            <div className="info-box bg-light">
                                                                <div className="info-box-content">
                                                                <span
                                                                    className="info-box-number text-center text-muted mb-0">
                                                                    {props.directoryData && props.directoryData.regions && props.directoryData.regions.filter(c => {
                                                                        return Number(c.id) === Number(e.region)
                                                                    })[0].name} обл.,
                                                                {/*    {props.directoryData && props.directoryData.districts && props.directoryData.districts.filter(b => {*/}
                                                                {/*    return Number(b.id) === Number(e.district)*/}
                                                                {/*})[0] && props.directoryData.districts.filter(b => {*/}
                                                                {/*    return Number(b.id) === Number(e.district)*/}
                                                                {/*})[0].name} р., */}
                                                                    м. {props.directoryData && props.directoryData.cities && props.directoryData.cities.filter(c => {
                                                                    return Number(c.id) === Number(e.city)
                                                                })[0] && props.directoryData.cities.filter(c => {
                                                                    return Number(c.id) === Number(e.city)
                                                                })[0].name},
                                                                    {e.id_delivery_type && Number(e.id_delivery_type) === 1 ?
                                                                        <>
                                                                        вул. {e.street} № {e.street_house}
                                                                        </>
                                                                        : e.id_delivery_type && Number(e.id_delivery_type) === 2 ?
                                                                            <>
                                                                                {props.directoryData && props.directoryData.np_warehouse && props.directoryData.np_warehouse.filter(c => {
                                                                                        return Number(c.id) === Number(e.np_warehouse)
                                                                                    })[0] && props.directoryData.np_warehouse.filter(c => {
                                                                                        return Number(c.id) === Number(e.np_warehouse)
                                                                                    })[0].name}
                                                                            </>
                                                                    : ""}
                                                                </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    : ""}
                                                {e.documents && e.documents.map(doc => {
                                                    return <>
                                                        {doc.type && doc.type === "receipt" ?
                                                            <tr>
                                                                <td colSpan={"4"}>
                                                                    <a className={"account_order_invoice_link"} href={doc.link} target={"_blank"}>{props.getTranslate('front_account','account_button_download_invoice')} <img alt={""} src={ImagePDF} /></a>
                                                                </td>
                                                            </tr>
                                                            : ""
                                                        }
                                                    </>
                                                })}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    :
                                    ''
                                }
                            </>
                        })}
                    </tbody>
                </table>
            </div>
            {props.paginationHTML}
        </div>
    </>
}
export default compose(
    withPagination
)(AccountOrders);
