import React from 'react';
import {connect} from "react-redux";
import PartnerProgramme from "./PartnerProgramme";
import './PartnerProgramme.css';
import {
    changePartnerFormValueAC,
    createPartnerThunk,
    getSpecialtyDataThunk
} from "../../../store/reducers/MainReducer";
import {compose} from "redux";
import {withLang} from "../../HOC/withLang/withLang";
import onClickOutside from "react-onclickoutside";

class PartnerProgrammeContainer extends React.Component {

    constructor(props){
        super(props);
    }

    componentDidMount() {
        this.props.specialty();
    }

    render() {
        return <PartnerProgramme
            getTranslate={this.props.getTranslate}
            user={this.props.user}
            specialtyData={this.props.specialtyData}
            form={this.props.form}
            changeValue={this.props.changeValue}
            createPartner={this.props.createPartner}
            scroll={this.props.uri && this.props.uri.match && this.props.uri.match.params.scroll}
        />
    }
}

let mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        specialtyData: state.main.specialtyData,
        form: state.main.PartnerForm,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        specialty: (specialty) => dispatch(getSpecialtyDataThunk(specialty)),
        changeValue: (input, value) => dispatch(changePartnerFormValueAC(input, value)),
        createPartner: (partner) => dispatch(createPartnerThunk(partner)),
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang,
    onClickOutside
)(PartnerProgrammeContainer);