import React from 'react';
import './Confirm.css';
import ImageDelete from './../../../image/delete.png'
import ImageMinus from './../../../image/minus.png'
import ImagePlus from './../../../image/plus.png'
import {Link} from "react-router-dom";
import {formatSum} from "../../../store/reducers/MainReducer";
import CalculateWindowContainer from "../CalculateWindow/CalculateWindowContainer";
import {SeoTagsStatic} from "../../SeoTagsStatic";

const Confirm = (props) => {
    return (<>
            <SeoTagsStatic page={'confirm'}/>
            {/*товари в наявності*/}
            <div className={'confirm_title'}>
                <h3>{props.getTranslate('front_confirm','confirm_title')}</h3>
            </div>

            {/*товари з складу*/}
        {props.products && props.products.length > 0 ? <>
            <div className={'confirm_title_type row'}>
                <div className="col-md-12">
                    <h4>{props.getTranslate('front_common','in_stock')}</h4>
                </div>
            </div>
            {props.products && props.products.map((e) => {
                return (
                    <div className={'confirm_container row'}>
                        <div className={'col-md-8 confirm_name_product'}>{e.name}</div>
                        <div className={'col-md-3 confirm_info_product'}>
                            <div className={'confirm_count_product'}>
                                <button
                                    onClick={() => props.changeProduct(e.id, 'count', (Number(e.count) - 1 > 1 ? Number(e.count) - 1 : 1))}>
                                    <img src={ImageMinus}/>
                                </button>
                                <input className={'form-control'} value={e.count}/>
                                <button
                                    onClick={() => props.changeProduct(e.id, 'count', (Number(e.count_max) >= Number(e.count) + 1 ? Number(e.count) + 1 : Number(e.count)))}>
                                    <img src={ImagePlus}/>
                                </button>
                            </div>
                            {/*<div className={'cart_price_product'}>₴ {e.price}</div>*/}
                            <div className={'confirm_price_product'}>₴ {formatSum(Number(e.price) * Number(e.count))}</div>
                        </div>
                        <div className={'col-md-1 cart_delete_product'}>
                            <button onClick={() => props.deleteProduct(e.id)}><img src={ImageDelete}/></button>
                        </div>
                    </div>
                )
            })}
            <CalculateWindowContainer type={'order'} products={props.products}/>
        </> : ""}
            {/*товари предзамовлення*/}

            {props.productsPreOrder && props.productsPreOrder.length > 0 ? <>
                <div className={'confirm_title_type row'}>
                    <div className="col-md-12">
                        <h4>{props.getTranslate('front_common','preorder')}</h4>
                    </div>
                </div>
                {props.productsPreOrder && props.productsPreOrder.map((e) => {
                    return (
                        <div className={'confirm_container row'}>
                            <div className={'col-md-8 confirm_name_product'}>{e.name}</div>
                            <div className={'col-md-3 confirm_info_product'}>
                                <div className={'confirm_count_product'}>
                                    <button
                                        onClick={() => props.changeProductPreOrder(e.id, 'count', (Number(e.count) - 1 > 1 ? Number(e.count) - 1 : 1))}>
                                        <img src={ImageMinus}/>
                                    </button>
                                    <input className={'form-control'} value={e.count}/>
                                    <button
                                        onClick={() => props.changeProductPreOrder(e.id, 'count', Number(e.count) + 1)}>
                                        <img src={ImagePlus}/>
                                    </button>
                                </div>
                                <div className={'confirm_price_product'}>₴ {formatSum(Number(e.price) * Number(e.count))}</div>
                            </div>
                            <div className={'col-md-1 confirm_delete_product'}>
                                <button onClick={() => props.deleteProductPreOrder(e.id)}><img src={ImageDelete}/>
                                </button>
                            </div>
                        </div>
                    )
                })}
                <CalculateWindowContainer type={'preorder'} products={props.productsPreOrder}/>
            </> : ''}
        </>
    )
}

export default Confirm;