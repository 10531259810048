import {applyMiddleware, combineReducers, createStore} from 'redux';
import thunk from 'redux-thunk';
import AuthReducer from "./reducers/AuthReducer";
import MainReducer from './reducers/MainReducer';

let reducers = combineReducers({
    main: MainReducer,
    auth: AuthReducer
});

let store = createStore(reducers, applyMiddleware(thunk));

window.store = store;

export default store;