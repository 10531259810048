import React from 'react';
import {useCurrentLangPrefix} from "../../../App";
import {Link} from "react-router-dom";

const LangLink = (props) => {
    const langPrefix = useCurrentLangPrefix();
    const { children, isA } = props;
    const newProps = {...props};
    if(isA && newProps?.href) {
        newProps.href = langPrefix + newProps.href;
    } else {
        newProps.to = langPrefix + newProps.to;
    }
    return isA ? <a {...newProps}>{children}</a> : <Link {...newProps}>{children}</Link>
};

export default LangLink;