import React from 'react';
import {connect} from "react-redux";
import Home from "./Home";
import {compose} from "redux";
import {withLang} from "../HOC/withLang/withLang";
import onClickOutside from "react-onclickoutside";


class HomeContainer extends React.Component {

    constructor(props){
        super(props);
    }

    render() {
        return <Home
            getTranslate={this.props.getTranslate}
        />
    }
}

let mapStateToProps = (state) => {
    return {
    }
}

let mapDispatchToProps = (dispatch) => {
    return {

    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang,
    onClickOutside
)(HomeContainer)