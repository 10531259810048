import React from 'react';
import './AccountActivate.css';

const AccountActivate = (props) => {
    return (<>
        <div className="activate_account_page_container">
            <h1>{props.getTranslate('front_account_activate','reset_account_activate_title')}</h1>
            <p>
                {props.getTranslate('front_account_activate','reset_account_activate_description')}
            </p>
            <div className={'row'}>
                <div className="col-md-3">
                    <button type="submit" className="btn custom_button btn-block">{props.getTranslate('front_account_activate','reset_account_email_again_button')}</button>
                </div>
            </div>
        </div>
    </>)
}

export default AccountActivate;