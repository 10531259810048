import React from 'react'
import ImageNotFound from '../../../image/404.png'
import './404.css'
import {Link} from "react-router-dom";
import LangLink from "../LangLink";

const NotFound404 = (props) => {
    return (
        <div className={'row'}>
            <div className={'image_404 col-md-12'}>
                <img src={ImageNotFound} />
            </div>
            <div className={'col-md-12 text_404'}>
                <p>{props.getTranslate('front_404','404_title')}</p>
            </div>
            <div className={'col-md-12 button_404'}>
                <LangLink to={'/'}>{props.getTranslate('front_404','404_button_go_home_title')}</LangLink>
            </div>
        </div>
    )
}

export default NotFound404;