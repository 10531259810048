import React from 'react';
import Registration from "./Registration";
import {connect} from "react-redux";
import {changeRegistrationFormAC} from "../../store/reducers/MainReducer";
import {sendCustomerRegistrationThunk} from "../../store/reducers/AuthReducer";
import ApiService from "../../api/api";
import {compose} from "redux";
import {withLang} from "../HOC/withLang/withLang";
import onClickOutside from "react-onclickoutside";

const apiService = new ApiService();

class RegistrationContainer extends React.Component {

    constructor(props){
        super(props);
        this.checkEmail = this.checkEmail.bind(this);
    }



    checkEmail(email) {
        apiService.checkEmail(email)
            .then(res => {
                this.props.changeValue('validEmail', res);
            })
    }

    render() {
        return <Registration form={this.props.form}
                             changeValue={this.props.changeValue}
                             sendForm={this.props.sendForm}
                             checkEmail={this.checkEmail}
                             getTranslate={this.props.getTranslate}
        />
    }
}

let mapStateToProps = (state) => {
    return {
        form: state.main.registrationForm
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        changeValue: (input, value) => dispatch(changeRegistrationFormAC(input, value)),
        sendForm: (form) => dispatch(sendCustomerRegistrationThunk(form)),
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang,
    onClickOutside
)(RegistrationContainer);