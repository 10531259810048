import React from 'react';
import './PaymentsGateways.css';
import logoWFP from '../../../image/WayForPay_logo_black.png';
import logoPortmone from '../../../image/Portmone_logo.png';

const gateways = [
    { id: 1, image: logoWFP },
    { id: 2, image: logoPortmone },
];
export const PaymentsGateways = ({ gateway_id, setGateway }) => {
    const handleClick = (id) => {
        setGateway(id);
    }

    return (
        <div className={'pg-container'}>
            {gateways.map((e) => (
                <div className={`pg-item ${gateway_id === e.id ? ' active' : ''}`} onClick={() => handleClick(e.id)}>
                    <img src={e.image} alt=""/>
                </div>
            ))}
        </div>
    );
};
