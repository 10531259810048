import React from 'react';
import {connect} from "react-redux";
import ResetPassword from "./ResetPassword";
import ResetPasswordForm from "./ResetPasswordForm";
import {
    changePasswordResetValueAC,
    changePasswordResetValueEmailAC,
    resetCustomerPasswordThunk, setCustomerPasswordThunk
} from "../../../store/reducers/MainReducer";
import {compose} from "redux";
import {withLang} from "../../HOC/withLang/withLang";
import onClickOutside from "react-onclickoutside";

class ResetPasswordContainer extends React.Component {
    render() {
        return this.props.isForm ?
            <ResetPasswordForm changeValue={this.props.changeValueForm}
                               send={this.props.sendForm}
                               form={this.props.form}
                               token={this.props.uri.match.params.token}
                               getTranslate={this.props.getTranslate}
            />
        :
            <ResetPassword changeValue={this.props.changeValue}
                           send={this.props.send}
                           form={this.props.formEmail}
                           getTranslate={this.props.getTranslate}
            />
    }
}

let mapStateToProps = (state) => {
    return {
        form: state.main.resetPassword,
        formEmail: state.main.resetPasswordEmail,
    }
};

let mapDispatchToProps = (dispatch) => {
    return {
        changeValueForm: (input, value) => dispatch(changePasswordResetValueAC(input, value)),
        changeValue: (input, value) => dispatch(changePasswordResetValueEmailAC(input, value)),
        send: (email) => dispatch(resetCustomerPasswordThunk(email)),
        sendForm: (password, token) => dispatch(setCustomerPasswordThunk(password, token)),
    }
};
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang,
    onClickOutside
)(ResetPasswordContainer);
