import React from 'react';
import {Link} from "react-router-dom";
import LangLink from "../LangLink";

const Pagination = (props) => {

    const renderPages = (count) => {
        let pages = [];
        let countPage = Math.floor( count / 9);
        if(count % 9 !== 0) {
            countPage++;
        }
        for(let i = 1; i <= countPage; i++) {
            pages.push(<li className="page-item"><LangLink to={props.link + i} className={'page-link ' + (Number(props.activePage) === Number(i) ? 'active' : '')}>{i}</LangLink></li>)
        }
        return pages;
    }

    return(
        <div className={'pagination_main_container'}>
            <ul className="pagination">
                {renderPages(props.count)}
            </ul>
        </div>
    )
}

export default Pagination;