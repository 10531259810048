import React from 'react';
import {connect} from "react-redux";
import Cart from "./Cart";
import './Cart.css';
import {
    changeProductsCartDataAC,
    changeProductsCartPreOrderDataAC,
    deleteProductsCartAC, deleteProductsPreOrderCartAC,
    toggleCartOpenAC
} from "../../store/reducers/MainReducer";
import {compose} from "redux";
import {withLang} from "../HOC/withLang/withLang";
import onClickOutside from "react-onclickoutside";


class CartContainer extends React.Component {

    constructor(props){
        super(props);
        this.getTotals = this.getTotals.bind(this);
    }

    getTotals() {
        let in_stock = this.props.products ? this.props.products.reduce(function(accumulator, currentValue, index, array) {
            return accumulator + (currentValue.count * currentValue.price);
        }, 0) : 0;
        let pre_order = this.props.productsPreOrder ? this.props.productsPreOrder.reduce(function(accumulator, currentValue, index, array) {
            return accumulator + (currentValue.count * currentValue.price);
        }, 0) : 0;
        let total = in_stock + pre_order;

        return {
            in_stock: in_stock,
            pre_order: pre_order,
            total: total
        }
    }

    render() {
        return this.props.isOpen ? <Cart toggle={this.props.toggle}
                                         products={this.props.products}
                                         productsPreOrder={this.props.productsPreOrder}
                                         changeProduct={this.props.changeProduct}
                                         changeProductPreOrder={this.props.changeProductPreOrder}
                                         deleteProduct={this.props.deleteProduct}
                                         deleteProductPreOrder={this.props.deleteProductPreOrder}
                                         totals={this.getTotals()}
                                         getTranslate={this.props.getTranslate}
        /> : '';
    }
}

let mapStateToProps = (state) => {
    return {
        isOpen: state.main.cart.isOpen,
        products: state.main.cart.products,
        productsPreOrder: state.main.cart.productsPreOrder,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        toggle: (status) => dispatch(toggleCartOpenAC(status)),
        changeProduct: (product_id, input, value) => dispatch(changeProductsCartDataAC(product_id, input, value)),
        deleteProduct: (product_id) => dispatch(deleteProductsCartAC(product_id)),
        changeProductPreOrder: (product_id, input, value) => dispatch(changeProductsCartPreOrderDataAC(product_id, input, value)),
        deleteProductPreOrder: (product_id) => dispatch(deleteProductsPreOrderCartAC(product_id)),
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withLang,
    onClickOutside
)(CartContainer);